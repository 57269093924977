import React from "react";
import { Form, Icon, Input, Button, Table, DatePicker, Breadcrumb } from "antd";

import Axios from "../../util/request";
import moment from "moment";
import debounce from "lodash/debounce";

class GpsLogList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData: {
        cid: "",
      }, //检索数据
      dataSource: {}, //列表数据
      provinceList: [],
      pageNum: 1,
      tableLoading: true,
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
    };
    this.pageNum = 1;
    this.fetchOrgData = debounce(this.fetchOrgData, 800);
  }

  // 获取table数据
  fetchOrgData = () => {
    const self = this;
    const { filterData, timeString, userInfo } = this.state;
    const page = this.pageNum || 1;
    this.setState({ tableLoading: true });
    if (!filterData.state) {
      delete filterData.state;
    }
    if (userInfo.projectId) {
      filterData.projectId = userInfo.projectId;
    }
    const params = JSON.stringify(filterData);
    // 拉取预约数据
    Axios(
      {
        url: "/server/admin/car/getGpsLogList",
        data: { params, page, rows: 20 },
        method: "get",
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        self.setState({ dataSource: result, tableLoading: false });
      }
    });
  };

  componentDidMount() {
    const self = this;
    const { filterData } = this.state;

    const searchCid = new URLSearchParams(window.location.search).get("cid");
    console.log(
      window.location.search,
      new URLSearchParams(window.location.search),
      searchCid,
      "----------------------searchCid"
    );
    if (searchCid) {
      filterData.cid = searchCid;
    }

    this.setState({ filterData }, function () {
      self.fetchOrgData();
    });
  }

  render() {
    const { filterData, tableLoading } = this.state;
    const self = this;
    const { match } = this.props;
    const dataColumns = [
      {
        title: "编号",
        dataIndex: "id",
        key: "id",
        render: (cur, record, index) => {
          return (self.pageNum - 1) * 20 + index + 1;
        },
      },
      {
        title: "日期",
        dataIndex: "day",
        key: "day",
        render: (qrcode, record) => {
          return <div>{qrcode && moment(qrcode).format("YYYY-MM-DD")} </div>;
        },
      },
      {
        title: "车牌号",
        dataIndex: "carCode",
        key: "carCode",
      },
      {
        title: "GPS设备号",
        dataIndex: "cid",
        key: "cid",
      },

      {
        title: "上报次数",
        dataIndex: "pingTimes",
        key: "pingTimes",
      },
      {
        title: "行驶距离",
        dataIndex: "distanceMi",
        key: "distanceMi",
        render: (qrcode, record) => {
          console.log(record);
          // 定义两个日期字符串
          const startDateStr = moment(record.startTime).format(
            "YYYY-MM-DD HH:mm"
          );
          const endDateStr = moment(record.endTime).format("YYYY-MM-DD HH:mm");
          // 将日期字符串转换为Date对象
          const startDate = new Date(startDateStr.replace(/-/g, "/")); // 注意：这里的替换操作是因为JavaScript的Date对象对于日期格式较为严格，直接使用"-"可能会导致解析错误
          const endDate = new Date(endDateStr.replace(/-/g, "/"));
          // 计算时间差，结果为毫秒
          const timeDifference = endDate.getTime() - startDate.getTime();
          // 将毫秒转换为分钟
          const minutesDifference = Math.round(timeDifference / (1000 * 60));
          return qrcode > 0 ? (
            <div>{qrcode}米</div>
          ) : (
            <div>停留{minutesDifference}分钟</div>
          );
        },
      },
      {
        title: "区间开始时间",
        dataIndex: "startTime",
        key: "startTime",
        render: (qrcode, record) => {
          return (
            <div>{qrcode && moment(qrcode).format("YYYY-MM-DD HH:mm")} </div>
          );
        },
      },
      {
        title: "区间结束时间",
        dataIndex: "endTime",
        key: "endTime",
        render: (qrcode, record) => {
          return (
            <div>{qrcode && moment(qrcode).format("YYYY-MM-DD HH:mm")} </div>
          );
        },
      },
      {
        title: "开始坐标",
        dataIndex: "startLatLng",
        key: "startLatLng",
        render: (cur, record) => {
          return (
            <div className="optbtn">
              <a
                href={`https://apis.map.qq.com/uri/v1/marker?marker=coord:${
                  cur.split(",")[0]
                },${cur.split(",")[1]};title:${record.cid};addr:${
                  record.cid
                }&referer=myapp`}
                target="_blank"
              >
                {cur}
              </a>
            </div>
          );
        },
      },
      {
        title: "结束坐标",
        dataIndex: "endLatLng",
        key: "endLatLng",
        render: (cur, record) => {
          return (
            <div className="optbtn">
              <a
                href={`https://apis.map.qq.com/uri/v1/marker?marker=coord:${
                  cur.split(",")[0]
                },${cur.split(",")[1]};title:${record.cid};addr:${
                  record.cid
                }&referer=myapp`}
                target="_blank"
              >
                {cur}
              </a>
            </div>
          );
        },
      },
    ];
    return (
      <div style={{ flex: 1 }}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Icon type="home" /> 首页
          </Breadcrumb.Item>
          <Breadcrumb.Item>轨迹列表</Breadcrumb.Item>
        </Breadcrumb>
        <div className="mt-20">
          <Form layout="inline" style={{ flex: 1 }}>
            <Form.Item label="GPS设备号">
              <Input
                placeholder="GPS设备号"
                allowClear
                onChange={(e) => {
                  const { filterData } = this.state;
                  filterData.cid = e.target.value;
                  this.setState({ filterData });
                }}
                value={filterData.cid}
                style={{ width: 200 }}
              ></Input>
            </Form.Item>
            <Form.Item label="车牌号">
              <Input
                placeholder="车牌号"
                allowClear
                onChange={(e) => {
                  const { filterData } = this.state;
                  filterData.carCode = e.target.value;
                  this.setState({ filterData });
                }}
                value={filterData.carCode}
                style={{ width: 200 }}
              ></Input>
            </Form.Item>
            <Form.Item label="日期">
              {/* <DatePicker
                onChange={(data) => {
                  if (!data) return;
                  const { filterData } = this.state;
                  filterData.day = data.format("YYYY-MM-DD");
                  this.setState({ filterData });
                }}
                // value={filterData.day}
                style={{ width: 200 }}
              /> */}
              <DatePicker
                format="YYYY-MM-DD"
                onChange={(date, timeString) => {
                  if (timeString === "" && filterData.day !== undefined) {
                    delete filterData.day;
                  } else {
                    const { filterData } = this.state;
                    filterData.day = timeString;
                    this.setState({ filterData });
                  }
                }}
                style={{ width: 200 }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                onClick={() => {
                  this.pageNum = 1;
                  this.fetchOrgData();
                }}
                type="primary"
              >
                搜索
              </Button>
            </Form.Item>
          </Form>
          <div className="card-container" style={{ marginTop: 20 }}>
            <Table
              loading={tableLoading}
              scroll={{ x: 1300 }}
              dataSource={this.state.dataSource.datas}
              pagination={{
                total: this.state.dataSource.total || 0,
                showTotal: (total) =>
                  `共 ${this.state.dataSource.total || 0} 条数据`,
                pageSize: 20,
                current: this.pageNum,
                onChange: (page) => {
                  this.pageNum = page;
                  this.fetchOrgData();
                },
              }}
              columns={dataColumns}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default GpsLogList;
