import React from 'react';
import {Form, Select} from 'antd';

import debounce from 'lodash/debounce';
import Axios from "../../util/request";

const {Option} = Select;

class CommunitySelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      communityList: [],
    };
    this.fetchData = debounce(this.fetchData, 800);
  };

  fetchData = (url, val) => {  //筛选下拉查询
    this.lastFetchId += 1;
    // const fetchId = this.lastFetchId;
    const data = {
      page: 1,
      rows: 20,
      params: JSON.stringify({"communityName":val})
    }
    Axios({url, method: 'get', data }, true).then(result => {

      if(result.success) {
        let communityList = [];

        communityList = result.data.map(d => ({
          text: `${d.communityName}`,
          value: d.communityCode,
          areaCode: d.areaCode,
          orgCode: d.orgCode
        }));

        this.setState({communityList});

        // callBack(result.data);
      }

    });

  };

  render() {
    const { communityList} = this.state;
    return (
        <Select
          onFocus={()=>{
            this.setState({communityList: []});
          }}
          showSearch
          allowClear
          onSearch={(value)=>{
            this.fetchData("/server/admin/communityNew/getCommunityList?communityName=" + value);
          }}
          onChange={(val,d) => {
            this.props.onChange(val,d ? d.props.orgCode : '',d ? d.key : '',d ? d.props.item : '',)
          }}
          style={{width: 200}}
          placeholder="社区"
          filterOption={false}
          value={this.props.value}
        >
          {communityList.map(d => (
            <Option key={d.text} value={d.value} orgCode={d.orgCode} item={d}>{d.text}</Option>
          ))}
        </Select>
    )
  }



}

export default CommunitySelect;
