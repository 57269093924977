import React from 'react';
import {Row, Col, Breadcrumb, message, Upload, InputNumber, Divider, Card, List, Radio} from 'antd';
import {Form, Icon, Input, Button, Select, Modal} from 'antd';

import debounce from 'lodash/debounce';
import Axios from "../../../util/request";

import moment from "moment";
// import FranchiseeSelect from "../../../components/select/FranchiseeSelect";
import StreetSelect from "../../../components/select/StreetSelect";

const {Option} = Select;
const {TextArea} = Input;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

class UpdataTeamInfoModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: {
        title: '提示',
        visible: false,
        width: 600,
        content: function () {
          return <span></span>
        },
        loading: false
      },
      record: {},
      filterData: {},
      id:null
    };
    if(props.onRef){//如果父组件传来该方法 则调用方法将子组件this指针传过去
      props.onRef(this)
    }
  };


  showDetail = (record) => {
    var {modal,filterData} = this.state;
    const self = this;
    this.props.form.resetFields();
    modal.visible = true;
    modal.width = 600;
    modal.loading = false;

    if(record && record.id){
      modal.title = <span>修改服务组信息</span>;
      filterData = record
    }else{
      modal.title = <span>新增服务组信息</span>;
      filterData.state = "1";
    }
    self.setState({modal,filterData});

  };

  handleCancel = () => {
    const {modal} = this.state;
    modal.visible = false;
    this.setState({modal});
  };

  handleOk = () => {
    const self = this;
    const {filterData} = this.state;
    Axios({
      url: '/server/admin/team/updateTeamInfo',
      method: 'post',
      data:filterData,
      type: 'json'
    }, true).then(result => {
      if(result.success){
        message.success('修改成功');
        self.props.fetchOrgData();
        const {modal} = this.state;
        modal.visible = false;
        this.setState({modal});
      }
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log(values);
      }
    });
  };

  componentDidMount(){
    const self = this;
  };

  render(){
    const {filterData,modal,communityList ,sortersList ,TeamCommunity, TeamUser} = this.state;
    const {match} = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        onCancel={this.handleCancel}
        visible={modal.visible}
        width={modal.width}
        title={modal.title}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={this.handleOk}>
            确定
          </Button>,
        ]}
      >
        <div>
          <Form name="resources" style={{maxWidth: "1000px"}} {...layout} onSubmit={this.handleSubmit}>
            <Form.Item name={['resources', 'teamName']} label="服务组名称">
              {getFieldDecorator('teamName', {
                rules: [
                  { required: true, message: '请输入服务组名称!' },
                ],
                initialValue:filterData.teamName || ''
              })(<Input onChange={e => {
                const {filterData} = this.state;
                filterData.teamName = e.target.value || '';
                this.setState({filterData});
              }} placeholder="服务组名称" />)}
            </Form.Item>
            <Form.Item label="街道">
              <StreetSelect value={filterData.areaCode} onChange={(val) =>{
                const {filterData} = this.state;
                filterData.areaCode = val;
                this.setState({filterData});
              }}></StreetSelect>
            </Form.Item>
            <Form.Item name={['team', 'desc']} label="简介">
              {getFieldDecorator('desc', {
                initialValue:filterData.desc || ''
              })(<TextArea onChange={(e)=>{
                const {filterData} = this.state;
                filterData.desc = e.currentTarget.value || '';
                this.setState({filterData});
              }} rows={4} placeholder='简介' />)}
            </Form.Item>
            <Form.Item name={['classifier', 'state']} label="状态">
              {getFieldDecorator('state', {
                initialValue: filterData.state + ""
              })(<Select
                  onChange={val => {
                    const {filterData} = this.state;
                    filterData.state = val;
                    this.setState({filterData});
                  }}
                  style={{ width: 300 }}
              >
                <Option key={1} value={"1"}>正常</Option>
                <Option key={0} value={"0"}>禁用</Option>
              </Select>)}
            </Form.Item>
          </Form>
        </div>
      </Modal>
    )
  }
}
export default Form.create({ name: 'submit' })(UpdataTeamInfoModal);
