import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './style.scss';
import App from './App';
import LoginPage from './pages/login/LoginPage';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { createHashHistory,createBrowserHistory } from 'history';
const history = createBrowserHistory();
moment.locale('zh-cn');
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

ReactDOM.render(
  <Router history={history}>
    <Route path="/admin" component={App} />
    <Route path="/login" component={LoginPage} />
  </Router>,
  document.getElementById('root')
);

function NoMatch({ location }) {
  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
