import React from "react";
import {
  Row,
  Col,
  Breadcrumb,
  message,
  Upload,
  InputNumber,
  Divider,
  Checkbox,
} from "antd";
import { Form, Icon, Input, Button, Select, Modal } from "antd";

import Axios from "../../../util/request";

import moment from "moment";
import FranchiseeSelect from "../../../components/select/FranchiseeSelect";
import CommunitySelect from "../../../components/select/CommunitySelect";
import UserSelect from "../../../components/select/UserSelect";
import { orgServices } from "../../../util/publicData";
// import { recyclerServices } from "../../../util/publicData";
import StreetSelect from "../../../components/select/StreetSelect";

const { Option } = Select;
const { TextArea } = Input;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("分类图片请选择 JPG/PNG !");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("分类图片最大 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

class UpdateUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        title: "提示",
        visible: false,
        width: 600,
        token: null,
        content: function () {
          return <span></span>;
        },
        loading: false,
        userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
      },
      filterData: {}, //用户信息
      imageUrl: "",
      areaList: [],
      committeeCodeList: [],
      carList: [],
    };
    if (props.onRef) {
      //如果父组件传来该方法 则调用方法将子组件this指针传过去
      props.onRef(this);
    }
  }

  getUploadToken = (values) => {
    //提交新增用户信息方法
    const self = this;
    Axios(
      {
        url: "/server/admin/qiniu/getUploadToken",
        method: "get",
        data: values,
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        self.setState({ token: result.uploadToken });
      }
    });
  };

  showDetail = (record) => {
    const { modal } = this.state;
    const self = this;
    this.props.form.resetFields();
    modal.visible = true;
    modal.width = 600;
    modal.loading = false;
    if (record && record.id) {
      modal.title = <span>修改用户信息</span>;

      const filterData = { ...record };
      // filterData.areaCodeList = filterData.areaCodeList
      //   ? filterData.areaCodeList.split(",")
      //   : "";
      // filterData.committeeCodeList = filterData.committeeCodeList
      //   ? filterData.committeeCodeList.split(",")
      //   : "";

      // filterData.recyclerType = [];
      // recyclerServices.forEach((d) => {
      //   if (filterData[d.sqlName] > 0) {
      //     filterData.recyclerType.push(filterData[d.sqlName]);
      //   }
      // });
      // if (
      //   recyclerServices &&
      //   filterData.recyclerType &&
      //   recyclerServices.filter(
      //     (item) =>
      //       filterData.recyclerType.indexOf(item.value) > -1 && item.isCar
      //   ).length > 0
      // ) {
      //   self.getCarList({
      //     carType: recyclerServices
      //       .filter(
      //         (item) =>
      //           filterData.recyclerType.indexOf(item.value) > -1 && item.isCar
      //       )
      //       .map((d) => d.carType),
      //   });
      // }
      // if (filterData.carList) {
      //   filterData.carList = filterData.carList
      //     .split(",")
      //     .filter((d) => d)
      //     .map((d) => parseInt(d));
      // } else {
      //   filterData.carList = [];
      // }

      self.setState({
        modal,
        record,
        filterData,
        imageUrl: record.userHeadImg,
      });
    } else {
      modal.title = <span>新增用户信息</span>;
      const filterData = {};
      filterData.state = 1;
      self.setState({ modal, filterData, imageUrl: "" });
    }
  };

  handleCancel = () => {
    const { modal } = this.state;
    modal.visible = false;
    this.setState({ modal });
  };

  handleOk = () => {
    console.log("1234566");
    const self = this;
    const { form } = this.props;
    const { filterData, imageUrl } = this.state;
    filterData.userHeadImg = imageUrl ? imageUrl : "";

    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      // recyclerServices.forEach((d) => {
      //   if (filterData.recyclerType.indexOf(d.value) > -1) {
      //     filterData[d.sqlName] = d.value;
      //   } else {
      //     filterData[d.sqlName] = 0;
      //   }
      // });

      // delete filterData.recyclerType;
      console.log("JFK是的降幅扩大解放健康的jf");
      Axios(
        {
          url: "/server/admin/worker/updateUser",
          // url: "/server/admin/worker/getUserInfo",
          method: "post",
          data: filterData,
          type: "json",
        },
        true
      ).then((result) => {
        if (result.success) {
          message.success("修改成功");
          self.props.fetchOrgData();
          const { modal } = this.state;
          modal.visible = false;
          self.setState({ modal });
        }
      });
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log(values);
      }
    });
  };

  handleChange = (info) => {
    if (info.file.status == "done") {
      this.setState({
        imageUrl: "https://static.aifenlei.com.cn/" + info.file.response.key,
        loading: false,
      });
    }
  };

  getCarList = (data) => {
    const self = this;
    Axios(
      {
        url: "/server/admin/car/getCarTypeList",
        method: "get",
        data: data,
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        self.setState({ carList: result.datas });
      }
    });
  };

  componentDidMount() {
    const self = this;
    this.getUploadToken();
  }

  render() {
    const { filterData, modal, areaList, imageUrl, token, carList } =
      this.state;
    const { match } = this.props;
    const { getFieldDecorator } = this.props.form;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">上传图片</div>
      </div>
    );
    return (
      <Modal
        onCancel={this.handleCancel}
        visible={modal.visible}
        width={modal.width}
        title={modal.title}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={this.handleOk}>
            确定
          </Button>,
        ]}
      >
        <div>
          <Form
            name="nest-messages"
            style={{ maxWidth: "1000px" }}
            {...layout}
            onSubmit={this.handleSubmit}
          >
            <Row>
              <Col span={24}>
                {/*<Form.Item name={['classifier', 'userId']} label="绑定用户">*/}
                {/*  {getFieldDecorator('userId', {*/}
                {/*    rules: [*/}
                {/*      { required: true, message: '请选择绑定用户!' },*/}
                {/*    ],*/}
                {/*    initialValue:filterData.userId*/}
                {/*  })(<UserSelect onChange={(val) =>{*/}
                {/*    const {filterData} = this.state;*/}
                {/*    filterData.userId = val;*/}
                {/*    this.setState({filterData});*/}
                {/*  }}></UserSelect>)}*/}
                {/*</Form.Item>*/}
                <Form.Item name={["classifier", "userName"]} label="姓名">
                  {getFieldDecorator("userName", {
                    rules: [{ required: true, message: "请输入姓名!" }],
                    initialValue: filterData.userName,
                  })(
                    <Input
                      placeholder="姓名"
                      style={{ width: 300 }}
                      onChange={(e) => {
                        const { filterData } = this.state;
                        filterData.userName = e.target.value;
                        this.setState({ filterData });
                      }}
                    />
                  )}
                </Form.Item>
                <Form.Item name={["classifier", "mobile"]} label="手机号">
                  {getFieldDecorator("mobile", {
                    rules: [{ required: true, message: "请输入手机号!" }],
                    initialValue: filterData.mobile,
                  })(
                    <Input
                      placeholder="手机号"
                      style={{ width: 300 }}
                      onChange={(e) => {
                        const { filterData } = this.state;
                        filterData.mobile = e.target.value;
                        this.setState({ filterData });
                      }}
                    />
                  )}
                </Form.Item>
                <Form.Item name={["classifier", "openId"]} label="openId">
                  {getFieldDecorator("openId", {
                    rules: [{ required: true, message: "请输入openId!" }],
                    initialValue: filterData.openId,
                  })(
                    <Input
                      placeholder="openId"
                      style={{ width: 300 }}
                      onChange={(e) => {
                        const { filterData } = this.state;
                        filterData.openId = e.target.value;
                        this.setState({ filterData });
                      }}
                    />
                  )}
                </Form.Item>
                <Form.Item name={["classifier", "state"]} label="是否通过">
                  {getFieldDecorator("state", {
                    initialValue: filterData.state + "",
                  })(
                    <Select
                      onChange={(val) => {
                        const { filterData } = this.state;
                        filterData.state = val;
                        this.setState({ filterData });
                      }}
                      style={{ width: 300 }}
                    >
                      <Option key={0} value={"0"}>
                        不通过
                      </Option>
                      <Option key={1} value={"1"}>
                        通过
                      </Option>
                    </Select>
                  )}
                </Form.Item>
                <Divider dashed={true} />
                <Form.Item name={["resources", "orgImg"]} label="头像">
                  <Upload
                    name="file"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    data={(file) => ({
                      token,
                      key:
                        "/huanwei/worker/" + (new Date().getTime() + file.name),
                    })}
                    action="https://upload-z1.qiniup.com"
                    beforeUpload={beforeUpload}
                    onChange={this.handleChange}
                  >
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        alt="avatar"
                        style={{ maxWidth: "100%" }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    );
  }
}
export default Form.create({ name: "submit" })(UpdateUsers);
