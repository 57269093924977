import React from "react";
import {
  Row,
  Col,
  Form,
  Icon,
  Input,
  Button,
  Table,
  Tag,
  Select,
  DatePicker,
  Breadcrumb,
  Radio,
  message,
} from "antd";

import Axios from "../../util/request";
import moment from "moment";
import UpdataCar from "./components/UpdataCar";
import debounce from "lodash/debounce";
import Zmage from "react-zmage";
import { carTypeList } from "../../util/publicData";
import CarTypeSelect from "../../components/select/CarTypeSelect";

const { Option } = Select;

class CarList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData: {}, //检索数据
      dataSource: {}, //列表数据
      provinceList: [],
      pageNum: 1,
      tableLoading: true,
      projectNameMap: [],
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || "",
    };
    this.pageNum = 1;
    this.projectNameMap = [];
    this.fetchOrgData = debounce(this.fetchOrgData, 800);
    this.fetchData = debounce(this.fetchData, 800);
  }
  fetchData = (url, type) => {
    const self = this;
    //筛选下拉查询
    Axios({ url, method: "get" }, true).then((result) => {
      if (result.success) {
        let projectNameMap = [];
        projectNameMap = result.data.map((item) => {
          return { label: item.name, value: item.id };
        });
        self.setState({ projectNameMap });
      }
    });
  };

  // 获取table数据
  fetchOrgData = () => {
    const self = this;
    const { filterData, timeString } = this.state;
    const page = this.pageNum || 1;
    this.setState({ tableLoading: true });
    if (!filterData.state) {
      delete filterData.state;
    }

    const params = JSON.stringify(filterData);
    // 拉取预约数据
    Axios(
      {
        url: "/server/admin/car/getCarList",
        data: { page, rows: 20, params },
        method: "get",
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        self.setState({ dataSource: result, tableLoading: false });
      }
    });
  };
  getProjectNameMap = () => {
    const self = this;
    const { userInfo } = this.state;
    Axios(
      {
        // url: "/server/admin/worker/getUserList",
        url: "/server/admin/project/getProjects",
        data: { page: 1, rows: 200, params: JSON.stringify({}) },
        method: "get",
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        self.projectNameMap = result.data.map((item) => {
          return { label: item.name, value: item.id };
        });
        self.setState({
          projectNameMap: self.projectNameMap,
        });
      }
    });
  };
  componentDidMount() {
    const self = this;
    const { filterData, userInfo } = this.state;
    const { search } = this.props.location;
    const paramsString = search.substring(1);
    const searchParams = new URLSearchParams(paramsString);
    const sortersName = searchParams.get("sortersName");
    if (userInfo.projectId != null) {
      filterData.projectId = userInfo.projectId;
    }
    if (filterData) {
      filterData.sortersName = sortersName;
    }
    this.setState({ filterData }, function () {
      self.fetchOrgData();
    });
    this.getProjectNameMap();
    console.log(this.state.userInfo, "------------userInfo");
  }

  render() {
    const { filterData, tableLoading, userInfo } = this.state;
    const { match } = this.props;
    const self = this;
    const dataColumns = [
      {
        title: "编号",
        dataIndex: "id",
        key: "id",
        render: (cur, record, index) => {
          return (self.pageNum - 1) * 20 + index + 1;
        },
      },
      {
        title: "绑定驾驶员",
        dataIndex: "classifierNames",
        key: "classifierNames",
      },
      {
        title: "绑定装卸工",
        dataIndex: "loaderNames",
        key: "loaderNames",
      },
      {
        title: "车牌号",
        dataIndex: "code",
        key: "code",
      },
      {
        title: "项目",
        dataIndex: "projectName",
        key: "projectName",
      },
      {
        title: "类型",
        dataIndex: "type",
        key: "type",
        render: (cur, record) => {
          return (
            <div>
              {((type) => {
                return carTypeList.map((d) => {
                  if (type == d.value) {
                    return d.label + " ";
                  } else {
                    return "";
                  }
                });
              })(cur)}
            </div>
          );
        },
      },
      {
        title: "车辆保险日期",
        dataIndex: "insureStartTime",
        key: "insureStartTime",
        render: (cur, record) => {
          return cur ? (
            <div>
              {cur && moment(cur).format("YYYY-MM-DD")} -{" "}
              {record.insureEndTime &&
                moment(record.insureEndTime).format("YYYY-MM-DD")}
              {record.isInsure == 1 ? <Tag color="#f50">需保险</Tag> : ""}
            </div>
          ) : (
            ""
          );
        },
      },
      {
        title: "车辆下次年检日期",
        dataIndex: "nextInspectTime",
        key: "nextInspectTime",
        render: (cur, record) => {
          return (
            <div>
              {cur && moment(cur).format("YYYY-MM-DD")}
              {record.isDetection == 1 ? <Tag color="#f50">需年检</Tag> : ""}
            </div>
          );
        },
      },
      {
        title: "车辆生产日期",
        dataIndex: "produceTime",
        key: "produceTime",
        render: (cur, record) => {
          return <div>{cur && moment(cur).format("YYYY-MM-DD")} </div>;
        },
      },
      {
        title: "状态",
        dataIndex: "state",
        key: "state",
        render: (cur, record) => {
          return (
            <div>
              {cur == 1 ? (
                <Tag color="#87d068">通过</Tag>
              ) : (
                <Tag color="#f50">不通过</Tag>
              )}
            </div>
          );
        },
      },
      {
        title: "gps",
        dataIndex: "gpsCid",
        key: "gpsCid",
        render: (cur) => {
          return (
            <div
              style={{ cursor: "pointer", color: "rgb(64,169,255)" }}
              onClick={() => {
                window.open("/admin/car/gpsLogList?cid=" + cur);
              }}
            >
              {cur}
            </div>
          );
        },
      },
      {
        title: "创建时间",
        dataIndex: "createTime",
        key: "createTime",
        render: (qrcode, record) => {
          return <div>{qrcode && moment(qrcode).format("YYYY-MM-DD")} </div>;
        },
      },
      {
        title: "昨日里程(公里)",
        dataIndex: "distanceKm",
        key: "distanceKm",
      },
      {
        title: "轨迹",
        render: (qrcode, record) => {
          return (
            <div>
              <div className="optbtn">
                {record.gpsCid ? (
                  <a
                    title="轨迹地图"
                    target="_blank"
                    href={`http://hw.aifenlei.com.cn/data/index.html?id=${record.gpsCid}`}
                  >
                    轨迹地图
                  </a>
                ) : (
                  ""
                )}
              </div>
              <div className="optbtn">
                {record.gpsCid ? (
                  <div
                    style={{ cursor: "pointer", color: "rgb(255,45,32)" }}
                    onClick={() => {
                      window.open("/admin/car/gpsLogList?cid=" + record.gpsCid);
                    }}
                  >
                    &nbsp;轨迹列表
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        },
      },
      {
        title: "操作",
        render: (qrcode, record) => {
          return (
            <div className="optbtn">
              <Button
                type="primary"
                icon="eye"
                title="查看"
                onClick={() => {
                  window.open("/admin/car/carListDetail/?code=" + record.code);
                }}
              ></Button>
              <Button
                type="primary"
                icon="edit"
                title="编辑"
                onClick={() => this.UpdataCar.showDetail(record)}
              ></Button>
            </div>
          );
        },
      },
    ];
    return (
      <div style={{ flex: 1 }}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Icon type="home" /> 首页
          </Breadcrumb.Item>
          <Breadcrumb.Item>车辆管理</Breadcrumb.Item>
        </Breadcrumb>
        <div className="mt-20">
          <Form layout="inline" style={{ flex: 1 }}>
            <Form.Item label="车牌号">
              <Input
                placeholder="车牌号"
                allowClear
                onChange={(e) => {
                  const { filterData } = this.state;
                  filterData.code = e.target.value;
                  this.setState({ filterData });
                }}
                value={filterData.code}
                style={{ width: 200 }}
              ></Input>
            </Form.Item>
            <Form.Item label="项目">
              <Select
                defaultValue={userInfo.projectId || undefined}
                disabled={userInfo.projectId != null ? true : false}
                onChange={(val) => {
                  const { filterData } = this.state;
                  filterData.projectId = val;
                  this.setState({ filterData });
                }}
                onSearch={(value) => {
                  self.fetchData(
                    "/server/admin/project/getProjects?projectName=" + value
                  );
                }}
                onSelect={() => {
                  self.setState({ projectNameMap: self.projectNameMap });
                }}
                placeholder="项目"
                style={{ width: 120 }}
                allowClear
                showSearch
                filterOption={false}
              >
                {this.state.projectNameMap.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="类型">
              <CarTypeSelect
                onChange={(val) => {
                  const { filterData } = this.state;
                  filterData.type = val;
                  this.setState({ filterData });
                }}
                value={filterData.type}
              ></CarTypeSelect>
            </Form.Item>
            <Form.Item>
              <Button
                onClick={() => {
                  this.pageNum = 1;
                  this.fetchOrgData();
                }}
                type="primary"
              >
                搜索
              </Button>
            </Form.Item>
          </Form>
          <div className="mt-20">
            <div className="listQuerydata">
              {/*<Card>*/}
              <div className="row">
                <div className="item">
                  <div className="value">
                    <span className="num">{this.state.dataSource.total}</span>
                  </div>
                  <div className="label">总车辆数</div>
                </div>
                <div className="item">
                  <div className="value">
                    <span className="num">
                      {this.state.dataSource.insureCount}
                    </span>
                  </div>
                  <div className="label">需保险车辆数</div>
                </div>
                <div className="item">
                  <div className="value">
                    <span className="num">
                      {this.state.dataSource.detectionCount}
                    </span>
                  </div>
                  <div className="label">需年检车辆数</div>
                </div>
                {/*<div className="item">*/}
                {/*  <div className="value"><span className="num">{totalData.orderMoney}</span></div>*/}
                {/*  <div className="label">取消订单金额</div>*/}
                {/*</div>*/}
              </div>
              {/*</Card>*/}
            </div>
          </div>
          <br />
          <div style={{ textAlign: "right" }}>
            <Form.Item>
              <Button
                onClick={() => {
                  this.UpdataCar.showDetail();
                }}
                type="primary"
              >
                新增车辆
              </Button>
            </Form.Item>
          </div>
          <div className="card-container" style={{ marginTop: 20 }}>
            <Table
              loading={tableLoading}
              scroll={{ x: 1300 }}
              dataSource={this.state.dataSource.datas}
              pagination={{
                total: this.state.dataSource.total || 0,
                showTotal: (total) =>
                  `共 ${this.state.dataSource.total || 0} 条数据`,
                pageSize: 20,
                current: this.pageNum,
                onChange: (page) => {
                  this.pageNum = page;
                  this.fetchOrgData();
                },
              }}
              columns={dataColumns}
            />
          </div>
        </div>
        <UpdataCar
          fetchOrgData={this.fetchOrgData}
          onRef={(c) => (this.UpdataCar = c)}
        ></UpdataCar>
      </div>
    );
  }
}

export default CarList;
