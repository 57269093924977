import reqwest from 'reqwest';
import {Message} from 'antd';

const Axios = function (obj, needToken = false, toast = true) {

  // 查看接口请求到响应完成时间
  console.time(obj.url)

  // 请求接口时的loading效果
  // if (loading) {
  //     this.$Indicator.open()
  //     loading = false
  // }

  let headers = {}

  // 签名
  // if (!/^https:\/\/|http:\/\//.test(obj.url)) {
  //     const token = util.cookies.get("token");
  //     let timestamp = obj.timestamp || parseInt(+new Date() / 1000);
  //     headers = {
  //         "timestamp": timestamp,
  //         "signature": sign(obj.data || {}, timestamp),
  //         "token": token
  //     }
  // }

  if(needToken){
    const token = localStorage.getItem("token");
    if(!token){
      return window.location.href = "/login";
    }

    headers = {
      "token" : localStorage.getItem("token")
    }
  }

  return new Promise((response, reject) => {

    // 默认post，
    let method = obj.method || "post";
    let param = {};
    if(method == "post"){
      param = {
        url: obj.url,
        method,
        type: 'json',
        contentType: 'application/json',
        data: obj.data ? JSON.stringify(obj.data) : {},
        headers: headers
      }
    }else{
      param = {
        url: obj.url,
        method,
        data: obj.data ? obj.data : {},
        headers: headers
      }
    }
  reqwest(param)
    .then(data => {
        // console.timeEnd(obj.url)
        // // code等于3表示token失效需要重新登陆
        if (data.errorCode == "41001") {
          window.location.href = "/login";
          return;
        }
        //
        // // 与后端定义：code为0表示接口按预期返回
        if (!data.success && !data.sucess) {
          // 报错 todo全局报错
          Message.error(data.message || "系统繁忙，请稍后重试");
        }

        response(data);
    })
    .catch(rej => {
      Message.error("系统繁忙，请稍后重试");
        reject(rej);
    });
  });
};

export default Axios;
