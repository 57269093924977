import React from "react";
import {
  Row,
  Col,
  Breadcrumb,
  message,
  Upload,
  InputNumber,
  Divider,
  Checkbox,
} from "antd";
import { Form, Icon, Input, Button, Select, Modal } from "antd";
import { debounce } from "lodash";
import Axios from "../../../util/request";

import moment from "moment";
import FranchiseeSelect from "../../../components/select/FranchiseeSelect";
import CommunitySelect from "../../../components/select/CommunitySelect";
import UserSelect from "../../../components/select/UserSelect";
import { orgServices } from "../../../util/publicData";
import { recyclerServices } from "../../../util/publicData";
import StreetSelect from "../../../components/select/StreetSelect";

const { Option } = Select;
const { TextArea } = Input;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("分类图片请选择 JPG/PNG !");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("分类图片最大 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

class UpdataWorkers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        title: "提示",
        visible: false,
        width: 600,
        token: null,
        content: function () {
          return <span></span>;
        },
        loading: false,
        userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
      },
      filterData: {}, //用户信息
      imageUrl: "",
      areaList: [],
      committeeCodeList: [],
      carList: [],
      projectNameMap: [],
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
    };
    this.projectNameMap = [];
    if (props.onRef) {
      //如果父组件传来该方法 则调用方法将子组件this指针传过去
      props.onRef(this);
    }
    this.fetchData = debounce(this.fetchData, 800);
    this.getCarList = debounce(this.getCarList, 800);
  }
  fetchData = (url, type) => {
    const self = this;
    //筛选下拉查询
    Axios({ url, method: "get" }, true).then((result) => {
      if (result.success) {
        let projectNameMap = [];
        projectNameMap = result.data.map((item) => {
          return { label: item.name, value: item.id };
        });
        self.setState({ projectNameMap });
      }
    });
  };

  getUploadToken = (values) => {
    //提交新增用户信息方法
    const self = this;
    Axios(
      {
        url: "/server/admin/qiniu/getUploadToken",
        method: "get",
        data: values,
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        self.setState({ token: result.uploadToken });
      }
    });
  };

  showDetail = (record) => {
    const { modal } = this.state;
    const self = this;
    this.props.form.resetFields();
    modal.visible = true;
    modal.width = 600;
    modal.loading = false;
    if (record && record.id) {
      modal.title = <span>修改员工信息</span>;

      const filterData = { ...record };
      filterData.areaCodeList = filterData.areaCodeList
        ? filterData.areaCodeList.split(",")
        : "";
      filterData.committeeCodeList = filterData.committeeCodeList
        ? filterData.committeeCodeList.split(",")
        : "";

      filterData.recyclerType = [];
      recyclerServices.forEach((d) => {
        if (filterData[d.sqlName] > 0) {
          filterData.recyclerType.push(filterData[d.sqlName]);
        }
      });
      if (
        recyclerServices &&
        filterData.recyclerType &&
        recyclerServices.filter(
          (item) =>
            filterData.recyclerType.indexOf(item.value) > -1 && item.isCar
        ).length > 0
      ) {
        self.getCarList({
          carType: recyclerServices
            .filter(
              (item) =>
                filterData.recyclerType.indexOf(item.value) > -1 && item.isCar
            )
            .map((d) => d.carType),
        });
      }
      if (filterData.carList) {
        filterData.carList = filterData.carList
          .split(",")
          .filter((d) => d)
          .map((d) => parseInt(d));
      } else {
        filterData.carList = [];
      }

      self.setState({
        modal,
        record,
        filterData,
        imageUrl: record.classifierHeadImg,
      });
    } else {
      modal.title = <span>新增员工信息</span>;
      const filterData = {};
      filterData.state = 1;
      self.setState({ modal, filterData, imageUrl: "" });
    }
  };

  handleCancel = () => {
    const { modal } = this.state;
    modal.visible = false;
    this.setState({ modal });
  };

  handleOk = () => {
    const self = this;
    const { form } = this.props;
    const { filterData, imageUrl, userInfo } = this.state;
    filterData.classifierHeadImg = imageUrl ? imageUrl : "";
    if (userInfo.projectId) {
      filterData.projectId = userInfo.projectId;
    }

    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      recyclerServices.forEach((d) => {
        if (filterData.recyclerType.indexOf(d.value) > -1) {
          filterData[d.sqlName] = d.value;
        } else {
          filterData[d.sqlName] = 0;
        }
      });

      delete filterData.recyclerType;

      Axios(
        {
          url: "/server/admin/worker/updateWork",
          method: "post",
          data: filterData,
          type: "json",
        },
        true
      ).then((result) => {
        if (result.success) {
          message.success("修改成功");
          self.props.fetchOrgData();
          const { modal } = this.state;
          modal.visible = false;
          self.setState({ modal });
        }
      });
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log(values);
      }
    });
  };

  handleChange = (info) => {
    if (info.file.status == "done") {
      this.setState({
        imageUrl: "https://static.aifenlei.com.cn/" + info.file.response.key,
        loading: false,
      });
    }
  };

  getCarList = (data) => {
    const self = this;
    const { userInfo } = this.state;

    // 不管type   搜projectId = 1的车
    // if (userInfo.projectId == 1) {
    //   data.carType = [7, 8];
    // } else {
    //   delete data.carType;
    // }
    if (userInfo.projectId > 0) {
      data.projectId = userInfo.projectId;
    }
    console.log(data, "-----------------249");

    Axios(
      {
        url: "/server/admin/car/getCarTypeList",
        method: "get",
        data: data,
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        self.setState({ carList: result.datas });
      }
    });
  };
  getProjectNameMap = () => {
    const self = this;
    Axios(
      {
        // url: "/server/admin/worker/getUserList",
        url: "/server/admin/project/getProjects",
        data: { page: 1, rows: 200, params: JSON.stringify({}) },
        method: "get",
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        self.projectNameMap = result.data.map((item) => {
          return { label: item.name, value: item.id };
        });
        self.setState({
          projectNameMap: self.projectNameMap,
        });
      }
    });
  };
  componentDidMount() {
    const self = this;
    this.getUploadToken();
    this.getProjectNameMap();
  }

  render() {
    const self = this;
    const { filterData, modal, areaList, imageUrl, token, carList, userInfo } =
      this.state;
    const { match } = this.props;
    const { getFieldDecorator } = this.props.form;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">上传图片</div>
      </div>
    );
    return (
      <Modal
        onCancel={this.handleCancel}
        visible={modal.visible}
        width={modal.width}
        title={modal.title}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={this.handleOk}>
            确定
          </Button>,
        ]}
      >
        <div>
          <Form
            name="nest-messages"
            style={{ maxWidth: "1000px" }}
            {...layout}
            onSubmit={this.handleSubmit}
          >
            <Row>
              <Col span={24}>
                {/*<Form.Item name={['classifier', 'userId']} label="绑定用户">*/}
                {/*  {getFieldDecorator('userId', {*/}
                {/*    rules: [*/}
                {/*      { required: true, message: '请选择绑定用户!' },*/}
                {/*    ],*/}
                {/*    initialValue:filterData.userId*/}
                {/*  })(<UserSelect onChange={(val) =>{*/}
                {/*    const {filterData} = this.state;*/}
                {/*    filterData.userId = val;*/}
                {/*    this.setState({filterData});*/}
                {/*  }}></UserSelect>)}*/}
                {/*</Form.Item>*/}
                <Form.Item name={["classifier", "classifierName"]} label="姓名">
                  {getFieldDecorator("classifierName", {
                    rules: [{ required: true, message: "请输入姓名!" }],
                    initialValue: filterData.classifierName,
                  })(
                    <Input
                      placeholder="姓名"
                      style={{ width: 300 }}
                      onChange={(e) => {
                        const { filterData } = this.state;
                        filterData.classifierName = e.target.value;
                        this.setState({ filterData });
                      }}
                    />
                  )}
                </Form.Item>
                <Form.Item
                  name={["classifier", "classifierMobile"]}
                  label="手机号"
                >
                  {getFieldDecorator("classifierMobile", {
                    rules: [{ required: true, message: "请输入手机号!" }],
                    initialValue: filterData.classifierMobile,
                  })(
                    <Input
                      placeholder="手机号"
                      style={{ width: 300 }}
                      onChange={(e) => {
                        const { filterData } = this.state;
                        filterData.classifierMobile = e.target.value;
                        this.setState({ filterData });
                      }}
                    />
                  )}
                </Form.Item>
                <Form.Item
                  name={["classifier", "classifierAddress"]}
                  label="联系地址"
                >
                  {getFieldDecorator("classifierAddress", {
                    initialValue: filterData.classifierAddress,
                  })(
                    <Input
                      placeholder="联系地址"
                      style={{ width: 300 }}
                      onChange={(e) => {
                        const { filterData } = this.state;
                        filterData.classifierAddress = e.target.value;
                        this.setState({ filterData });
                      }}
                    />
                  )}
                </Form.Item>
                <Form.Item name={["classifier", "state"]} label="是否通过">
                  {getFieldDecorator("state", {
                    initialValue: filterData.state + "",
                  })(
                    <Select
                      onChange={(val) => {
                        const { filterData } = this.state;
                        filterData.state = val;
                        this.setState({ filterData });
                      }}
                      style={{ width: 300 }}
                    >
                      <Option key={0} value={"0"}>
                        不通过
                      </Option>
                      <Option key={1} value={"1"}>
                        通过
                      </Option>
                    </Select>
                  )}
                </Form.Item>

                <Form.Item name={["classifier", "recyclerType"]} label="角色">
                  {getFieldDecorator("recyclerType", {
                    initialValue: filterData.recyclerType
                      ? filterData.recyclerType
                      : [],
                  })(
                    <Checkbox.Group
                      options={recyclerServices}
                      onChange={(checkedValues) => {
                        const { filterData } = this.state;
                        filterData.recyclerType = checkedValues;
                        this.setState({ filterData });
                        if (
                          recyclerServices &&
                          filterData.recyclerType &&
                          recyclerServices.filter(
                            (item) =>
                              filterData.recyclerType.indexOf(item.value) >
                                -1 && item.isCar
                          ).length > 0
                        ) {
                          this.getCarList({
                            carType: recyclerServices
                              .filter(
                                (item) =>
                                  filterData.recyclerType.indexOf(item.value) >
                                    -1 && item.isCar
                              )
                              .map((d) => d.carType),
                          });
                        }
                      }}
                    />
                  )}
                </Form.Item>
                {recyclerServices &&
                filterData.recyclerType &&
                recyclerServices.filter(
                  (item) =>
                    filterData.recyclerType.indexOf(item.value) > -1 &&
                    item.isCar
                ).length > 0 ? (
                  <Form.Item name={["resources", "car"]} label="车辆">
                    <Select
                      showSearch
                      mode="multiple"
                      onSearch={(value) => {
                        this.getCarList({
                          carType: recyclerServices
                            .filter(
                              (item) =>
                                filterData.recyclerType.indexOf(item.value) >
                                  -1 && item.isCar
                            )
                            .map((d) => d.carType),
                          code: value,
                        });
                      }}
                      onChange={(val) => {
                        const { filterData } = this.state;
                        filterData.carList = val;
                        this.setState({ filterData });
                      }}
                      placeholder="车辆"
                      optionFilterProp="children"
                      value={filterData.carList}
                    >
                      {carList.map((d) => (
                        <Option key={d.id} value={d.id}>
                          {d.code}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  ""
                )}
                <Form.Item label="项目">
                  {getFieldDecorator("projectId", {
                    initialValue:
                      userInfo.projectId != null
                        ? userInfo.projectId
                        : filterData.projectId,
                  })(
                    <Select
                      disabled={userInfo.projectId != null ? true : false}
                      onChange={(val) => {
                        const { filterData } = this.state;
                        filterData.projectId = val;
                        this.setState({ filterData });
                      }}
                      onSearch={(value) => {
                        self.fetchData(
                          "/server/admin/project/getProjects?projectName=" +
                            value
                        );
                      }}
                      onSelect={() => {
                        self.setState({ projectNameMap: self.projectNameMap });
                      }}
                      placeholder="项目"
                      style={{ width: 120 }}
                      allowClear
                      showSearch
                      filterOption={false}
                    >
                      {this.state.projectNameMap.map((item) => (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
                <Divider dashed={true} />
                <Form.Item name={["resources", "orgImg"]} label="头像">
                  <Upload
                    name="file"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    data={(file) => ({
                      token,
                      key:
                        "/huanwei/worker/" + (new Date().getTime() + file.name),
                    })}
                    action="https://upload-z1.qiniup.com"
                    beforeUpload={beforeUpload}
                    onChange={this.handleChange}
                  >
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        alt="avatar"
                        style={{ maxWidth: "100%" }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    );
  }
}
export default Form.create({ name: "submit" })(UpdataWorkers);
