import React from "react";
import { Row, Col, message, Upload, Divider } from "antd";
import { Form, Icon, Input, Button, Select, Modal } from "antd";

import Axios from "../../../util/request";
import debounce from "lodash/debounce";
import moment from "moment";

const { Option } = Select;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

class UpdateTickets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        title: "提示",
        visible: false,
        width: 600,
        token: null,
        content: function () {
          return <span></span>;
        },
        loading: false,
        userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
      },
      filterData: {}, //用户信息
      areaList: [],
      committeeCodeList: [],
      carList: [],
      recvWorkMap: [],
      sendWorkerMap: [],
      projectNameMap: [],
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
    };
    this.sendWorkerMap = [];
    this.recvWorkMap = [];
    this.projectNameMap = [];
    this.status = "";
    this.recvWorkId = "";
    if (props.onRef) {
      //如果父组件传来该方法 则调用方法将子组件this指针传过去
      props.onRef(this);
    }
    this.fetchData = debounce(this.fetchData, 800);
    this.fetchPorjectData = debounce(this.fetchPorjectData, 800);
  }
  fetchPorjectData = (url, type) => {
    const self = this;
    //筛选下拉查询
    Axios({ url, method: "get" }, true).then((result) => {
      if (result.success) {
        let projectNameMap = [];
        projectNameMap = result.data.map((item) => {
          return { label: item.name, value: item.id };
        });
        self.setState({ projectNameMap });
      }
    });
  };
  getProjectNameMap = () => {
    const self = this;
    Axios(
      {
        // url: "/server/admin/worker/getUserList",
        url: "/server/admin/project/getProjects",
        data: { page: 1, rows: 200, params: JSON.stringify({}) },
        method: "get",
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        self.projectNameMap = result.data.map((item) => {
          return { label: item.name, value: item.id };
        });
        self.setState({
          projectNameMap: self.projectNameMap,
        });
      }
    });
  };

  fetchData = (url, type) => {
    const self = this;
    const { userInfo } = this.state;
    //筛选下拉查询
    Axios(
      {
        url,
        method: "get",
        data: { params: JSON.stringify({ projectId: userInfo.projectId }) },
      },
      true
    ).then((result) => {
      if (result.success) {
        if (type === "sendWorkerMap") {
          let sendWorkerMap = [];
          sendWorkerMap = result.data.map((item) => {
            return { label: item.classifierName, value: item.id };
          });
          self.setState({ sendWorkerMap });
        }

        if (type === "recvWorkMap") {
          let recvWorkMap = [];
          recvWorkMap = result.data.map((item) => {
            return { label: item.classifierName, value: item.id };
          });
          self.setState({ recvWorkMap });
        }

        // callBack(result.data);
      }
    });
  };
  getUploadToken = (values) => {
    //提交新增用户信息方法
    const self = this;
    Axios(
      {
        url: "/server/admin/qiniu/getUploadToken",
        method: "get",
        data: values,
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        self.setState({ token: result.uploadToken });
      }
    });
  };

  showDetail = (record) => {
    const { modal } = this.state;
    const self = this;
    this.props.form.resetFields();
    modal.visible = true;
    modal.width = 600;
    modal.loading = false;
    if (record && record.id) {
      modal.title = <span>修改工单信息</span>;

      const filterData = { ...record };
      self.setState(
        {
          modal,
          record,
          filterData,
        },
        function () {
          this.recvWorkId = this.state.filterData.recvWorkId;
          this.status = this.state.filterData.status;
          console.log(this.recvWorkId, this.status);
        }
      );
    } else {
      modal.title = <span>新增工单信息</span>;
      const filterData = {};
      filterData.state = 1;
      self.setState({ modal, filterData });
    }
  };

  handleCancel = () => {
    const { modal } = this.state;
    modal.visible = false;
    this.setState({ modal });
  };

  handleOk = () => {
    const self = this;
    const { form } = this.props;
    const { filterData, userInfo } = this.state;
    console.log(
      this.recvWorkId,
      this.status,
      filterData.recvWorkId,
      filterData.status
    );
    if (this.recvWorkId != filterData.recvWorkId) {
      let recvWorkName = this.recvWorkMap.filter(
        (item) => item.value == filterData.recvWorkId
      )[0].label;
      console.log(recvWorkName, "180name");
      let logValue = `转派给${recvWorkName}`;
      let log = `#${moment().format("YYYY-MM-DD HH:mm:ss")} ${
        userInfo.userName
      } ${logValue}`;
      filterData.log = filterData.log + log;
    }
    if (filterData.status == "done") {
      let logValue = `标记为"已完成"`;
      console.log(logValue, "191log");
      let log = `#${moment().format("YYYY-MM-DD HH:mm:ss")} ${
        userInfo.userName
      } ${logValue}`;
      filterData.log = filterData.log + log;
    }
    if (userInfo.projectId) {
      filterData.projectId = userInfo.projectId;
    }
    console.log(filterData, "197data");
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      Axios(
        {
          url: "/server/admin/ticket/updateTicket",
          method: "post",
          data: filterData,
          type: "json",
        },
        true
      ).then((result) => {
        if (result.success) {
          message.success("修改成功");
          self.props.fetchOrgData();
          const { modal } = this.state;
          modal.visible = false;
          self.setState({ modal });
        }
      });
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log(values);
      }
    });
  };

  getCarList = (data) => {
    const self = this;
    Axios(
      {
        url: "/server/admin/car/getCarTypeList",
        method: "get",
        data: data,
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        self.setState({ carList: result.datas });
      }
    });
  };
  getNameMap = () => {
    const self = this;
    const { userInfo } = this.state;
    Axios(
      {
        // url: "/server/admin/worker/getUserList",
        url: "/server/admin/ticket/getNameMap",
        data: {
          page: 1,
          rows: 200,
          params: JSON.stringify({ projectId: userInfo.projectId }),
        },
        method: "get",
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        console.log(result, "---------------result");
        self.sendWorkerMap = result.data.map((item) => {
          return { label: item.classifierName, value: item.id };
        });
        self.recvWorkMap = result.data.map((item) => {
          return { label: item.classifierName, value: item.id };
        });
        self.setState({
          sendWorkerMap: self.sendWorkerMap,
          recvWorkMap: self.recvWorkMap,
        });
      }
    });
  };
  componentDidMount() {
    this.getUploadToken();
    this.getNameMap();
    this.getProjectNameMap();
  }

  render() {
    const self = this;
    const { filterData, modal, areaList, token, carList, userInfo } =
      this.state;
    const { match } = this.props;
    const { getFieldDecorator } = this.props.form;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">上传图片</div>
      </div>
    );
    return (
      <Modal
        onCancel={this.handleCancel}
        visible={modal.visible}
        width={modal.width}
        title={modal.title}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={this.handleOk}>
            确定
          </Button>,
        ]}
      >
        <div>
          <Form
            name="nest-messages"
            style={{ maxWidth: "1000px" }}
            {...layout}
            onSubmit={this.handleSubmit}
          >
            <Row>
              <Col span={24}>
                <Form.Item label="发起人">
                  {getFieldDecorator("sendWorkerId", {
                    rules: [{ required: true, message: "请选择发起人!" }],
                    initialValue: filterData.sendWorkerId,
                  })(
                    <Select
                      onChange={(val) => {
                        const { filterData } = this.state;
                        filterData.sendWorkerId = val;
                        this.setState({ filterData });
                      }}
                      onSearch={(value) => {
                        self.fetchData(
                          "/server/admin/ticket/getNameMap?classifierName=" +
                            value,
                          "sendWorkerMap"
                        );
                      }}
                      onSelect={() => {
                        self.setState({ sendWorkerMap: self.sendWorkerMap });
                      }}
                      placeholder="发起人"
                      style={{ width: 120 }}
                      allowClear
                      showSearch
                      filterOption={false}
                    >
                      {this.state.sendWorkerMap.map((item) => (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label="接收人">
                  {getFieldDecorator("recvWorkId", {
                    rules: [{ required: true, message: "请选择接收人!" }],
                    initialValue: filterData.recvWorkId,
                  })(
                    <Select
                      onChange={(val) => {
                        const { filterData } = this.state;
                        filterData.recvWorkId = val;
                        this.setState({ filterData });
                      }}
                      onSearch={(value) => {
                        self.fetchData(
                          "/server/admin/ticket/getNameMap?classifierName=" +
                            value,
                          "recvWorkMap"
                        );
                      }}
                      onSelect={() => {
                        self.setState({ recvWorkMap: self.recvWorkMap });
                      }}
                      placeholder="接收人"
                      style={{ width: 120 }}
                      allowClear
                      showSearch
                      filterOption={false}
                    >
                      {this.state.recvWorkMap.map((item) => (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label="项目">
                  {getFieldDecorator("projectId", {
                    rules: [{ required: true, message: "请选择项目!" }],
                    initialValue:
                      userInfo.projectId != null
                        ? userInfo.projectId
                        : filterData.projectId,
                  })(
                    <Select
                      disabled={userInfo.projectId != null ? true : false}
                      onChange={(val) => {
                        const { filterData } = this.state;
                        filterData.projectId = val;
                        this.setState({ filterData });
                      }}
                      onSearch={(value) => {
                        self.fetchPorjectData(
                          "/server/admin/project/getProjects?projectName=" +
                            value
                        );
                      }}
                      onSelect={() => {
                        self.setState({ projectNameMap: self.projectNameMap });
                      }}
                      placeholder="项目"
                      style={{ width: 120 }}
                      allowClear
                      showSearch
                      filterOption={false}
                    >
                      {this.state.projectNameMap.map((item) => (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item name={["classifier", "content"]} label="任务内容">
                  {getFieldDecorator("content", {
                    rules: [{ required: true, message: "请输入任务内容!" }],
                    initialValue: filterData.content,
                  })(
                    <Input
                      placeholder="任务内容"
                      style={{ width: 300 }}
                      onChange={(e) => {
                        const { filterData } = this.state;
                        filterData.content = e.target.value;
                        this.setState({ filterData });
                      }}
                    />
                  )}
                </Form.Item>
                {/* <Form.Item name={["classifier", "log"]} label="工单日志">
                  {getFieldDecorator("log", {
                    rules: [{ required: true, message: "请输入工单日志!" }],
                    initialValue: filterData.log,
                  })(
                    <Input
                      placeholder="工单日志"
                      style={{ width: 300 }}
                      onChange={(e) => {
                        const { filterData } = this.state;
                        filterData.log = e.target.value;
                        this.setState({ filterData });
                      }}
                    />
                  )}
                </Form.Item> */}
                <Form.Item name={["classifier", "type"]} label="工单类型">
                  {getFieldDecorator("type", {
                    rules: [{ required: true, message: "请选择工单类型!" }],
                    initialValue: filterData.type,
                  })(
                    <Select
                      onChange={(val) => {
                        const { filterData } = this.state;
                        filterData.type = val.trim();
                        this.setState({ filterData });
                      }}
                      placeholder="全部"
                      defaultValue="全部"
                      style={{ width: 120 }}
                    >
                      <Option key=" " value={" "}>
                        全部
                      </Option>
                      <Option key={"clean"} value={"clean"}>
                        清扫区域
                      </Option>
                      <Option key={"ship"} value={"ship"}>
                        安排清运车辆
                      </Option>
                    </Select>
                  )}
                </Form.Item>
                <Form.Item name={["classifier", "status"]} label="工单状态">
                  {getFieldDecorator("status", {
                    rules: [{ required: true, message: "请选择工单状态!" }],
                    initialValue: filterData.status,
                  })(
                    <Select
                      onChange={(val) => {
                        const { filterData } = this.state;
                        filterData.status = val.trim();
                        this.setState({ filterData });
                      }}
                      placeholder="全部"
                      defaultValue="全部"
                      style={{ width: 120 }}
                    >
                      <Option key=" " value={" "}>
                        全部
                      </Option>
                      <Option key={"wait"} value={"wait"}>
                        待接单
                      </Option>
                      <Option key={"accept"} value={"accept"}>
                        已接单
                      </Option>
                      <Option key={"doing"} value={"doing"}>
                        开始处理
                      </Option>
                      <Option key={"done"} value={"done"}>
                        完成
                      </Option>
                      <Option key={"error"} value={"error"}>
                        无法完成
                      </Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    );
  }
}
export default Form.create({ name: "submit" })(UpdateTickets);
