import React from "react";
import {
  Row,
  Col,
  Form,
  Icon,
  Input,
  Button,
  Table,
  Tag,
  Select,
  DatePicker,
  Breadcrumb,
  Radio,
  message,
} from "antd";

import Axios from "../../util/request";
import moment from "moment";
import UpdateUsers from "./components/UpdateUsers";
import debounce from "lodash/debounce";
import Zmage from "react-zmage";
import { orgServices, recyclerServices } from "../../util/publicData";

const { Option } = Select;

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData: {
        isVolunteer: 4,
      }, //检索数据
      dataSource: {}, //列表数据
      provinceList: [],
      pageNum: 1,
      tableLoading: true,
    };
    this.pageNum = 1;
    this.fetchOrgData = debounce(this.fetchOrgData, 800);
  }

  // 获取table数据
  fetchOrgData = () => {
    const self = this;
    const { filterData, timeString } = this.state;
    const page = this.pageNum || 1;
    this.setState({ tableLoading: true });
    if (!filterData.state) {
      delete filterData.state;
    }

    const params = JSON.stringify(filterData);
    // 拉取预约数据
    Axios(
      {
        url: "/server/admin/worker/getUserList",
        data: { page, rows: 20, params },
        method: "get",
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        self.setState({ dataSource: result, tableLoading: false });
      }
    });
  };

  componentDidMount() {
    const self = this;
    const { filterData } = this.state;
    const { search } = this.props.location;
    const paramsString = search.substring(1);
    const searchParams = new URLSearchParams(paramsString);
    const sortersName = searchParams.get("sortersName");

    if (filterData) {
      filterData.sortersName = sortersName;
    }
    this.setState({ filterData }, function () {
      self.fetchOrgData();
    });
  }

  render() {
    const self = this;
    const { filterData, tableLoading } = this.state;
    const { match } = this.props;
    const dataColumns = [
      {
        title: "编号",
        dataIndex: "id",
        key: "id",
        render: (cur, record, index) => {
          return (self.pageNum - 1) * 20 + index + 1;
        },
      },
      {
        title: "姓名",
        dataIndex: "userName",
        key: "userName",
      },
      {
        title: "头像",
        dataIndex: "userHeadImg",
        key: "userHeadImg",
        render: (cur, record) => {
          return (
            <div style={{ textAlign: "center" }}>
              <Zmage
                edge={20}
                style={{ width: "60px", height: "60px", borderRadius: "50%" }}
                src={cur}
                set={cur}
                defaultPage={0}
              />
            </div>
          );
        },
      },
      {
        title: "手机号",
        dataIndex: "mobile",
        key: "mobile",
      },
      {
        title: "openId",
        dataIndex: "openId",
        key: "openId",
      },
      {
        title: "状态",
        dataIndex: "state",
        key: "state",
        render: (cur, record) => {
          return (
            <div>
              {cur == 1 ? (
                <Tag color="#87d068">通过</Tag>
              ) : (
                <Tag color="#f50">不通过</Tag>
              )}
            </div>
          );
        },
      },
      {
        title: "创建时间",
        dataIndex: "ctime",
        key: "ctime",
        render: (qrcode, record) => {
          return <div>{qrcode && moment(qrcode).format("YYYY-MM-DD")} </div>;
        },
      },
      {
        title: "更新时间",
        dataIndex: "mtime",
        key: "mtime",
        render: (qrcode, record) => {
          return <div>{qrcode && moment(qrcode).format("YYYY-MM-DD")} </div>;
        },
      },
      {
        title: "操作",
        render: (qrcode, record) => {
          return (
            <div className="optbtn">
              <Button
                type="primary"
                icon="eye"
                title="查看"
                onClick={() =>
                  this.props.history.push(
                    "/admin/worker/userDetail/" + record.id
                  )
                }
              ></Button>
              <Button
                type="primary"
                icon="edit"
                title="编辑"
                onClick={() => this.UpdateUsers.showDetail(record)}
              ></Button>
            </div>
          );
        },
      },
    ];
    return (
      <div style={{ flex: 1 }}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Icon type="home" /> 首页
          </Breadcrumb.Item>
          <Breadcrumb.Item>用户管理</Breadcrumb.Item>
        </Breadcrumb>
        <div className="mt-20">
          <Form layout="inline" style={{ flex: 1 }}>
            <Form.Item label="姓名">
              <Input
                placeholder="姓名"
                allowClear
                onChange={(e) => {
                  const { filterData } = this.state;
                  filterData.userName = e.target.value;
                  this.setState({ filterData });
                }}
                value={filterData.userName}
                style={{ width: 200 }}
              ></Input>
            </Form.Item>
            <Form.Item label="用户电话">
              <Input
                placeholder="用户电话"
                allowClear
                onChange={(e) => {
                  const { filterData } = this.state;
                  filterData.mobile = e.target.value;
                  this.setState({ filterData });
                }}
                style={{ width: 200 }}
              ></Input>
            </Form.Item>
            <Form.Item label="状态">
              <Select
                onChange={(val) => {
                  const { filterData } = this.state;
                  filterData.state = val.trim();
                  this.setState({ filterData });
                }}
                placeholder="全部"
                defaultValue="全部"
                style={{ width: 120 }}
              >
                <Option key=" " value={" "}>
                  全部
                </Option>
                <Option key={1} value={"1"}>
                  正常
                </Option>
                <Option key={0} value={"0"}>
                  禁用
                </Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                onClick={() => {
                  this.pageNum = 1;
                  this.fetchOrgData();
                }}
                type="primary"
              >
                搜索
              </Button>
            </Form.Item>
          </Form>
          <br />
          <div style={{ textAlign: "right" }}>
            <Form.Item>
              <Button
                onClick={() => {
                  this.UpdateUsers.showDetail();
                }}
                type="primary"
              >
                新增用户
              </Button>
            </Form.Item>
          </div>
          <div className="card-container" style={{ marginTop: 20 }}>
            <Table
              loading={tableLoading}
              scroll={{ x: 1300 }}
              dataSource={this.state.dataSource.datas}
              pagination={{
                total: this.state.dataSource.total || 0,
                showTotal: (total) =>
                  `共 ${this.state.dataSource.total || 0} 条数据`,
                pageSize: 20,
                current: this.pageNum,
                onChange: (page) => {
                  this.pageNum = page;
                  this.fetchOrgData();
                },
              }}
              columns={dataColumns}
            />
          </div>
        </div>
        <UpdateUsers
          fetchOrgData={this.fetchOrgData}
          onRef={(c) => (this.UpdateUsers = c)}
        ></UpdateUsers>
      </div>
    );
  }
}

export default UserList;
