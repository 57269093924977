import React from "react";
import {
  Row,
  Col,
  Breadcrumb,
  message,
  Upload,
  Divider,
  InputNumber,
} from "antd";
import { Form, Icon, Input, Button, Select } from "antd";

import debounce from "lodash/debounce";
import StreetSelect from "../../components/select/StreetSelect";
import QRCode from "qrcode.react";
import { Link, Route } from "react-router-dom";
import Axios from "../../util/request";

import moment from "moment";
import { result } from "lodash";

const { Option } = Select;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function getQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return decodeURI(r[2]);
  return null;
}

class AddDrop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData: {},
      edifilterData: {}, //编辑后用户信息
      selectData: [], //下拉数据
      id: "",
      imageUrl: "",
      token: null,
      addDrop: {},
      locationData: {},
      projectNameMap: [],
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
    };
    this.projectNameMap = [];
    this.fetchPorjectData = debounce(this.fetchPorjectData, 800);
  }
  fetchPorjectData = (url, type) => {
    const self = this;
    //筛选下拉查询
    Axios({ url, method: "get" }, true).then((result) => {
      if (result.success) {
        let projectNameMap = [];
        projectNameMap = result.data.map((item) => {
          return { label: item.name, value: item.id };
        });
        self.setState({ projectNameMap });
      }
    });
  };
  getProjectNameMap = () => {
    const self = this;
    Axios(
      {
        // url: "/server/admin/worker/getUserList",
        url: "/server/admin/project/getProjects",
        data: { page: 1, rows: 200, params: JSON.stringify({}) },
        method: "get",
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        self.projectNameMap = result.data.map((item) => {
          return { label: item.name, value: item.id };
        });
        self.setState({
          projectNameMap: self.projectNameMap,
        });
      }
    });
  };

  getUploadToken = (values) => {
    //提交新增用户信息方法
    const self = this;
    Axios(
      {
        url: "/server/admin/qiniu/getUploadToken",
        method: "get",
        data: values,
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        self.setState({ token: result.uploadToken });
      }
    });
  };

  validateLatLng = (rule, value, callback) => {
    const latLngRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/;
    if (!latLngRegex.test(value)) {
      callback("无效的经纬度格式");
    } else {
      callback();
    }
  };
  checkAccount(rule, value, callback) {
    //手机号验证
    var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
    if (!value || myreg.test(value)) {
      callback();
    } else {
      callback("请输入正确的联系电话!");
    }
  }

  handleSubmit = (e) => {
    // 新增点位;
    let _this = this;
    let addDrop = {};
    const { userInfo } = this.state;
    this.props.form.validateFieldsAndScroll((err, values) => {
      const dropLocation = values.dropLocation.split(",").join("");
      addDrop = {
        dropName: values.dropName,
        type: values.type,
        dropLocation: dropLocation,
        dropLat: values.dropLat,
        dropLng: values.dropLng,
        dropAddress: values.dropAddress,
        areaCode: values.areaCode,
        radius: values.radius,
      };
    });
    if (userInfo.projectId) {
      addDrop.projectId = userInfo.projectId;
    }
    _this.setState({
      addDrop: addDrop,
    });
    Axios(
      {
        url: "/server/admin/drop/addDropInfo",
        data: { ...addDrop },
        method: "post",
        type: "json",
      },
      true
    ).then((result) => {
      const { type } = addDrop;
      let path = "";
      switch (type) {
        case "1":
          path = "transferStn";
          break;
        case "2":
          path = "garbageFty";
          break;
        case "3":
          path = "barrelStn";
          break;
        default:
          break;
      }
      if (result.message == "添加成功") {
        message.success("新增点位成功");
        this.props.history.push(`/admin/drop/${path}`);
      } else {
        message.error("新增点位失败");
      }
    });
    e.preventDefault();
  };
  ddoiiahslihs() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude; //纬度
        const longitude = position.coords.longitude; //经度
        let locationData = { latitude: latitude, longitude: longitude };
        this.setState({ locationData: locationData });
      });
    }
  }
  componentDidMount() {
    this.ddoiiahslihs();
    this.getUploadToken();
    this.getProjectNameMap();
    const self = this;
  }

  handleChange = (info) => {
    if (info.file.status == "done") {
      this.setState({
        imageUrl: "https://static.aifenlei.com.cn/" + info.file.response.key,
        loading: false,
      });
    }
  };

  render() {
    const self = this;
    const {
      filterData,
      edifilterData,
      id,
      imageUrl,
      token,
      locationData,
      userInfo,
    } = this.state;
    const { match } = this.props;
    const { getFieldDecorator } = this.props.form;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">上传头像 </div>
      </div>
    );
    return (
      <div style={{ flex: 1 }}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Icon type="home" /> 首页
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/admin/org/orgList">点位管理</Breadcrumb.Item>
          <Breadcrumb.Item>新增点位</Breadcrumb.Item>
        </Breadcrumb>
        <div style={{ marginTop: "20px" }}>
          <Form
            name="nest-messages"
            style={{ maxWidth: "600px" }}
            {...layout}
            onSubmit={this.handleSubmit}
          >
            <Form.Item name={["user", "dropName"]} label="点位名称">
              {getFieldDecorator("dropName", {
                rules: [{ required: true, message: "请输入点位名称!" }],
                initialValue: filterData.orgName,
              })(<Input placeholder="请输入点位名称" />)}
            </Form.Item>
            <Form.Item name={["user", "type"]} label="类型">
              {getFieldDecorator("type", {
                rules: [{ required: true, message: "请选择类型" }],
              })(
                <Select
                  onChange={(val) => {
                    const { filterData } = this.state;
                    filterData.state = val;
                    this.setState({ filterData });
                  }}
                  style={{ width: 400 }}
                  placeholder="请选择"
                >
                  <Option key={0} value={"1"}>
                    中转站
                  </Option>
                  <Option key={1} value={"2"}>
                    垃圾焚烧厂
                  </Option>
                  <Option key={2} value={"3"}>
                    桶站
                  </Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item name={["user", "dropLocation"]} label="经纬度">
              {getFieldDecorator("dropLocation", {
                initialValue: filterData.orgName,
                rules: [
                  {
                    required: true,
                    message: "请输入经纬度",
                    // validator: this.validateLatLng
                  },
                ],
              })(<Input placeholder="请输入经纬度" />)}
            </Form.Item>
            {/* <Form.Item name={['user', 'dropLat']} label="维度">
                            {getFieldDecorator('dropLat', {
                                initialValue: locationData.latitude,
                            })(<Input placeholder="请输入维度" disabled={true} />)}
                        </Form.Item>
                        <Form.Item name={['user', 'dropLng']} label="经度">
                            {getFieldDecorator('dropLng', {
                                initialValue: locationData.longitude,
                            })(<Input placeholder="请输入经度" disabled={true} />)}
                        </Form.Item> */}
            <Form.Item name={["user", "dropAddress"]} label="地址">
              {getFieldDecorator("dropAddress", {
                initialValue: filterData.orgContactTel,
              })(<Input placeholder="请输入地址" />)}
            </Form.Item>
            <Form.Item name={["user", "areaCode"]} label="街道">
              {getFieldDecorator("areaCode", {
                initialValue: filterData.orgContactTel,
              })(
                <StreetSelect
                  style={{ width: 400 }}
                  value={filterData.areaCode}
                  onChange={(val) => {
                    const { filterData } = this.state;
                    filterData.areaCode = val;
                    this.setState({ filterData });
                  }}
                ></StreetSelect>
              )}
            </Form.Item>
            <Form.Item name={["user", "radius"]} label="半径(m)">
              {getFieldDecorator("radius", {
                initialValue: filterData.orgContactTel,
              })(
                <InputNumber
                  min={0}
                  step={0.01}
                  placeholder="请输入半径(m)"
                  style={{ width: 400 }}
                />
              )}
            </Form.Item>
            <Form.Item label="项目">
              {getFieldDecorator("projectId", {
                rules: [{ required: true, message: "请选择项目!" }],
                initialValue:
                  userInfo.projectId != null
                    ? userInfo.projectId
                    : filterData.projectId,
              })(
                <Select
                  disabled={userInfo.projectId != null ? true : false}
                  onChange={(val) => {
                    const { filterData } = this.state;
                    filterData.projectId = val;
                    this.setState({ filterData });
                  }}
                  onSearch={(value) => {
                    self.fetchPorjectData(
                      "/server/admin/project/getProjects?projectName=" + value
                    );
                  }}
                  onSelect={() => {
                    self.setState({ projectNameMap: self.projectNameMap });
                  }}
                  placeholder="项目"
                  style={{ width: 120 }}
                  allowClear
                  showSearch
                  filterOption={false}
                >
                  {this.state.projectNameMap.map((item) => (
                    <Option key={item.value} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Divider dashed={true} />
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}
/**
 * 
 * 
 * 
 * 
 * <InputNumber onChange={value => {
                                        const {filterData} = this.state;
                                        filterData.weight = value;
                                        this.setState({filterData});
                                    }} style={{width:"100%"}} min={0} step={0.01} placeholder="车辆重量/kg" />
 */
const RestaurantForm = Form.create({ name: "submit" })(AddDrop);

export default RestaurantForm;
