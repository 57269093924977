import React from "react";

import { Menu, Icon, Tabs, Col, Button } from "antd";
import { BrowserRouter as Router, Route, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../../images/icon/head_logo.png";

import "./style.scss";
// import DropList from "./DropList";
// import AddDrop from "./AddDrop";

const { SubMenu } = Menu;

class Drop extends React.Component {
  rootSubmenuKeys = ["sub1", "sub2", "sub4"];

  constructor(props) {
    super(props);
    this.state = {
      openKeys: ["sub1"],
      selectedKeys: "", //默认路由
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
    };
  }

  handleSetSelectedKeys(pathname) {
    //获取路由最后一个地址名称
    // /admin = ["/","admin"]
    // 根据'/'把路由地址分割成一个数组
    const temp = pathname.split("/");
    // 如果数组的长度小于2,表示的是只有根路径/,设置为Home. 否则取数组中第二个值
    const key = temp[temp.length - 2] + "/" + temp[temp.length - 1];

    this.setState({
      selectedKeys: key,
      openKeys: [temp[temp.length - 2]],
    });
  }

  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({
        openKeys: openKeys.length > 0 ? [openKeys[openKeys.length - 1]] : [],
      });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  componentWillMount() {
    this.handleSetSelectedKeys(this.props.history.location.pathname);
    // this.props.history.push('/admin/UserManagement');
  }

  render() {
    const { userInfo } = this.state;
    const { match } = this.props;
    const { selectedKeys } = this.state.selectedKeys;

    return (
      <div className={"menu-left"}>
        <div className="menu-head">
          <a id="logo" href="/admin/home">
            <img src={logo} />
          </a>
        </div>
        <Menu
          className={"munu-list"}
          onClick={this.handleClick}
          style={{ width: 240, height: "100%", overflow: "auto" }}
          defaultSelectedKeys={this.state.selectedKeys}
          openKeys={this.state.openKeys}
          onOpenChange={this.onOpenChange}
          mode="inline"
          theme="dark"
        >
          {userInfo.id == 1 ? (
            <SubMenu
              key="adminService"
              title={
                <span>
                  <Icon type="environment" />
                  <span>管理员管理</span>
                </span>
              }
            >
              <Menu.ItemGroup key="g1" title="">
                <Menu.Item key="adminService/adminList">
                  <Link to={`/admin/adminService/adminList`}>管理员列表</Link>
                </Menu.Item>
              </Menu.ItemGroup>
            </SubMenu>
          ) : (
            ""
          )}

          <SubMenu
            key="statistics"
            title={
              <span>
                <Icon type="user" />
                <span>统计数据</span>
              </span>
            }
          >
            <Menu.ItemGroup key="g1" title="">
              <Menu.Item key="home">
                <Link to={`/admin/home`}>首页 统计信息</Link>
              </Menu.Item>
              {/* <Menu.Item key="statistics/userManagement">
                <Link to={`/admin/statistics/userManagement`}>综合</Link>
              </Menu.Item>
              <Menu.Item key="statistics/userManagement">
                <Link to={`/admin/statistics/userManagement`}>日报</Link>
              </Menu.Item>
              <Menu.Item key="statistics/userManagement">
                <Link to={`/admin/statistics/userManagement`}>月报</Link>
              </Menu.Item>
              <Menu.Item key="statistics/userManagement">
                <Link to={`/admin/statistics/userManagement`}>年报</Link>
              </Menu.Item> */}
            </Menu.ItemGroup>
          </SubMenu>
          <SubMenu
            key="worker"
            title={
              <span>
                <Icon type="user" />
                <span>服务人员管理</span>
              </span>
            }
          >
            <Menu.ItemGroup key="g1" title="">
              <Menu.Item key="worker/groupList">
                <Link to={`/admin/worker/groupList`}>服务组</Link>
              </Menu.Item>
              {/* <Menu.Item key="worker/classifierList">
                <Link to={`/admin/worker/classifierList`}>回收员管理</Link>
              </Menu.Item>
              <Menu.Item key="worker/commissionerList">
                <Link to={`/admin/worker/commissionerList`}>项目专员管理</Link>
              </Menu.Item> */}
              <Menu.Item key="worker/driverList">
                <Link to={`/admin/worker/driverList`}>服务人员</Link>
              </Menu.Item>
              {/* <Menu.Item key="worker/sanitationList">
                <Link to={`/admin/worker/sanitationList`}>环卫工人</Link>
              </Menu.Item>
              <Menu.Item key="worker/publicizeList">
                <Link to={`/admin/worker/publicizeList`}>宣传员</Link>
              </Menu.Item>
              <Menu.Item key="worker/volunteerList">
                <Link to={`/admin/worker/volunteerList`}>志愿者</Link>
              </Menu.Item> */}
              {userInfo.projectId ? (
                ""
              ) : (
                <Menu.Item key="worker/userList">
                  <Link to={`/admin/worker/userList`}>用户</Link>
                </Menu.Item>
              )}
            </Menu.ItemGroup>
          </SubMenu>
          <SubMenu
            key="work"
            title={
              <span>
                <Icon type="user" />
                <span>作业管理</span>
              </span>
            }
          >
            <Menu.ItemGroup key="g1" title="">
              <Menu.Item key="work/clockList">
                <Link to={`/admin/work/clockList`}>服务人员 打卡记录</Link>
              </Menu.Item>
              <Menu.Item key="work/userManagement">
                <Link to={`/admin/work/userManagement`}>厨余清运 记录</Link>
              </Menu.Item>
              <Menu.Item key="work/userManagement">
                <Link to={`/admin/work/userManagement`}>其他垃圾清运 记录</Link>
              </Menu.Item>
              <Menu.Item key="work/userManagement">
                <Link to={`/admin/work/userManagement`}>中转作业 记录</Link>
              </Menu.Item>
              <Menu.Item key="work/userManagement">
                <Link to={`/admin/work/userManagement`}>环卫工人 作业记录</Link>
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
          <SubMenu
            key="ticket"
            title={
              <span>
                <Icon type="user" />
                <span>工单管理</span>
              </span>
            }
          >
            <Menu.ItemGroup key="g1" title="">
              <Menu.Item key="ticket/ticketList">
                <Link to={`/admin/ticket/ticketList`}>工单列表</Link>
              </Menu.Item>
              <Menu.Item key="ticket/addTicket">
                <Link to={`/admin/ticket/addTicket`}>新增工单</Link>
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
          <SubMenu
            key="car"
            title={
              <span>
                <Icon type="user" />
                <span>车辆管理</span>
              </span>
            }
          >
            {/*<Menu.ItemGroup key="g1" title="车辆列表">*/}
            <Menu.ItemGroup key="g1" title="">
              <Menu.Item key="car/carList">
                <Link to={`/admin/car/carList`}>车辆列表</Link>
              </Menu.Item>
              <Menu.Item key="car/gpsStatList">
                <Link to={`/admin/car/gpsStatList`}>行驶里程</Link>
              </Menu.Item>
              <Menu.Item key="car/gpsList">
                <Link to={`/admin/car/gpsList`}>GPS设备管理</Link>
              </Menu.Item>
              <Menu.Item key="user/car">
                {/* <Link to={`/admin/car/userManagement`}>车辆轨迹</Link> */}
                {userInfo.projectId ? (
                  <a
                    href={`https://hw.aifenlei.com.cn/data/index.html?projectId=${userInfo.projectId}`}
                    target="_blank"
                  >
                    车辆轨迹
                  </a>
                ) : (
                  <a
                    href="https://hw.aifenlei.com.cn/data/index.html"
                    target="_blank"
                  >
                    车辆轨迹
                  </a>
                )}
              </Menu.Item>
              <Menu.Item key="car/gpsLogList">
                <Link to={`/admin/car/gpsLogList`}>轨迹列表</Link>
              </Menu.Item>
              {/* <Menu.Item key="user/car1">
                            <Link to={`/admin/car/userManagement`}>车辆保险、年检</Link>
                        </Menu.Item>
                        <Menu.Item key="user/car1">
                            <Link to={`/admin/car/userManagement`}>车辆 GPS 状态</Link>
                        </Menu.Item> */}
            </Menu.ItemGroup>
            {/*<Menu.ItemGroup key="g2" title="">*/}
            {/*  <Menu.Item key="user/car">*/}
            {/*    <Link to={`/admin/car/userManagement`}>新增车辆</Link>*/}
            {/*  </Menu.Item>*/}
            {/*</Menu.ItemGroup>*/}
          </SubMenu>
          <SubMenu
            key="drop"
            title={
              <span>
                <Icon type="user" />
                <span>点位管理</span>
              </span>
            }
          >
            <Menu.ItemGroup key="g1" title="点位列表">
              <Menu.Item key="drop/transferStn">
                <Link to={`/admin/drop/transferStn`}>中转站</Link>
              </Menu.Item>
              <Menu.Item key="drop/garbageFty">
                <Link to={`/admin/drop/garbageFty`}>垃圾焚烧厂</Link>
              </Menu.Item>
              <Menu.Item key="drop/barrelStn">
                <Link to={`/admin/drop/barrelStn`}>桶站</Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="g2" title="">
              <Menu.Item key="drop/addDrop">
                <Link to={`/admin/drop/addDrop`}>新增点位</Link>
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
          <SubMenu
            key="contract"
            title={
              <span>
                <Icon type="user" />
                <span>合同管理</span>
              </span>
            }
          >
            <Menu.ItemGroup key="g1" title="">
              <Menu.Item key="user/userManagement">
                <Link to={`/admin/user/userManagement`}>签约主体列表</Link>
              </Menu.Item>
              <Menu.Item key="user/userManagement">
                <Link to={`/admin/user/userManagement`}>合同列表</Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="g1" title="收费列表">
              <Menu.Item key="user/userManagement">
                <Link to={`/admin/user/userManagement`}>收费统计</Link>
              </Menu.Item>
              <Menu.Item key="user/userManagement">
                <Link to={`/admin/user/userManagement`}>收费详情</Link>
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
          <SubMenu
            key="data"
            title={
              <span>
                <Icon type="user" />
                <span>基础数据管理</span>
              </span>
            }
          >
            <Menu.ItemGroup key="g1" title="">
              <Menu.Item key="user/userManagement">
                <Link to={`/admin/user/userManagement`}>街道列表</Link>
              </Menu.Item>
              <Menu.Item key="user/userManagement">
                <Link to={`/admin/user/userManagement`}>社区列表</Link>
              </Menu.Item>
              <Menu.Item key="user/userManagement">
                <Link to={`/admin/user/userManagement`}>道路列表</Link>
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
        </Menu>
      </div>
    );
  }
}

export default withRouter(Drop);
