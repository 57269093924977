import React from 'react';
import {Row, Col, Form, Icon, Input, Button, Table, Tag, Select, DatePicker, Breadcrumb, Modal, message} from 'antd';

import Axios from "../../util/request";

import UpdataGroup from "./components/UpdataTeamInfoModal";
import UpdataTeamInfoModal from "./components/UpdataTeamInfoModal";

import moment from "moment";
import QRCode from "qrcode.react";

const {Option} = Select;
const {confirm} = Modal;

class TeamList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filterData:{},//检索数据
      dataSource:{},//列表数据
      pageNum: 1,
      tableLoading: true,
    };
  };

  // 获取table数据
  fetchOrgData = () =>{
    const self = this;
    const {filterData, timeString} = this.state;
    const page = this.pageNum || 1;
    this.setState({tableLoading:true});
    const params = JSON.stringify({
      "teamName": filterData.teamName || '',
      // "communityCode": filterData.communityCode || '',
      // "communityType": filterData.communityType || '',
    })
    // 拉取预约数据
    Axios({
      url: '/server/admin/team/getData',
      data: {page, "rows": 20, params},
      method: 'get',
      type: 'json'
    }, true).then(result => {
      if (result.sucess) {
        self.setState({dataSource: result, tableLoading:false});
      }
    });

  };

  deleteConfirm = (record) => {
    const self = this;
    confirm({
      title: '确认是否删除服务组?',
      content: <div>
        <Row>
          <Col>服务组名称 :{record.teamName}</Col>
        </Row>
      </div>,
      okText: '确认',
      cancelText: '取消',
      onOk() {
        Axios({
          url: '/server/admin/team/deleteTeam',
          data: {id:record.id},
          method: 'get',
          type: 'json'
        }, true).then(result => {
          if (result.sucess) {
            message.success('删除成功');
            self.fetchOrgData();
          }
        });
      },
      onCancel() {
      },
    });
  };

  componentDidMount(){
    const self = this;
    this.fetchOrgData();
  }

  render() {
    const { filterData, dataSource, tableLoading} = this.state;
    const {match} = this.props;
    const dataColumns = [
      {
        title: '编号',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: '服务组名称',
        dataIndex: 'teamName',
        key: 'teamName',
      },
      {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        render: (cur,record) => {
          return <div>{cur == 1 ? <Tag color="#87d068">通过</Tag> : <Tag color="#f50">不通过</Tag>}</div>
        }
      },
      {
        title: '创建时间',
        dataIndex: 'createTime',
        key: 'createTime',
        render: (qrcode, record) => {
          return <div>{qrcode && moment(qrcode).format("YYYY-MM-DD")} </div>;
        }
      },
      {
        title: '操作',
        render: (qrcode, record) => {
          return <div className='optbtn'>
            <Button type="primary" icon="eye" title="查看" onClick={()=>(
              this.props.history.push("/admin/worker/groupDetail/"+record.id)
            )}></Button>
            <Button type="primary" icon="edit" title="编辑" onClick={() => (
              this.UpdataTeamInfoModal.showDetail(record)
            )}></Button>
          </div>;
        }
      },
    ];
    return (
      <div style={{ flex: 1}}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item><Icon type="home" /> 首页</Breadcrumb.Item>
          <Breadcrumb.Item>服务组管理</Breadcrumb.Item>
        </Breadcrumb>
        <div className="mt-20">
          <Form layout="inline" style={{flex: 1}}>
            <Form.Item label="服务组名称">
              <Input
                placeholder="服务组名称"
                allowClear
                onChange={e => {
                  const {filterData} = this.state;
                  filterData.teamName = e.target.value;
                  this.setState({filterData});
                }}
                style={{width: 200}}
              ></Input>
            </Form.Item>
            <Form.Item>
              <Button onClick={() => {
                this.pageNum = 1;
                this.fetchOrgData();
              }} type="primary">
                搜索
              </Button>
            </Form.Item>
          </Form>
          <div style={{textAlign:"right"}}>
            <Form.Item>
              <Button onClick={() => {
                this.UpdataGroup.showDetail();
              }} type="primary">
                新增服务组
              </Button>
            </Form.Item>
          </div>
          <div className="card-container" style={{marginTop: 20}}>
            <Table loading={tableLoading} dataSource={this.state.dataSource.datas} pagination={{total: this.state.dataSource.total || 0,showTotal:total => `共 ${this.state.dataSource.total || 0} 条数据`, pageSize: 20, current: this.pageNum, onChange: page => {
                this.pageNum = page;
                this.fetchOrgData();
              }}} columns={dataColumns}/>
          </div>
        </div>
        <UpdataTeamInfoModal fetchOrgData={this.fetchOrgData} onRef={c=>this.UpdataTeamInfoModal=c}></UpdataTeamInfoModal>
        <UpdataGroup fetchOrgData={this.fetchOrgData} onRef={c=>this.UpdataGroup=c}></UpdataGroup>
      </div>
    )
  }
}

export default TeamList;
