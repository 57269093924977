import React from "react";
import { Form, Icon, Input, Button, Table, DatePicker, Breadcrumb } from "antd";

import Axios from "../../util/request";
import moment from "moment";
import debounce from "lodash/debounce";

class GpsStatList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData: {
        cid: "",
      }, //检索数据
      dataSource: {}, //列表数据
      provinceList: [],
      pageNum: 1,
      tableLoading: true,
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
    };
    this.pageNum = 1;
    this.fetchOrgData = debounce(this.fetchOrgData, 800);
  }

  // 获取table数据
  fetchOrgData = () => {
    const self = this;
    const { filterData, timeString, userInfo } = this.state;
    const page = this.pageNum || 1;
    this.setState({ tableLoading: true });
    if (!filterData.state) {
      delete filterData.state;
    }
    if (userInfo.projectId) {
      filterData.projectId = userInfo.projectId;
    }
    const params = JSON.stringify(filterData);
    console.log(params, "------------");
    // 拉取预约数据
    Axios(
      {
        url: "/server/admin/car/getGpsStatList",
        data: { params, page, rows: 20 },
        method: "get",
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        self.setState({ dataSource: result, tableLoading: false });
      }
    });
  };

  componentDidMount() {
    const self = this;
    const { filterData } = this.state;
    this.setState({ filterData }, function () {
      self.fetchOrgData();
    });
  }

  render() {
    const { filterData, tableLoading } = this.state;
    const { match } = this.props;
    const self = this;
    const dataColumns = [
      {
        title: "编号",
        dataIndex: "id",
        key: "id",
        render: (cur, record, index) => {
          return (self.pageNum - 1) * 20 + index + 1;
        },
      },
      {
        title: "车牌号",
        dataIndex: "carCode",
        key: "carCode",
      },
      {
        title: "GPS设备号",
        dataIndex: "cid",
        key: "cid",
      },
      {
        title: "上报次数",
        dataIndex: "pingTimes",
        key: "pingTimes",
      },
      {
        title: "行驶距离",
        dataIndex: "distanceMi",
        key: "distanceMi",
        render: (qrcode, record) => {
          return (
            <div>
              <a
                href={
                  "https://hw.aifenlei.com.cn/data/index.html?id=" + record.cid
                }
                target="_blank"
              >
                {qrcode > 0 ? (
                  qrcode > 1000 ? (
                    <div>{(qrcode / 1000).toFixed(1)}公里</div>
                  ) : (
                    <div>{qrcode}米</div>
                  )
                ) : (
                  <div>{qrcode}</div>
                )}
              </a>
            </div>
          );

          // return qrcode > 0 ? (
          //   qrcode > 1000 ? (
          //     <div>
          //       {(qrcode / 1000).toFixed(1)}公里
          //     </div>
          //   ) : (
          //     <div>{qrcode}米</div>
          //   )
          // ) : (
          //   <div>{qrcode}</div>
          // );
        },
      },
      {
        title: "最后坐标",
        dataIndex: "lastLatLng",
        key: "lastLatLng",
      },
      {
        title: "第一次移动时间",
        dataIndex: "firstMoveTime",
        key: "firstMoveTime",
        render: (qrcode, record) => {
          return (
            <div>{qrcode && moment(qrcode).format("YYYY-MM-DD HH:mm")} </div>
          );
        },
      },
      {
        title: "最后一次移动时间",
        dataIndex: "lastMoveTime",
        key: "lastMoveTime",
        render: (qrcode, record) => {
          return (
            <div>{qrcode && moment(qrcode).format("YYYY-MM-DD HH:mm")} </div>
          );
        },
      },
    ];
    return (
      <div style={{ flex: 1 }}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Icon type="home" /> 首页
          </Breadcrumb.Item>
          <Breadcrumb.Item>行驶里程</Breadcrumb.Item>
        </Breadcrumb>
        <div className="mt-20">
          <Form layout="inline" style={{ flex: 1 }}>
            <Form.Item label="车牌号">
              <Input
                placeholder="车牌号"
                allowClear
                onChange={(e) => {
                  const { filterData } = this.state;
                  filterData.carCode = e.target.value;
                  this.setState({ filterData });
                }}
                value={filterData.carCode}
                style={{ width: 200 }}
              ></Input>
            </Form.Item>
            <Form.Item label="GPS设备号">
              <Input
                placeholder="GPS设备号"
                allowClear
                onChange={(e) => {
                  const { filterData } = this.state;
                  filterData.cid = e.target.value;
                  this.setState({ filterData });
                }}
                value={filterData.cid}
                style={{ width: 200 }}
              ></Input>
            </Form.Item>
            <Form.Item label="日期">
              {/* <DatePicker
                onChange={(data) => {
                  const { filterData } = this.state;
                  filterData.day = data.format("YYYY-MM-DD");
                  this.setState({ filterData });
                }}
                // value={filterData.day}
                style={{ width: 200 }}
              /> */}
              <DatePicker
                format="YYYY-MM-DD"
                onChange={(date, timeString) => {
                  if (timeString === "" && filterData.day !== undefined) {
                    delete filterData.day;
                  } else {
                    const { filterData } = this.state;
                    filterData.day = timeString;
                    this.setState({ filterData });
                  }
                }}
                style={{ width: 200 }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                onClick={() => {
                  this.pageNum = 1;
                  this.fetchOrgData();
                }}
                type="primary"
              >
                搜索
              </Button>
            </Form.Item>
          </Form>
          <div className="card-container" style={{ marginTop: 20 }}>
            <Table
              loading={tableLoading}
              scroll={{ x: 1300 }}
              dataSource={this.state.dataSource.datas}
              pagination={{
                total: this.state.dataSource.total || 0,
                showTotal: (total) =>
                  `共 ${this.state.dataSource.total || 0} 条数据`,
                pageSize: 20,
                current: this.pageNum,
                onChange: (page) => {
                  this.pageNum = page;
                  this.fetchOrgData();
                },
              }}
              columns={dataColumns}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default GpsStatList;
