import React from 'react';
import {Row, Col, Breadcrumb, message, Typography, InputNumber, Divider, Card, DatePicker} from 'antd';
import {Form, Icon, Input, Button, Select, Modal, Tabs, Radio} from 'antd';
import {Chart, Area, Line, Point, Tooltip, Interval} from 'bizcharts';

import Axios from "../../../util/request";

import moment from "moment";

import style from "../style.module.scss"

const {Option} = Select;
const {TabPane} = Tabs;
const { RangePicker } = DatePicker;
const { Title } = Typography;

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 3, span: 16 },
};

class DataTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      timeString: [],
      resType: 1,
      timeRadio: 2,
      labelShow: true,
    };
    if(props.onRef){//如果父组件传来该方法 则调用方法将子组件this指针传过去
      props.onRef(this)
    }
  };

  timeForMat = (count) => {
// 拼接时间
    let time1 = new Date()
    time1.setTime(time1.getTime() - (24 * 60 * 60 * 1000))
    let Y1 = time1.getFullYear()
    let M1 = ((time1.getMonth() + 1) > 10 ? (time1.getMonth() + 1) : '0' + (time1.getMonth() + 1))
    let D1 = (time1.getDate() > 10 ? time1.getDate() : '0' + time1.getDate())
    let timer1 = Y1 + '-' + M1 + '-' + D1 // 当前时间
    let time2 = new Date()
    time2.setTime(time2.getTime() - (24 * 60 * 60 * 1000 * count))
    let Y2 = time2.getFullYear()
    let M2 = ((time2.getMonth() + 1) > 9 ? (time2.getMonth() + 1) : '0' + (time2.getMonth() + 1))
    let D2 = (time2.getDate() > 9 ? time2.getDate() : '0' + time2.getDate())
    // let timer2 = Y2 + '-' + M2 + '-' + D2 // 之前的7天或者30天
    // return {
    // // t1: timer1,
    // // t2: timer2
    // }
    return Y2 + '-' + M2 + '-' + D2 + ' 00:00:00';
  };

  getNowFirstLastDay = () => {
    const nowDate = new Date().toLocaleDateString();
    return [moment(nowDate, 'YYYY-MM-DD'), moment(nowDate, 'YYYY-MM-DD')];
  }

  getWeekFirstLastDay = () => {
    var thisweek = {};
    var date = new Date();
    // // 本周一的日期
    // date.setDate(date.getDate() - date.getDay() + 1);
    // thisweek.start_day = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() ;
    //
    // // 本周日的日期
    // date.setDate(date.getDate() + 6);
    // thisweek.end_day = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    const firstTime = this.timeForMat(30);
    return [moment(firstTime, 'YYYY-MM-DD'), moment(date, 'YYYY-MM-DD'),];
  }

  getMonthFirstLastDay = () => {
    var firstDate = new Date();
    // firstDate.setDate(1);
    // var endDate = new Date(firstDate);
    // endDate.setMonth(firstDate.getMonth()+1);
    // endDate.setDate(0);
    // var firstday = firstDate.toLocaleDateString();
    // var lastday = endDate.toLocaleDateString();
    const firstTime = this.timeForMat(180);
    return [moment(firstTime, 'YYYY-MM-DD'),moment(firstDate, 'YYYY-MM-DD')];
  }

  getYearFirstLastDay = () => {
    var firstDay = new Date();
    firstDay.setDate(1);
    firstDay.setMonth(0);
    var lastDay = new Date();
    lastDay.setFullYear(lastDay.getFullYear()+1);
    lastDay.setDate(0);
    lastDay.setMonth(-1);
    firstDay = firstDay.toLocaleDateString();
    lastDay = lastDay.toLocaleDateString();
    return [moment(firstDay, 'YYYY-MM-DD'),moment(lastDay, 'YYYY-MM-DD')];
  }

  getIncreaseData = () => {
    const self = this;
    const {resType,timeString} = this.state;
    const startTime = moment(timeString[0]).format("YYYY-MM-DD")
    const endTime = moment(timeString[1]).format("YYYY-MM-DD")
    let url = "";
    switch(parseInt(resType)){
      case 1:
        url = "/server/admin/home/getUserIncreaseData"
        break;
      case 2:
        url = "/server/admin/home/getOrderIncreaseData?orderType=1"
        break;
      case 3:
        url = "/server/admin/home/getCostIncreaseData?transDirection=2"
        break;
      case 4:
        url = "/server/admin/home/getCostIncreaseData?transDirection=1"
        break;
      case 5:
        url = "/server/admin/home/getOrderIncreaseData?orderType=2"
        break;
    }
    Axios({
      url,
      method: 'get',
      data:{"rangedate":startTime+','+endTime},
      type: 'json'
    }, true).then(result => {
      if (result.success) {
        // result.data.forEach(d => {
        //   d.date = moment(d.date, 'YYYY-MM-DD')
        // })
        if(resType == 1){
          result.data.forEach(d => {
            d.value = parseInt(d.userCount);
          })
        }else if(resType == 2){
          result.data.forEach(d => {
            d.value = parseInt(d.orderCount)
          })
        }else if(resType == 5){
          result.data.forEach(d => {
            d.value = parseInt(d.orderWeight)
          })
        }else{
          result.data.forEach(d => {
            d.value = parseInt(d.money)
          })
        }
        self.setState({dataSource: result.data});
      }
      this.setState({spinning:false})
    });
  }

  componentDidMount(){
    const self = this;
    var date = this.getWeekFirstLastDay()
    this.setState({timeString:date},function () {
      this.getIncreaseData()
    })
  };

  render(){
    const {timeString,dataSource,timeRadio,labelShow} = this.state;
    const tabs = [
      {title:"新增用户",val:1},
      {title:"订单量",val:2},
      {title:"签收重量",val:5},
      {title:"用户消费",val:3},
      {title:"用户收入",val:4},
    ]
    const operations = (
      <div className={style.extra}>
        <div className={style.salesExtra}>
          <Radio.Group name="radiogroup" defaultValue={2} value={timeRadio} onChange={(e) => {
            this.setState({timeRadio:e.target.value})
            switch(e.target.value){
              case 1:
                var date = this.getNowFirstLastDay()
                this.setState({timeString:date,labelShow:true},function () {
                  this.getIncreaseData()
                })
                break;
              case 2:
                var date = this.getWeekFirstLastDay()
                this.setState({timeString:date,labelShow:true},function () {
                  this.getIncreaseData()
                })
                break;
              case 3:
                var date = this.getMonthFirstLastDay()
                this.setState({timeString:date,labelShow:false},function () {
                  this.getIncreaseData()
                })
                break;
              case 4:
                var date = this.getYearFirstLastDay()
                this.setState({timeString:date,labelShow:false},function () {
                  this.getIncreaseData()
                })
                break;
            }
          }}>
            {/*<Radio value={1}>今日</Radio>*/}
            <Radio value={2}>近30天</Radio>
            <Radio value={3}>半年</Radio>
            <Radio value={4}>全年</Radio>
          </Radio.Group>
        </div>
        <RangePicker
          format="YYYY-MM-DD"
          placeholder={['开始日期', '结束日期']}
          onChange={(date, timeString)=>{
            this.setState({timeString:date,timeRadio:6},function () {
              this.getIncreaseData()
            })
          }}
          value={timeString.length ? timeString : this.getNowFirstLastDay()}
          style={{width: 260}}
        />
      </div>
    )

    const scale = {
      value: {
        min: 0,
        nice: true,
      },
      date: {
        range: [0, 1],
      },
    };
    return (
      <div className={style.dataTable}>
        <Card loading={false}>
          <Tabs defaultActiveKey="1" tabBarExtraContent={operations} onChange={(val) => {
            this.setState({resType:val},function () {
              this.getIncreaseData()
            })
          }}>
            {tabs.map(d => (
              <TabPane tab={d.title} value={d.val} key={d.val}></TabPane>
            ))}
          </Tabs>
          <div className={style.tableContent}>
            <Chart scale={scale} height={390} data={dataSource} autoFit>
              <Tooltip showCrosshairs />
              <Area position="date*value" />
              <Line position="date*value" label={labelShow ? 'value' : ''} />
              <Point position="date*value" />
            </Chart>
          </div>
        </Card>
      </div>
    )
  }
}
export default DataTable;
