import React from "react";
import {
  Row,
  Col,
  Form,
  Icon,
  Input,
  Button,
  Table,
  Tag,
  Select,
  DatePicker,
  Breadcrumb,
  Radio,
  message,
} from "antd";

import Axios from "../../util/request";
import moment from "moment";
import debounce from "lodash/debounce";
import Zmage from "react-zmage";
import { carTypeList } from "../../util/publicData";

const { Option } = Select;
const { RangePicker } = DatePicker;

class ClockList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData: {}, //检索数据
      dataSource: {}, //列表数据
      provinceList: [],
      pageNum: 1,
      tableLoading: true,
      timeDate: [moment(), moment()],
    };
    this.fetchOrgData = debounce(this.fetchOrgData, 800);
  }

  // 获取table数据
  fetchOrgData = () => {
    const self = this;
    const { filterData, timeString, timeDate } = this.state;
    const page = this.pageNum || 1;
    this.setState({ tableLoading: true });
    if (!filterData.state) {
      delete filterData.state;
    }
    // if (timeDate) {
    //   filterData.createTime = timeDate[0]
    //     ? timeDate[0].format("YYYY-MM-DD") +
    //       " - " +
    //       moment(timeDate[1]).add(1, "days").format("YYYY-MM-DD")
    //     : "";
    // }
    if (timeDate) {
      filterData.createTime = timeDate[0]
        ? timeDate[0].format("YYYY-MM-DD") +
          " - " +
          timeDate[1].format("YYYY-MM-DD")
        : "";
    }
    console.log(filterData, "---------------filefskjdfjk");
    const params = JSON.stringify(filterData);
    // 拉取预约数据
    Axios(
      {
        url: "/server/admin/worker/getClockList",
        data: { page, rows: 20, params },
        method: "get",
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        result.datas.forEach((item) => {
          // if (item.status == "on") {
          //   let time =
          //     Math.floor(new Date().getTime() / 1000) -
          //     new Date(item.maxClockTime).getTime() / 1000;
          //   let hours = parseInt(time / 3600); //时
          //   let minutes = parseInt((time - hours * 3600) / 60); //秒
          //   item.duration = `${hours}时${minutes}分`;
          // } else {
          //   item.duration = "0时0分";
          // }
          // 如果没第一次打卡，返回0时0分
          // 有第一次打卡，
          // 无第二次打卡，时长 = 此时 - 第一次打卡（还需要少与24h，避免这一天没打第二次卡时长超过24小时）
          // 有第二次打卡，时长 = 第二次打卡 - 第一次打卡
          if (item.minClockTime) {
            if (
              new Date(item.maxClockTime).getTime() / 1000 -
                new Date(item.minClockTime).getTime() / 1000 ==
                0 &&
              Math.floor(new Date().getTime() / 1000) -
                new Date(item.minClockTime).getTime() / 1000 <
                86400
            ) {
              let time =
                Math.floor(new Date().getTime() / 1000) -
                new Date(item.minClockTime).getTime() / 1000;
              let hours = parseInt(time / 3600); //时
              let minutes = parseInt((time - hours * 3600) / 60); //秒
              item.duration = `${hours}时${minutes}分`;
            } else {
              let time =
                new Date(item.maxClockTime).getTime() / 1000 -
                new Date(item.minClockTime).getTime() / 1000;
              let hours = parseInt(time / 3600); //时
              let minutes = parseInt((time - hours * 3600) / 60); //秒
              item.duration = `${hours}时${minutes}分`;
            }
          } else {
            item.duration = "0时0分";
          }
        });
        self.setState({ dataSource: result, tableLoading: false });
      }
    });
  };

  componentDidMount() {
    const self = this;
    const { filterData } = this.state;
    const { search } = this.props.location;
    const paramsString = search.substring(1);
    const searchParams = new URLSearchParams(paramsString);
    const sortersName = searchParams.get("sortersName");

    if (filterData) {
      filterData.sortersName = sortersName;
    }
    this.setState({ filterData }, function () {
      self.fetchOrgData();
    });
  }

  render() {
    const { filterData, timeDate, tableLoading } = this.state;
    const { match } = this.props;
    const dataColumns = [
      {
        title: "姓名",
        dataIndex: "classifierName",
        key: "classifierName",
      },
      {
        title: "身份",
        dataIndex: "workerTypeName",
        key: "workerTypeName",
      },
      {
        title: "车辆",
        dataIndex: "carCode",
        key: "carCode",
      },
      {
        title: "当前定位",
        dataIndex: "address",
        key: "address",
      },
      {
        title: "经纬度",
        dataIndex: "lat",
        key: "lat",
        render: (cur, record) => {
          return (
            <div>
              {record.latitude},{record.longitude}
            </div>
          );
        },
      },
      {
        title: "工作时长",
        dataIndex: "duration",
        key: "duration",
      },
      {
        title: "第一次打卡时间",
        dataIndex: "minClockTime",
        key: "minClockTime",
        render: (qrcode, record) => {
          return (
            <div>{qrcode && moment(qrcode).format("YYYY-MM-DD HH:mm")} </div>
          );
        },
      },

      {
        title: "最后打卡时间",
        dataIndex: "maxClockTime",
        key: "maxClockTime",
        render: (qrcode, record) => {
          return (
            <div>{qrcode && moment(qrcode).format("YYYY-MM-DD HH:mm")} </div>
          );
        },
      },
    ];
    return (
      <div style={{ flex: 1 }}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Icon type="home" /> 首页
          </Breadcrumb.Item>
          <Breadcrumb.Item>打卡记录</Breadcrumb.Item>
        </Breadcrumb>
        <div className="mt-20">
          <Form layout="inline" style={{ flex: 1 }}>
            <Form.Item label="打卡时间">
              <RangePicker
                format="YYYY-MM-DD"
                placeholder={["开始日期", "结束日期"]}
                onChange={(date, timeString) => {
                  this.setState({ timeString, timeDate: date });
                }}
                value={timeDate}
                style={{ width: 220 }}
                // disabledDate={this.disabledDate}
              />
            </Form.Item>
            <Form.Item label="姓名">
              <Input
                placeholder="姓名"
                allowClear
                onChange={(e) => {
                  const { filterData } = this.state;
                  filterData.classifierName = e.target.value;
                  console.log(
                    filterData.classifierName,
                    typeof filterData.classifierName,
                    "----------------------------classifierName"
                  );
                  this.setState({ filterData });
                }}
                style={{ width: 200 }}
              ></Input>
            </Form.Item>
            <Form.Item>
              <Button
                onClick={() => {
                  this.pageNum = 1;
                  this.fetchOrgData();
                }}
                type="primary"
              >
                搜索
              </Button>
            </Form.Item>
          </Form>
          <div className="card-container" style={{ marginTop: 20 }}>
            <Table
              loading={tableLoading}
              scroll={{ x: 1300 }}
              dataSource={this.state.dataSource.datas}
              pagination={{
                total: this.state.dataSource.total || 0,
                showTotal: (total) =>
                  `共 ${this.state.dataSource.total || 0} 条数据`,
                pageSize: 20,
                current: this.pageNum,
                onChange: (page) => {
                  this.pageNum = page;
                  this.fetchOrgData();
                },
              }}
              columns={dataColumns}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ClockList;
