export const recyclerServices = [
  // carType: 3
  { sqlName: "isDriver", label: "司机", value: 1, isCar: 1, carType: 3 },
  { sqlName: "isSanitation", label: "环卫工人", value: 2 },
  { sqlName: "isPublicize", label: "宣传员", value: 3 },
  { sqlName: "isVolunteer", label: "志愿者", value: 4 },
  { sqlName: "isClassifier", label: "回收员", value: 5, isCar: 1, carType: 1 },
  { sqlName: "isCommissioner", label: "项目专员", value: 6 },
  { sqlName: "isLoader", label: "装卸工", value: 7, isCar: 1 },
];
// 车辆类型 1三轮回收物车 2三轮保洁车 3四轮小货车 4四轮其他垃圾清运车 5四轮厨余垃圾清运车 6建筑垃圾清运车辆
export const carTypeList = [
  { label: "三轮回收物车", value: "1" },
  { label: "三轮保洁车", value: "2" },
  { label: "四轮小货车", value: "3" },
  { label: "四轮其他垃圾清运车", value: "4" },
  { label: "四轮厨余垃圾清运车", value: "5" },
  { label: "建筑垃圾清运车辆", value: "6" },
  { label: "污水车", value: "7" },
  { label: "六轮压缩车", value: "8" },
];
export const classifierLevel = [
  { label: "新锐", value: "1" },
  { label: "骨干", value: "2" },
  { label: "精英", value: "3" },
  { label: "资深", value: "4" },
  { label: "首席", value: "5" },
];
export const orgContacts = [
  { label: "可回收物", value: "dry" },
  { label: "厨余", value: "wet" },
];
export const orgServices = [
  { label: "可回收", value: "s1" },
  { label: "大件家电", value: "s2" },
  { label: "大件家具", value: "s3" },
  { label: "装修垃圾", value: "s4" },
];
export const shopTypes = [
  { label: "线下商家", value: "isOffLine" },
  { label: "线上商家", value: "isOnline" },
  { label: "小爱到家商家", value: "isOnDoor" },
];
export const dateOptions = [
  { label: "星期一", value: "openWeek1" },
  { label: "星期二", value: "openWeek2" },
  { label: "星期三", value: "openWeek3" },
  { label: "星期四", value: "openWeek4" },
  { label: "星期五", value: "openWeek5" },
  { label: "星期六", value: "openWeek6" },
  { label: "星期日", value: "openWeek0" },
];
export const timeOptions = [
  { label: "08:00-10:00", value: "open10" },
  { label: "10:00-12:00", value: "open12" },
  { label: "12:00-14:00", value: "open14" },
  { label: "14:00-16:00", value: "open16" },
  { label: "16:00-18:00", value: "open18" },
  { label: "18:00-20:00", value: "open20" },
];
export const communityType = [
  { label: "全部", value: "0" },
  { label: "街道", value: "1" },
  { label: "办公", value: "2" },
  { label: "商业", value: "3" },
  { label: "学校", value: "4" },
];
export const dropStyle = [
  { label: "桶站", value: "1" },
  { label: "可回收物交投点", value: "2" },
  { label: "大件垃圾暂存点", value: "3" },
  { label: "装修垃圾暂存点", value: "4" },
  { label: "分类驿站", value: "5" },
];
export const dropType = [
  { label: "可回收物", value: "1" },
  { label: "厨余", value: "2" },
  { label: "有害", value: "3" },
  { label: "其它", value: "4" },
];
