import React from 'react';

import { Menu, Icon, Tabs } from 'antd';
import { BrowserRouter as Router, Route , withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { DispatchingContainer } from '../dispatchingService/style';
import CarList from "./CarList";
import CarListDetail from "./CarListDetail";
import GpsList from "./GpsList";
import GpsStatList from './GpsStatList';
import GpsLogList from './GpsLogList';

const { SubMenu } = Menu;

class Sorters extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedKeys:"",//默认路由
    };
  }

  //获取路由最后一个地址名称
  handleSetSelectedKeys(pathname) {
    // /admin = ["/","admin"]
    // 根据'/'把路由地址分割成一个数组
    const temp = pathname.split('/');
    // 如果数组的长度小于2,表示的是只有根路径/,设置为Home. 否则取数组中第二个值
    const key = temp[temp.length - 1];
    this.setState({
      selectedKeys: key
    });
  }


  componentWillMount() {
    this.handleSetSelectedKeys(this.props.history.location.pathname);
  }

  render() {

    const {match} = this.props;
    const {selectedKeys} = this.state.selectedKeys;

    return <DispatchingContainer>
      <div style={{ padding: 20, flex: 1, width:"700px" }}>
          <Route path={`${match.path}/carList`} component={CarList} />
          <Route path={`${match.path}/carListDetail`} component={CarListDetail} />
          <Route path={`${match.path}/gpsList`} component={GpsList} />
          <Route path={`${match.path}/gpsStatList`} component={GpsStatList} />
          <Route path={`${match.path}/gpsLogList`} component={GpsLogList} />
      </div>
    </DispatchingContainer>
  }
}

export default Sorters;
