import React from "react";
import {
  Row,
  Col,
  Form,
  Icon,
  Input,
  Button,
  Table,
  Tag,
  Select,
  DatePicker,
  Breadcrumb,
  Radio,
  message,
} from "antd";

import Axios from "../../util/request";
import moment from "moment";
import UpdataStn from "./components/UpdataStn";
import debounce from "lodash/debounce";
import Zmage from "react-zmage";
import { carTypeList } from "../../util/publicData";
import CarTypeSelect from "../../components/select/CarTypeSelect";

const { Option } = Select;

class TransferStn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData: {
        type: 1,
      }, //检索数据
      dataSource: {}, //列表数据
      provinceList: [],
      pageNum: 1,
      tableLoading: true,
      projectNameMap: [],
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
    };
    this.projectNameMap = [];
    this.fetchOrgData = debounce(this.fetchOrgData, 800);
    this.fetchPorjectData = debounce(this.fetchPorjectData, 800);
  }
  fetchPorjectData = (url, type) => {
    const self = this;
    //筛选下拉查询
    Axios({ url, method: "get" }, true).then((result) => {
      if (result.success) {
        let projectNameMap = [];
        projectNameMap = result.data.map((item) => {
          return { label: item.name, value: item.id };
        });
        self.setState({ projectNameMap });
      }
    });
  };
  getProjectNameMap = () => {
    const self = this;
    Axios(
      {
        // url: "/server/admin/worker/getUserList",
        url: "/server/admin/project/getProjects",
        data: { page: 1, rows: 200, params: JSON.stringify({}) },
        method: "get",
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        self.projectNameMap = result.data.map((item) => {
          return { label: item.name, value: item.id };
        });
        self.setState({
          projectNameMap: self.projectNameMap,
        });
      }
    });
  };

  // 获取table数据
  fetchOrgData = () => {
    const self = this;
    const { filterData, timeString, userInfo } = this.state;
    const page = this.pageNum || 1;
    this.setState({ tableLoading: true });
    // if (!filterData.state) {
    //     delete filterData.state;
    // }
    if (userInfo.projectId) {
      filterData.projectId = userInfo.projectId;
    }
    const params = JSON.stringify(filterData);
    // 拉取预约数据
    Axios(
      {
        url: "/server/admin/drop/getDropList",
        data: { page, rows: 20, params },
        method: "get",
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        self.setState({ dataSource: result, tableLoading: false });
      }
    });
  };

  componentDidMount() {
    const self = this;
    const { filterData, userInfo } = this.state;
    const { search } = this.props.location;
    const paramsString = search.substring(1);
    const searchParams = new URLSearchParams(paramsString);
    const sortersName = searchParams.get("sortersName");

    if (filterData) {
      filterData.sortersName = sortersName;
    }
    this.setState({ filterData }, function () {
      self.fetchOrgData();
    });
    this.getProjectNameMap();
  }

  render() {
    const self = this;
    const { filterData, tableLoading, userInfo } = this.state;
    const { match } = this.props;
    const dataColumns = [
      {
        title: "编号",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "点位名称",
        dataIndex: "dropName",
        key: "dropName",
      },
      {
        title: "类型",
        dataIndex: "type",
        key: "type",
        render: (cur, record) => {
          let type = "";
          switch (cur) {
            case 1:
              type = "中转站";
              break;
            case 2:
              type = "垃圾焚烧厂";
              break;
            case 3:
              type = "桶站";
              break;
            default:
              break;
          }
          return <div>{type}</div>;
        },
      },
      {
        title: "经纬度",
        dataIndex: "dropLocation",
        key: "dropLocation",
      },

      {
        title: "街道",
        dataIndex: "areaName",
        key: "areaName",
      },
      {
        title: "地址",
        dataIndex: "dropAddress",
        key: "dropAddress",
      },
      {
        title: "半径(M)",
        dataIndex: "radius",
        key: "radius",
        render: (cur, record) => {
          return cur ? <div>{cur}m</div> : "";
        },
      },
      {
        title: "状态",
        dataIndex: "state",
        key: "state",
        render: (cur, record) => {
          return (
            <div>
              {cur == 1 ? (
                <Tag color="#87d068">启用</Tag>
              ) : (
                <Tag color="#f50">禁用</Tag>
              )}
            </div>
          );
        },
      },
      {
        title: "创建时间",
        dataIndex: "createTime",
        key: "createTime",
        render: (qrcode, record) => {
          return <div>{qrcode && moment(qrcode).format("YYYY-MM-DD")} </div>;
        },
      },
      {
        title: "更新时间",
        dataIndex: "updateTime",
        key: "updateTime",
        render: (qrcode, record) => {
          return <div>{qrcode && moment(qrcode).format("YYYY-MM-DD")} </div>;
        },
      },
      {
        title: "操作",
        render: (qrcode, record) => {
          return (
            <div className="optbtn">
              <Button
                type="primary"
                icon="edit"
                title="编辑"
                onClick={() => this.UpdataStn.showDetail(record)}
              ></Button>
            </div>
          );
        },
      },
    ];
    return (
      <div style={{ flex: 1 }}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Icon type="home" /> 首页
          </Breadcrumb.Item>
          <Breadcrumb.Item>点位管理</Breadcrumb.Item>
          <Breadcrumb.Item>中转站</Breadcrumb.Item>
        </Breadcrumb>
        <div className="mt-20">
          <Form layout="inline" style={{ flex: 1 }}>
            <Form.Item label="点位名称">
              <Input
                placeholder="点位名称"
                allowClear
                onChange={(e) => {
                  const { filterData } = this.state;
                  filterData.dropName = e.target.value;
                  this.setState({ filterData });
                }}
                value={filterData.dropName}
                style={{ width: 200 }}
              ></Input>
            </Form.Item>
            <Form.Item label="项目">
              <Select
                defaultValue={userInfo.projectId || undefined}
                disabled={userInfo.projectId != null ? true : false}
                onChange={(val) => {
                  const { filterData } = this.state;
                  filterData.projectId = val;
                  this.setState({ filterData });
                }}
                onSearch={(value) => {
                  self.fetchPorjectData(
                    "/server/admin/project/getProjects?projectName=" + value
                  );
                }}
                onSelect={() => {
                  self.setState({ projectNameMap: self.projectNameMap });
                }}
                placeholder="项目"
                style={{ width: 120 }}
                allowClear
                showSearch
                filterOption={false}
              >
                {this.state.projectNameMap.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {/* <Form.Item label="点位类型">
                            <Select
                                onChange={val => {
                                    const { filterData } = this.state;
                                    filterData.type = val;
                                    this.setState({ filterData });
                                }}
                                style={{ width: 200 }}
                                placeholder="请选择"
                            >
                                <Option key={0} value={'1'}>
                                    中转站
                                </Option>
                                <Option key={1} value={'2'}>
                                    垃圾焚烧厂
                                </Option>
                                <Option key={2} value={'3'}>
                                    桶站
                                </Option>
                            </Select>
                        </Form.Item> */}
            <Form.Item>
              <Button
                onClick={() => {
                  this.pageNum = 1;
                  this.fetchOrgData();
                }}
                type="primary"
              >
                搜索
              </Button>
            </Form.Item>
          </Form>
          <br />

          <div className="card-container" style={{ marginTop: 20 }}>
            <Table
              loading={tableLoading}
              scroll={{ x: 1300 }}
              dataSource={this.state.dataSource.datas}
              pagination={{
                total: this.state.dataSource.total || 0,
                showTotal: (total) =>
                  `共 ${this.state.dataSource.total || 0} 条数据`,
                pageSize: 5,
                current: this.pageNum,
                onChange: (page) => {
                  this.pageNum = page;
                  this.fetchOrgData();
                },
              }}
              columns={dataColumns}
            />
          </div>
        </div>
        <UpdataStn
          fetchOrgData={this.fetchOrgData}
          onRef={(c) => (this.UpdataStn = c)}
        ></UpdataStn>
      </div>
    );
  }
}

export default TransferStn;
