import React from "react";
import { Row, Col, message, Upload, Divider, Breadcrumb } from "antd";
import { Form, Icon, Input, Button, Select, Modal } from "antd";

import Axios from "../../util/request";
import debounce from "lodash/debounce";
import { filter, method } from "lodash";

const { Option } = Select;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

class AddTicket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        title: "提示",
        visible: false,
        width: 600,
        token: null,
        content: function () {
          return <span></span>;
        },
        loading: false,
        userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
      },
      filterData: {}, //用户信息
      imageUrl: "",
      areaList: [],
      committeeCodeList: [],
      carList: [],
      recvWorkMap: [],
      sendWorkerMap: [],
      projectNameMap: [],
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
    };
    this.recvWorkMap = [];
    this.sendWorkerMap = [];
    this.projectNameMap = [];
    this.fetchData = debounce(this.fetchData, 800);
    this.fetchPorjectData = debounce(this.fetchPorjectData, 800);
    if (props.onRef) {
      //如果父组件传来该方法 则调用方法将子组件this指针传过去
      props.onRef(this);
    }
  }

  fetchPorjectData = (url, type) => {
    const self = this;
    //筛选下拉查询
    Axios({ url, method: "get" }, true).then((result) => {
      if (result.success) {
        let projectNameMap = [];
        projectNameMap = result.data.map((item) => {
          return { label: item.name, value: item.id };
        });
        self.setState({ projectNameMap });
      }
    });
  };
  getProjectNameMap = () => {
    const self = this;
    Axios(
      {
        // url: "/server/admin/worker/getUserList",
        url: "/server/admin/project/getProjects",
        data: { page: 1, rows: 200, params: JSON.stringify({}) },
        method: "get",
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        self.projectNameMap = result.data.map((item) => {
          return { label: item.name, value: item.id };
        });
        self.setState({
          projectNameMap: self.projectNameMap,
        });
      }
    });
  };

  getUploadToken = (values) => {
    //提交新增用户信息方法
    const self = this;
    Axios(
      {
        url: "/server/admin/qiniu/getUploadToken",
        method: "get",
        data: values,
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        self.setState({ token: result.uploadToken });
      }
    });
  };

  showDetail = (record) => {
    const { modal } = this.state;
    const self = this;
    this.props.form.resetFields();
    modal.visible = true;
    modal.width = 600;
    modal.loading = false;
    if (record && record.id) {
      modal.title = <span>修改工单信息</span>;

      const filterData = { ...record };
      self.setState({
        modal,
        record,
        filterData,
        imageUrl: record.userHeadImg,
      });
    } else {
      modal.title = <span>新增工单信息</span>;
      const filterData = {};
      filterData.state = 1;
      self.setState({ modal, filterData, imageUrl: "" });
    }
  };

  handleCancel = () => {
    const { modal } = this.state;
    modal.visible = false;
    this.setState({ modal });
  };

  // 发送订阅消息
  sendSubscribe(recvWorkerOpenId, ticketInfo) {
    Axios({
      url: "/server/mini/user/launchSubscription",
      method: "post",
      data: { recvWorkerOpenId, ticketInfo },
    }).then((response) => {});
    const self = this;
    // 订阅发送成功后，接收人的订阅次数见一
    // 下面这个id换成接收人的id
    const { filterData } = this.state;
    console.log(201, filterData, filterData.recvWorkId);
    Axios({
      url: "/server/admin/ticket/getUserId",
      method: "get",
      data: { id: filterData.recvWorkId },
    }).then((result) => {
      if (result.success) {
        console.log(207, result.data.id);
        const id = result.data.id;
        Axios({
          url: "/server/mini/subscript/getSubscript",
          method: "get",
          data: {
            params: JSON.stringify({
              userId: id,
              templateId: "zqXXh3Y0u5lahBEBgQeZAswcbh58xVPJMpY4e6LY-WM",
            }),
          },
        }).then((response) => {
          if (response.success) {
            const { total, id, used, userId } = response.data;
            Axios({
              url: "/server/mini/subscript/updateSubscript",
              method: "post",
              data: {
                id,
                userId: userId,
                total: total,
                used: used + 1,
                templateId: "zqXXh3Y0u5lahBEBgQeZAswcbh58xVPJMpY4e6LY-WM",
              },
            }).then((response) => {
              if (response.success) console.log("修改成功");
            });
          }
        });
      }
    });
  }

  handleOk = () => {
    const self = this;
    const { form } = this.props;
    const { filterData, imageUrl, userInfo } = this.state;
    filterData.userHeadImg = imageUrl ? imageUrl : "";
    if (userInfo.projectId) {
      filterData.projectId = userInfo.projectId;
    }
    console.log(249, filterData.recvWorkId, filterData);
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      console.log("JFK是的降幅扩大解放健康的jf");
      Axios(
        {
          url: "/server/admin/ticket/updateTicket",
          method: "post",
          data: filterData,
          type: "json",
        },
        true
      ).then((result) => {
        if (result.success) {
          message.success("新增成功");

          Axios(
            {
              url: "/server/admin/ticket/getOpenId",
              method: "get",
              data: { id: filterData.recvWorkId },
              type: "json",
            },
            true
          ).then((result2) => {
            if (result2.success) {
              self.sendSubscribe(result2.data.openId, { ...filterData });
            }
          });

          //   self.props.fetchOrgData();
          const { modal } = self.state;
          modal.visible = false;
          self.setState({ modal });
          self.props.history.push(
            "/admin/ticket/ticketList?sendWorkerId=" + filterData.sendWorkerId
          );
        }
      });
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log(values);
      }
    });
  };
  fetchData = (url, type) => {
    const self = this;
    const { userInfo } = this.state;
    //筛选下拉查询
    Axios(
      {
        url,
        method: "get",
        data: { params: JSON.stringify({ projectId: userInfo.projectId }) },
      },
      true
    ).then((result) => {
      if (result.success) {
        if (type === "sendWorkerMap") {
          let sendWorkerMap = [];
          sendWorkerMap = result.data.map((item) => {
            return { label: item.classifierName, value: item.id };
          });
          self.setState({ sendWorkerMap });
        }

        if (type === "recvWorkMap") {
          let recvWorkMap = [];
          recvWorkMap = result.data.map((item) => {
            return { label: item.classifierName, value: item.id };
          });
          self.setState({ recvWorkMap });
        }
      }
    });
  };
  getNameMap = () => {
    const self = this;
    const { userInfo } = this.state;
    Axios(
      {
        // url: "/server/admin/worker/getUserList",
        url: "/server/admin/ticket/getNameMap",
        data: {
          page: 1,
          rows: 200,
          params: JSON.stringify({ projectId: userInfo.projectId }),
        },
        method: "get",
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        console.log(result, "---------------result");
        self.sendWorkerMap = result.data.map((item) => {
          return { label: item.classifierName, value: item.id };
        });
        self.recvWorkMap = result.data.map((item) => {
          return { label: item.classifierName, value: item.id };
        });
        self.setState({
          sendWorkerMap: self.sendWorkerMap,
          recvWorkMap: self.recvWorkMap,
        });
      }
    });
  };
  componentDidMount() {
    const self = this;
    this.getUploadToken();
    this.getNameMap();
    this.getProjectNameMap();
  }

  render() {
    const self = this;
    const { filterData, sendWorkerMap, recvWorkMap, userInfo } = this.state;
    const { match } = this.props;
    const { getFieldDecorator } = this.props.form;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">上传图片</div>
      </div>
    );
    return (
      <div>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Icon type="home" /> 首页
          </Breadcrumb.Item>
          <Breadcrumb.Item>新增工单</Breadcrumb.Item>
        </Breadcrumb>
        <Form
          name="nest-messages"
          style={{ maxWidth: "1000px", marginTop: "30px" }}
          {...layout}
          onSubmit={this.handleSubmit}
        >
          <Row>
            <Col span={24}>
              <Form.Item label="发起人">
                <Select
                  onChange={(val, o) => {
                    const { filterData } = this.state;
                    filterData.sendWorkerId = val;
                    filterData.sendWorkerName = o.props.children;
                    this.setState({ filterData });
                  }}
                  onSearch={(value) => {
                    self.fetchData(
                      "/server/admin/ticket/getNameMap?classifierName=" + value,
                      "sendWorkerMap"
                    );
                  }}
                  onSelect={() => {
                    self.setState({ sendWorkerMap: self.sendWorkerMap });
                  }}
                  placeholder="发起人"
                  style={{ width: 120 }}
                  allowClear
                  showSearch
                  filterOption={false}
                >
                  {sendWorkerMap.map((item) => (
                    <Option key={item.value} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="接收人">
                <Select
                  onChange={(val, o) => {
                    const { filterData } = this.state;
                    filterData.recvWorkId = val;
                    filterData.recvWorkName = o.props.children;
                    this.setState({ filterData });
                  }}
                  onSearch={(value) => {
                    self.fetchData(
                      "/server/admin/ticket/getNameMap?classifierName=" + value,
                      "recvWorkMap"
                    );
                  }}
                  onSelect={() => {
                    self.setState({ recvWorkMap: self.recvWorkMap });
                  }}
                  placeholder="接收人"
                  style={{ width: 120 }}
                  allowClear
                  showSearch
                  filterOption={false}
                >
                  {recvWorkMap.map((item) => (
                    <Option key={item.value} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="项目">
                {getFieldDecorator("projectId", {
                  rules: [{ required: true, message: "请选择项目!" }],
                  initialValue:
                    userInfo.projectId != null
                      ? userInfo.projectId
                      : filterData.projectId,
                })(
                  <Select
                    disabled={userInfo.projectId != null ? true : false}
                    onChange={(val) => {
                      const { filterData } = this.state;
                      filterData.projectId = val;
                      this.setState({ filterData });
                    }}
                    onSearch={(value) => {
                      self.fetchPorjectData(
                        "/server/admin/project/getProjects?projectName=" + value
                      );
                    }}
                    onSelect={() => {
                      self.setState({ projectNameMap: self.projectNameMap });
                    }}
                    placeholder="项目"
                    style={{ width: 120 }}
                    allowClear
                    showSearch
                    filterOption={false}
                  >
                    {this.state.projectNameMap.map((item) => (
                      <Option key={item.value} value={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item name={["classifier", "content"]} label="任务内容">
                {getFieldDecorator("content", {
                  rules: [{ required: true, message: "请输入任务内容!" }],
                  initialValue: filterData.content,
                })(
                  <Input
                    placeholder="任务内容"
                    style={{ width: 300 }}
                    onChange={(e) => {
                      const { filterData } = this.state;
                      filterData.content = e.target.value;
                      this.setState({ filterData });
                    }}
                  />
                )}
              </Form.Item>
              <Form.Item name={["classifier", "log"]} label="工单日志">
                {getFieldDecorator("log", {
                  rules: [{ required: true, message: "请输入工单日志!" }],
                  initialValue: filterData.log,
                })(
                  <Input
                    placeholder="工单日志"
                    style={{ width: 300 }}
                    onChange={(e) => {
                      const { filterData } = this.state;
                      filterData.log = e.target.value;
                      this.setState({ filterData });
                    }}
                  />
                )}
              </Form.Item>
              <Form.Item name={["classifier", "type"]} label="工单类型">
                <Select
                  onChange={(val) => {
                    const { filterData } = this.state;
                    filterData.type = val.trim();
                    this.setState({ filterData });
                  }}
                  placeholder="全部"
                  defaultValue="全部"
                  style={{ width: 120 }}
                >
                  <Option key=" " value={" "}>
                    全部
                  </Option>
                  <Option key={1} value={"clean"}>
                    清扫区域
                  </Option>
                  <Option key={0} value={"ship"}>
                    安排清运车辆
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item name={["classifier", "status"]} label="工单状态">
                <Select
                  onChange={(val, o) => {
                    const { filterData } = this.state;
                    filterData.status = val.trim();
                    filterData.statusName = o.props.children;
                    this.setState({ filterData });
                  }}
                  placeholder="全部"
                  defaultValue="全部"
                  style={{ width: 120 }}
                >
                  <Option key=" " value={" "}>
                    全部
                  </Option>
                  <Option key={1} value={"wait"}>
                    待接单
                  </Option>
                  <Option key={0} value={"accept"}>
                    已接单
                  </Option>
                  <Option key={1} value={"doing"}>
                    开始处理
                  </Option>
                  <Option key={0} value={"done"}>
                    完成
                  </Option>
                  <Option key={1} value={"error"}>
                    无法完成
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item label="操作">
                <Button key="back" onClick={this.handleCancel}>
                  取消
                </Button>
                <Button
                  style={{ marginLeft: "30px" }}
                  key="submit"
                  type="primary"
                  onClick={this.handleOk}
                >
                  确定
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
export default Form.create({ name: "submit" })(AddTicket);
