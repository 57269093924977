import React from "react";
import { Form, Select } from "antd";
import { carTypeList } from "../../util/publicData";

import Axios from "../../util/request";

const { Option } = Select;

class carTypeSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const self = this;
  }

  render() {
    const { areaList } = this.state;
    return (
      <Select
        showSearch
        allowClear
        style={{ width: 200 }}
        placeholder="车辆类型"
        // defaultValue="全部"
        onChange={(val, d) => {
          this.props.onChange(val, d ? d.key : "");
        }}
        value={this.props.value}
        optionFilterProp="children"
      >
        {carTypeList.map((d) => (
          <Option key={d.label} value={d.value} title={d.label}>
            {d.label}
          </Option>
        ))}
      </Select>
    );
  }
}

export default carTypeSelect;
