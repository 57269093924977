import React from 'react';
import {Form, Select} from 'antd';

import debounce from 'lodash/debounce';
import Axios from "../../util/request";

const {Option} = Select;

class FranchiseeSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      franchiseeList: [],
    };
    this.getFranchiseeSelect = debounce(this.getFranchiseeSelect, 800);
    if(props.onRef){//如果父组件传来该方法 则调用方法将子组件this指针传过去
      props.onRef(this)
    }
  };

  getFranchiseeSelect = (value) => {
    const self = this;
    const params = JSON.stringify({"franchiseeName":value})
    Axios({
      url: '/server/admin/franchisee/getDropDownList',
      data: {page:1, "rows": 20, params},
      method: 'get',
      type: 'json'
    }, true).then(result => {
      if (result.success) {
        self.setState({franchiseeList: result.data});
      }
    });
  };

  componentDidMount(){
    const self = this;
  }

  render() {
    const { franchiseeList} = this.state;
    return (
      <Select
        showSearch
        allowClear
        style={this.props.style ? this.props.style : { width: 300 }}
        onSearch={(value)=>{
          this.getFranchiseeSelect(value);
        }}
        onChange={val => {
          this.props.onChange(val)
        }}
        value={this.props.value}
        optionFilterProp="children"
      >
        {franchiseeList.map(d => (
          <Option key={d.id} title={d.franchiseeName}>{d.franchiseeName}</Option>
        ))}
      </Select>
    )
  }



}

export default FranchiseeSelect;
