import styled, { createGlobalStyle } from 'styled-components';

export const DispatchingContainer = styled.div`
  height: 100%;
  display: flex;
`;

export const ButtonOperationGroup = styled.div`
  margin-bottom: 10px;
  text-align: right;
  .ant-checkbox-wrapper {
    margin-right: 10px;
  }

  > button {
    margin-right: 10px;
  }
`;

export const OperationIcon = styled.img`
  width: 30;
`;
