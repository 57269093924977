import React from "react";
import {
  Row,
  Col,
  Form,
  Icon,
  Input,
  Button,
  Table,
  Tag,
  Select,
  DatePicker,
  Breadcrumb,
  Radio,
  message,
} from "antd";

import Axios from "../../util/request";
import moment from "moment";
import debounce from "lodash/debounce";
import Zmage from "react-zmage";
import { carTypeList } from "../../util/publicData";
import CarTypeSelect from "../../components/select/CarTypeSelect";

const { Option } = Select;

class GpsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData: {}, //检索数据
      dataSource: {}, //列表数据
      provinceList: [],
      pageNum: 1,
      tableLoading: true,
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
    };
    this.pageNum = 1;
    this.fetchOrgData = debounce(this.fetchOrgData, 800);
  }

  // 获取table数据
  fetchOrgData = () => {
    const self = this;
    const { filterData, timeString, userInfo } = this.state;
    const page = this.pageNum || 1;
    this.setState({ tableLoading: true });
    if (!filterData.state) {
      delete filterData.state;
    }
    if (userInfo.projectId) {
      filterData.projectId = userInfo.projectId;
    }
    const params = JSON.stringify(filterData);
    // 拉取预约数据
    Axios(
      {
        url: "/server/admin/car/getGpsList",
        data: { page, rows: 20, params },
        method: "get",
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        self.setState({ dataSource: result, tableLoading: false });
      }
    });
  };

  componentDidMount() {
    const self = this;
    const { filterData } = this.state;
    const { search } = this.props.location;
    const paramsString = search.substring(1);
    const searchParams = new URLSearchParams(paramsString);
    const sortersName = searchParams.get("sortersName");

    if (filterData) {
      filterData.sortersName = sortersName;
    }
    this.setState({ filterData }, function () {
      self.fetchOrgData();
    });
  }

  render() {
    const { filterData, tableLoading } = this.state;
    const { match } = this.props;
    const self = this;
    const dataColumns = [
      {
        title: "编号",
        dataIndex: "id",
        key: "id",
        render: (cur, record, index) => {
          return (self.pageNum - 1) * 20 + index + 1;
        },
      },
      {
        title: "车牌号",
        dataIndex: "carCode",
        key: "carCode",
        render: (cur) => {
          return (
            <div
              style={{ cursor: "pointer", color: "rgb(64,169,255)" }}
              onClick={() => {
                window.open("/admin/car/carListDetail/?code=" + cur);
              }}
            >
              {cur}
            </div>
          );
        },
      },
      {
        title: "GPS设备号",
        dataIndex: "cid",
        key: "cid",
      },
      {
        title: "经纬度",
        dataIndex: "lat",
        key: "lat",
        render: (cur, record) => {
          return (
            <div className="optbtn">
              <a
                href={`https://apis.map.qq.com/uri/v1/marker?marker=coord:${record.lat},${record.lng};title:${record.cid};addr:${record.cid}&referer=myapp`}
                target="_blank"
              >
                {record.lat},{record.lng}
              </a>
            </div>
          );
        },
      },
      {
        title: "创建时间",
        dataIndex: "ctime",
        key: "ctime",
        render: (qrcode, record) => {
          return <div>{qrcode && moment(qrcode).format("YYYY-MM-DD")} </div>;
        },
      },
      {
        title: "最后更新时间",
        dataIndex: "mtime",
        key: "mtime",
        render: (qrcode, record) => {
          return (
            <div>{qrcode && moment(qrcode).format("YYYY-MM-DD HH:mm")} </div>
          );
        },
      },
      {
        title: "操作",
        render: (qrcode, record) => {
          return (
            <div className="optbtn">
              <a
                href={`https://apis.map.qq.com/uri/v1/marker?marker=coord:${record.lat},${record.lng};title:${record.cid};addr:${record.cid}&referer=myapp`}
                target="_blank"
              >
                <Button type="primary" icon="eye" title="查看"></Button>
              </a>
            </div>
          );
        },
      },
    ];
    return (
      <div style={{ flex: 1 }}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Icon type="home" /> 首页
          </Breadcrumb.Item>
          <Breadcrumb.Item>GPS设备管理</Breadcrumb.Item>
        </Breadcrumb>
        <div className="mt-20">
          <Form layout="inline" style={{ flex: 1 }}>
            <Form.Item label="GPS设备号">
              <Input
                placeholder="GPS设备号"
                allowClear
                onChange={(e) => {
                  const { filterData } = this.state;
                  filterData.cid = e.target.value;
                  this.setState({ filterData });
                }}
                value={filterData.cid}
                style={{ width: 200 }}
              ></Input>
            </Form.Item>
            {/*<Form.Item label="类型">*/}
            {/*    <CarTypeSelect onChange={(val) =>{*/}
            {/*        const {filterData} = this.state;*/}
            {/*        filterData.type = val;*/}
            {/*        this.setState({filterData});*/}
            {/*    }}></CarTypeSelect>*/}
            {/*</Form.Item>*/}
            <Form.Item>
              <Button
                onClick={() => {
                  this.pageNum = 1;
                  this.fetchOrgData();
                }}
                type="primary"
              >
                搜索
              </Button>
            </Form.Item>
          </Form>
          <div className="card-container" style={{ marginTop: 20 }}>
            <Table
              loading={tableLoading}
              scroll={{ x: 1300 }}
              dataSource={this.state.dataSource.datas}
              pagination={{
                total: this.state.dataSource.total || 0,
                showTotal: (total) =>
                  `共 ${this.state.dataSource.total || 0} 条数据`,
                pageSize: 20,
                current: this.pageNum,
                onChange: (page) => {
                  this.pageNum = page;
                  this.fetchOrgData();
                },
              }}
              columns={dataColumns}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default GpsList;
