import { Breadcrumb, Col, Form, Icon, Input, Row, Tag } from "antd";
import React from "react";
import Axios from "../../util/request";
import { carTypeList } from "../../util/publicData";
import moment from "moment";
class CarListDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      record: {
        code: "",
        type: "",
        weight: "",
        insureStartTime: "",
        insureEndTime: "",
        nextInspectTime: "",
        produceTime: "",
        gpsCid: "",
        state: 2,
      },
      filterData: {
        code: "",
        sortersName: "",
      },
    };
  }
  componentDidMount() {
    const self = this;
    self.state.filterData.code = new URLSearchParams(
      window.location.search
    ).get("code");
    const params = JSON.stringify(self.state.filterData);
    Axios({
      url: "/server/admin/car/getCarList",
      method: "get",
      data: {
        page: 1,
        rows: 20,
        params,
      },
      type: "json",
    }).then((res) => {
      if (res.success) {
        // self.setState({ record: res.datas[0] });
        self.setState((pre) => ({
          record: {
            ...pre.record,
            ...res.datas[0],
          },
        }));
      }
    });
  }

  render() {
    const self = this;
    return (
      <div>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Icon type="home" /> 首页
          </Breadcrumb.Item>
          <Breadcrumb.Item>车辆管理</Breadcrumb.Item>
          <Breadcrumb.Item>车辆详情</Breadcrumb.Item>
        </Breadcrumb>

        <Form
          labelCol={{
            span: 10,
          }}
          wrapperCol={{
            span: 14,
          }}
          layout="horizontal"
          style={{
            maxWidth: 600,
          }}
        >
          <br></br>
          <Row>
            <Col span={24}>
              <Form.Item label="车牌号：">
                {self.state.record.code || ""}
              </Form.Item>
              <Form.Item label="车辆类型：">
                <div>
                  {(() => {
                    const type = self.state.record.type;
                    return carTypeList.map((d) => {
                      if (type == d.value) {
                        return d.label + " ";
                      } else {
                        return "";
                      }
                    });
                  })()}
                </div>
              </Form.Item>
              <Form.Item label="车辆重量/kg：">
                {self.state.record.weight || ""}
              </Form.Item>
              <Form.Item label="车辆保险开始日期：">
                {self.state.record.insureStartTime
                  ? moment(self.state.record.insureStartTime).format(
                      "YYYY-MM-DD"
                    )
                  : ""}
              </Form.Item>
              <Form.Item label="车辆保险结束日期：">
                {self.state.record.insureEndTime
                  ? moment(self.state.record.insureEndTime).format("YYYY-MM-DD")
                  : ""}
              </Form.Item>
              <Form.Item label="车辆下次年检日期：">
                {self.state.record.nextInspectTime
                  ? moment(self.state.record.nextInspectTime).format(
                      "YYYY-MM-DD"
                    )
                  : ""}
              </Form.Item>
              <Form.Item label="车辆生产日期：">
                {self.state.record.produceTime
                  ? moment(self.state.record.produceTime).format("YYYY-MM-DD")
                  : ""}
              </Form.Item>
              <Form.Item label="GPS设备号：">
                {self.state.record.gpsCid || ""}
              </Form.Item>
              <Form.Item label="状态：">
                {self.state.record.state === 2 ? (
                  ""
                ) : self.state.record.state === 1 ? (
                  <Tag color="#87d068">通过</Tag>
                ) : (
                  <Tag color="#f50">不通过</Tag> || ""
                )}
              </Form.Item>
              {/* <Form.Item label="车辆图片：">
                                { self.state.record.carDescFile || ''}
                            </Form.Item> */}
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
export default CarListDetail;
