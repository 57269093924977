import React from "react";
import {
  Form,
  Icon,
  Input,
  Button,
  Table,
  Tag,
  Select,
  Breadcrumb,
} from "antd";

import Axios from "../../util/request";
import moment from "moment";
import UpdateTickets from "./components/UpdateTickets";
import debounce from "lodash/debounce";
import Zmage from "react-zmage";

const { Option } = Select;

class TicketList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData: {
        isVolunteer: 4,
      }, //检索数据
      dataSource: {}, //列表数据
      provinceList: [],
      pageNum: 1,
      tableLoading: true,
      recvWorkMap: [],
      sendWorkerMap: [],
      projectNameMap: [],
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
    };
    this.pageNum = 1;
    this.sendWorkerMap = [];
    this.recvWorkMap = [];
    this.projectNameMap = [];
    this.fetchOrgData = debounce(this.fetchOrgData, 800);
    this.fetchData = debounce(this.fetchData, 800);
    this.fetchPorjectData = debounce(this.fetchPorjectData, 800);
  }
  fetchPorjectData = (url, type) => {
    const self = this;
    //筛选下拉查询
    Axios({ url, method: "get" }, true).then((result) => {
      if (result.success) {
        let projectNameMap = [];
        projectNameMap = result.data.map((item) => {
          return { label: item.name, value: item.id };
        });
        self.setState({ projectNameMap });
      }
    });
  };
  getProjectNameMap = () => {
    const self = this;
    Axios(
      {
        // url: "/server/admin/worker/getUserList",
        url: "/server/admin/project/getProjects",
        data: { page: 1, rows: 200, params: JSON.stringify({}) },
        method: "get",
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        self.projectNameMap = result.data.map((item) => {
          return { label: item.name, value: item.id };
        });
        self.setState({
          projectNameMap: self.projectNameMap,
        });
      }
    });
  };

  fetchData = (url, type) => {
    const self = this;
    const { userInfo } = this.state;
    //筛选下拉查询
    Axios(
      {
        url,
        method: "get",
        data: { params: JSON.stringify({ projectId: userInfo.projectId }) },
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        if (type === "sendWorkerMap") {
          let sendWorkerMap = [];
          sendWorkerMap = result.data.map((item) => {
            return { label: item.classifierName, value: item.id };
          });
          self.setState({ sendWorkerMap });
        }

        if (type === "recvWorkMap") {
          let recvWorkMap = [];
          recvWorkMap = result.data.map((item) => {
            return { label: item.classifierName, value: item.id };
          });
          self.setState({ recvWorkMap });
        }

        // callBack(result.data);
      }
    });
  };
  // 获取table数据
  fetchOrgData = () => {
    const self = this;
    const { filterData, timeString, userInfo } = this.state;
    const page = this.pageNum || 1;
    this.setState({ tableLoading: true });
    if (!filterData.state) {
      delete filterData.state;
    }
    if (userInfo.projectId) {
      filterData.projectId = userInfo.projectId;
    }
    const params = JSON.stringify(filterData);
    // 拉取预约数据
    Axios(
      {
        // url: "/server/admin/worker/getUserList",
        url: "/server/admin/ticket/getTicketList",
        data: { page, rows: 20, params },
        method: "get",
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        self.setState({ dataSource: result, tableLoading: false });
      }
    });
  };
  getNameMap = () => {
    const self = this;
    const { userInfo } = this.state;
    console.log(userInfo, "--------userInfo");
    Axios(
      {
        // url: "/server/admin/worker/getUserList",
        url: "/server/admin/ticket/getNameMap",
        data: {
          page: 1,
          rows: 200,
          params: JSON.stringify({ projectId: userInfo.projectId }),
        },
        method: "get",
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        console.log(result, "---------------result");
        self.sendWorkerMap = result.data.map((item) => {
          return { label: item.classifierName, value: item.id };
        });
        self.recvWorkMap = result.data.map((item) => {
          return { label: item.classifierName, value: item.id };
        });
        self.setState({
          sendWorkerMap: self.sendWorkerMap,
          recvWorkMap: self.recvWorkMap,
        });
        //   self.setState({ dataSource: result, tableLoading: false });
      }
    });
  };

  componentDidMount() {
    const self = this;
    const { filterData } = this.state;
    const { search } = this.props.location;
    const paramsString = search.substring(1);
    const searchParams = new URLSearchParams(paramsString);
    const sortersName = searchParams.get("sortersName");
    const sendWorkerId = searchParams.get("sendWorkerId");
    if (filterData) {
      filterData.sortersName = sortersName;
    }
    filterData.sendWorkerId = sendWorkerId;
    this.setState({ filterData }, function () {
      self.fetchOrgData();
    });
    this.getNameMap();
    this.getProjectNameMap();
  }

  render() {
    const { filterData, tableLoading, sendWorkerMap, userInfo } = this.state;
    const { match } = this.props;
    const self = this;
    const dataColumns = [
      {
        title: "编号",
        dataIndex: "id",
        key: "id",
        render: (cur, record, index) => {
          return (self.pageNum - 1) * 20 + index + 1;
        },
      },
      {
        title: "发起人",
        dataIndex: "sendWorkerName",
        key: "sendWorkerName",
      },
      {
        title: "接收人",
        dataIndex: "recvWorkName",
        key: "recvWorkName",
      },
      {
        title: "工单类型",
        dataIndex: "type",
        key: "type",
        render: (cur, record) => {
          // 定义一个函数来根据cur的值返回对应的Tag组件
          const getStatusTag = (status) => {
            switch (status) {
              case "clean":
                return <Tag>清扫区域</Tag>;
              case "ship":
                return <Tag>安排清运车辆</Tag>;
              default:
                return null; // 或者可以根据需要返回默认的Tag或其他内容
            }
          };
          // 在JSX中调用该函数来渲染正确的Tag
          return <div>{getStatusTag(cur)}</div>;
        },
      },
      {
        title: "任务内容",
        dataIndex: "content",
        key: "content",
      },
      // {
      //   title: "工单日志",
      //   dataIndex: "log",
      //   key: "log",
      // },
      {
        title: "工单状态",
        dataIndex: "status",
        key: "status",
        render: (cur, record) => {
          // 定义一个函数来根据cur的值返回对应的Tag组件
          const getStatusTag = (status) => {
            switch (status) {
              case "wait":
                return <Tag>待接单</Tag>;
              case "accept":
                return <Tag>已接单</Tag>;
              case "doing":
                return <Tag>开始处理</Tag>;
              case "done":
                return <Tag>完成</Tag>;
              case "error":
                return <Tag>无法完成</Tag>;
              default:
                return null; // 或者可以根据需要返回默认的Tag或其他内容
            }
          };
          // 在JSX中调用该函数来渲染正确的Tag
          return <div>{getStatusTag(cur)}</div>;
        },
      },
      //   {
      //     title: "状态",
      //     dataIndex: "state",
      //     key: "state",
      //     render: (cur, record) => {
      //       return (
      //         <div>
      //           {cur == 1 ? (
      //             <Tag color="#87d068">通过</Tag>
      //           ) : (
      //             <Tag color="#f50">不通过</Tag>
      //           )}
      //         </div>
      //       );
      //     },
      //   },
      {
        title: "创建时间",
        dataIndex: "createTime",
        key: "createTime",
        render: (qrcode, record) => {
          return <div>{qrcode && moment(qrcode).format("YYYY-MM-DD")} </div>;
        },
      },
      {
        title: "操作",
        render: (qrcode, record) => {
          return (
            <div className="optbtn">
              <Button
                type="primary"
                icon="eye"
                title="查看"
                onClick={() =>
                  this.props.history.push(
                    "/admin/ticket/ticketDetail/" + record.id
                  )
                }
              ></Button>
              <Button
                type="primary"
                icon="edit"
                title="编辑"
                onClick={() => this.UpdateTickets.showDetail(record)}
              ></Button>
            </div>
          );
        },
      },
    ];
    return (
      <div style={{ flex: 1 }}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Icon type="home" /> 首页
          </Breadcrumb.Item>
          <Breadcrumb.Item>工单列表</Breadcrumb.Item>
        </Breadcrumb>
        <div className="mt-20">
          <Form layout="inline" style={{ flex: 1 }}>
            <Form.Item label="发起人">
              <Select
                showSearch
                allowClear
                onSearch={(value) => {
                  self.fetchData(
                    "/server/admin/ticket/getNameMap?classifierName=" + value,
                    "sendWorkerMap"
                  );
                }}
                onChange={(val) => {
                  const { filterData } = this.state;
                  filterData.sendWorkerId = val;
                  self.setState({ filterData });
                }}
                onSelect={() => {
                  self.setState({ sendWorkerMap: self.sendWorkerMap });
                }}
                // value={{item.value}}
                placeholder="发起人"
                filterOption={false}
                style={{ width: 120 }}
              >
                {sendWorkerMap.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="接收人">
              <Select
                allowClear
                showSearch
                onChange={(val) => {
                  const { filterData } = this.state;
                  filterData.recvWorkId = val;
                  this.setState({ filterData });
                }}
                onSearch={(value) => {
                  self.fetchData(
                    "/server/admin/ticket/getNameMap?classifierName=" + value,
                    "recvWorkMap"
                  );
                }}
                onSelect={() => {
                  self.setState({ recvWorkMap: self.recvWorkMap });
                }}
                placeholder="接收人"
                filterOption={false}
                style={{ width: 120 }}
              >
                {this.state.recvWorkMap.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="项目">
              <Select
                defaultValue={userInfo.projectId || undefined}
                disabled={userInfo.projectId != null ? true : false}
                onChange={(val) => {
                  const { filterData } = this.state;
                  filterData.projectId = val;
                  this.setState({ filterData });
                }}
                onSearch={(value) => {
                  self.fetchPorjectData(
                    "/server/admin/project/getProjects?projectName=" + value
                  );
                }}
                onSelect={() => {
                  self.setState({ projectNameMap: self.projectNameMap });
                }}
                placeholder="项目"
                style={{ width: 120 }}
                allowClear
                showSearch
                filterOption={false}
              >
                {this.state.projectNameMap.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="工单类型">
              <Select
                onChange={(val) => {
                  const { filterData } = this.state;
                  filterData.type = val.trim();
                  this.setState({ filterData });
                }}
                placeholder="全部"
                defaultValue="全部"
                style={{ width: 120 }}
              >
                <Option key=" " value={" "}>
                  全部
                </Option>
                <Option key={1} value={"clean"}>
                  清扫区域
                </Option>
                <Option key={0} value={"ship"}>
                  安排清运车辆
                </Option>
              </Select>
            </Form.Item>
            <Form.Item label="工单状态">
              <Select
                onChange={(val) => {
                  const { filterData } = this.state;
                  filterData.status = val.trim();
                  this.setState({ filterData });
                }}
                placeholder="全部"
                defaultValue="全部"
                style={{ width: 120 }}
              >
                <Option key=" " value={" "}>
                  全部
                </Option>
                <Option key={1} value={"wait"}>
                  待接单
                </Option>
                <Option key={0} value={"accept"}>
                  已接单
                </Option>
                <Option key={1} value={"doing"}>
                  开始处理
                </Option>
                <Option key={0} value={"done"}>
                  完成
                </Option>
                <Option key={1} value={"error"}>
                  无法完成
                </Option>
              </Select>
            </Form.Item>
            {/* <Form.Item label="状态">
              <Select
                onChange={(val) => {
                  const { filterData } = this.state;
                  filterData.state = val.trim();
                  this.setState({ filterData });
                }}
                placeholder="全部"
                defaultValue="全部"
                style={{ width: 120 }}
              >
                <Option key=" " value={" "}>
                  全部
                </Option>
                <Option key={1} value={"1"}>
                  正常
                </Option>
                <Option key={0} value={"0"}>
                  禁用
                </Option>
              </Select>
            </Form.Item> */}
            <Form.Item>
              <Button
                onClick={() => {
                  this.pageNum = 1;
                  this.fetchOrgData();
                }}
                type="primary"
              >
                搜索
              </Button>
            </Form.Item>
          </Form>
          <br />
          <div style={{ textAlign: "right" }}>
            <Form.Item>
              <Button
                // onClick={() => {
                //   this.UpdateTickets.showDetail();
                // }}
                onClick={() =>
                  this.props.history.push("/admin/ticket/addTicket")
                }
                type="primary"
              >
                新增工单
              </Button>
            </Form.Item>
          </div>
          <div className="card-container" style={{ marginTop: 20 }}>
            <Table
              loading={tableLoading}
              scroll={{ x: 1300 }}
              dataSource={this.state.dataSource.datas}
              pagination={{
                total: this.state.dataSource.total || 0,
                showTotal: (total) =>
                  `共 ${this.state.dataSource.total || 0} 条数据`,
                pageSize: 20,
                current: this.pageNum,
                onChange: (page) => {
                  this.pageNum = page;
                  this.fetchOrgData();
                },
              }}
              columns={dataColumns}
            />
          </div>
        </div>
        <UpdateTickets
          fetchOrgData={this.fetchOrgData}
          onRef={(c) => (this.UpdateTickets = c)}
        ></UpdateTickets>
      </div>
    );
  }
}

export default TicketList;
