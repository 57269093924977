import React from "react";
import {
  Row,
  Col,
  Breadcrumb,
  message,
  Upload,
  InputNumber,
  Divider,
  DatePicker,
} from "antd";
import { Form, Icon, Input, Button, Select, Modal } from "antd";

import Axios from "../../../util/request";
import { debounce } from "lodash";
import moment from "moment";
import CarTypeSelect from "../../../components/select/CarTypeSelect";
import { recyclerServices } from "../../../util/publicData";

const { Option } = Select;
const { TextArea } = Input;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

class UpdataStn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        title: "提示",
        visible: false,
        width: 600,
        token: null,
        content: function () {
          return <span></span>;
        },
        loading: false,
        userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
      },
      filterData: {},
      imageUrl: "",
      areaList: [],
      committeeCodeList: [],
      fileList: [],
      projectNameMap: [],
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
    };
    this.projectNameMap = [];
    if (props.onRef) {
      //如果父组件传来该方法 则调用方法将子组件this指针传过去
      props.onRef(this);
    }
    this.fetchPorjectData = debounce(this.fetchPorjectData, 800);
  }
  fetchPorjectData = (url, type) => {
    const self = this;
    //筛选下拉查询
    Axios({ url, method: "get" }, true).then((result) => {
      if (result.success) {
        let projectNameMap = [];
        projectNameMap = result.data.map((item) => {
          return { label: item.name, value: item.id };
        });
        self.setState({ projectNameMap });
      }
    });
  };
  getProjectNameMap = () => {
    const self = this;
    Axios(
      {
        // url: "/server/admin/worker/getUserList",
        url: "/server/admin/project/getProjects",
        data: { page: 1, rows: 200, params: JSON.stringify({}) },
        method: "get",
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        self.projectNameMap = result.data.map((item) => {
          return { label: item.name, value: item.id };
        });
        self.setState({
          projectNameMap: self.projectNameMap,
        });
      }
    });
  };

  getUploadToken = (values) => {
    //提交新增用户信息方法
    const self = this;
    Axios(
      {
        url: "/server/admin/qiniu/getUploadToken",
        method: "get",
        data: values,
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        self.setState({ token: result.uploadToken });
      }
    });
  };

  showDetail = (record) => {
    console.log(record);
    const { modal } = this.state;
    const self = this;
    this.props.form.resetFields();
    modal.visible = true;
    modal.width = 800;
    modal.loading = false;
    if (record && record.id) {
      modal.title = <span>修改点位信息</span>;
      const filterData = { ...record };
      let fileListArr = filterData.carDescFile
        ? filterData.carDescFile.split(",")
        : [];
      let fileList = fileListArr.map((d, i) => {
        return {
          uid: i,
          name: "image.png",
          status: "done",
          url: "http://afl-static.aifenlei.com.cn/" + d,
          response: { key: d },
        };
      });

      self.setState({ modal, record, filterData, fileList });
    } else {
      modal.title = <span>新增点位信息</span>;
      const filterData = {};
      filterData.state = 1;
      self.setState({ modal, filterData, fileList: [] });
    }
  };

  handleCancel = () => {
    const { modal } = this.state;
    modal.visible = false;
    this.setState({ modal });
  };

  handleOk = () => {
    const self = this;
    const { form } = this.props;
    const { filterData, fileList, userInfo } = this.state;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      if (filterData.id) {
        values.id = filterData.id;
      }
      let params = values;
      params.radius = params.radius.replace("m" || "M", "");
      switch (params.type) {
        case "中转站":
          params.type = 1;
          break;
        case "垃圾焚烧厂":
          params.type = 2;
          break;
        case "桶站":
          params.type = 3;
          break;
        default:
          break;
      }
      if (userInfo.projectId) {
        params.projectId = userInfo.projectId;
      }

      Axios(
        {
          url: "/server/admin/drop/updateDropInfo",
          method: "post",
          data: params,
          type: "json",
        },
        true
      ).then((result) => {
        if (result.success) {
          message.success("修改成功");
          self.props.fetchOrgData();
          const { modal } = this.state;
          modal.visible = false;
          self.setState({ modal });
        }
      });
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log(values);
      }
    });
  };

  handleChange = (info) => {
    if (info.file.status == "done") {
      this.setState({
        imageUrl: "https://static.aifenlei.com.cn/" + info.file.response.key,
        loading: false,
      });
    }
  };

  componentDidMount() {
    const self = this;
    this.getUploadToken();
    this.getProjectNameMap();
  }

  handleChange2 = (info) => {
    this.setState({ fileList: info.fileList });
  };

  render() {
    const self = this;
    const { filterData, modal, fileList, imageUrl, token, userInfo } =
      this.state;
    const { match } = this.props;
    const { getFieldDecorator } = this.props.form;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">车辆图片</div>
      </div>
    );

    return (
      <Modal
        onCancel={this.handleCancel}
        visible={modal.visible}
        width={modal.width}
        title={modal.title}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={this.handleOk}>
            确定
          </Button>,
        ]}
      >
        <div>
          <Form
            name="nest-messages"
            style={{ maxWidth: "1000px" }}
            {...layout}
            onSubmit={this.handleSubmit}
          >
            <Row>
              <Col span={24}>
                <Form.Item name={["car", "dropName"]} label="点位名称">
                  {getFieldDecorator("dropName", {
                    rules: [{ required: true, message: "请输入点位名称!" }],
                    initialValue: filterData.dropName
                      ? filterData.dropName
                      : "",
                  })(
                    <Input
                      placeholder="点位名称"
                      style={{ width: 300 }}
                      onChange={(e) => {
                        const { filterData } = this.state;
                        filterData.code = e.target.value;
                        this.setState({ filterData });
                      }}
                    />
                  )}
                </Form.Item>
                <Form.Item name={["car", "type"]} label="类型">
                  {getFieldDecorator("type", {
                    rules: [{ required: true, message: "类型!" }],
                    initialValue:
                      filterData.type === 1
                        ? "中转站"
                        : filterData.type === 2
                        ? "垃圾焚烧厂"
                        : filterData.type === 3
                        ? "桶站"
                        : "",
                  })(
                    <Input
                      placeholder="类型"
                      disabled
                      style={{ width: 300 }}
                      onChange={(val) => {
                        const { filterData } = this.state;
                        filterData.type = val.target.value;
                        this.setState({ filterData });
                      }}
                    />
                  )}
                </Form.Item>
                <Form.Item name={["car", "dropLocation"]} label="经纬度">
                  {getFieldDecorator("dropLocation", {
                    rules: [{ required: true, message: "请输入经纬度!" }],
                    initialValue: filterData.dropLocation
                      ? filterData.dropLocation + ""
                      : "",
                  })(
                    <Input
                      onChange={(value) => {
                        const { filterData } = this.state;
                        filterData.dropLocation = value;
                        this.setState({ filterData });
                      }}
                      style={{ width: 300 }}
                      min={0}
                      step={0.01}
                      placeholder="经纬度(xxx.xxx.xxx.xx)"
                    />
                  )}
                </Form.Item>
                <Form.Item name={["car", "dropAddress"]} label="地址">
                  {getFieldDecorator("dropAddress", {
                    rules: [
                      // { required: true, message: '请选择车辆类型!' },
                    ],
                    initialValue: filterData.dropAddress
                      ? filterData.dropAddress + ""
                      : "",
                  })(
                    <Input
                      placeholder="地址"
                      style={{ width: 300 }}
                      onChange={(val) => {
                        const { filterData } = this.state;
                        filterData.dropAddress = val;
                        this.setState({ filterData });
                      }}
                    />
                  )}
                </Form.Item>
                <Form.Item name={["car", "areaCode"]} label="街道">
                  {getFieldDecorator("areaCode", {
                    rules: [
                      // { required: true, message: '请选择车辆类型!' },
                    ],
                    initialValue: filterData.areaCode
                      ? filterData.areaCode + ""
                      : "",
                  })(
                    <Input
                      placeholder="街道"
                      style={{ width: 300 }}
                      onChange={(val) => {
                        const { filterData } = this.state;
                        filterData.areaCode = val;
                        this.setState({ filterData });
                      }}
                    />
                  )}
                </Form.Item>
                <Form.Item name={["car", "radius"]} label="半径(M)">
                  {getFieldDecorator("radius", {
                    rules: [
                      // { required: true, message: '请选择车辆类型!' },
                    ],
                    initialValue: filterData.radius
                      ? filterData.radius + "m"
                      : "",
                  })(
                    <Input
                      placeholder="半径(M)"
                      style={{ width: 300 }}
                      onChange={(val) => {
                        const { filterData } = this.state;
                        filterData.radius = val;
                        this.setState({ filterData });
                      }}
                    />
                  )}
                </Form.Item>
                <Form.Item name={["car", "state"]} label="状态">
                  {getFieldDecorator("state", {
                    initialValue: filterData.state + "",
                  })(
                    <Select
                      onChange={(val) => {
                        const { filterData } = this.state;
                        filterData.state = val;
                        this.setState({ filterData });
                      }}
                      style={{ width: 300 }}
                    >
                      <Option key={1} value={"1"}>
                        启用
                      </Option>
                      <Option key={0} value={"0"}>
                        禁用
                      </Option>
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label="项目">
                  {getFieldDecorator("projectId", {
                    rules: [{ required: true, message: "请选择项目!" }],
                    initialValue:
                      userInfo.projectId != null
                        ? userInfo.projectId
                        : filterData.projectId,
                  })(
                    <Select
                      disabled={userInfo.projectId != null ? true : false}
                      onChange={(val) => {
                        const { filterData } = this.state;
                        filterData.projectId = val;
                        this.setState({ filterData });
                      }}
                      onSearch={(value) => {
                        self.fetchPorjectData(
                          "/server/admin/project/getProjects?projectName=" +
                            value
                        );
                      }}
                      onSelect={() => {
                        self.setState({ projectNameMap: self.projectNameMap });
                      }}
                      placeholder="项目"
                      style={{ width: 120 }}
                      allowClear
                      showSearch
                      filterOption={false}
                    >
                      {this.state.projectNameMap.map((item) => (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
                <Divider dashed={true} />
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    );
  }
}
export default Form.create({ name: "submit" })(UpdataStn);
