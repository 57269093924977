import React from "react";
import {
  List,
  Checkbox,
  Radio,
  Pagination,
  Tabs,
  Alert,
  Row,
  Col,
  Message,
  TreeSelect,
  Avatar,
  Breadcrumb,
} from "antd";
import {
  Form,
  Icon,
  Input,
  Button,
  Table,
  Divider,
  Tag,
  Select,
  Modal,
  Card,
  Steps,
  Popover,
} from "antd";

import moment from "moment";
import debounce from "lodash/debounce";
import Axios from "../../util/request";
import QRCode from "qrcode.react";
import { recyclerServices } from "../../util/publicData";
import style from "./style.module.scss";
import AddLog from "./components/AddLog";

const { TabPane } = Tabs;
const { Option } = Select;
const { Step } = Steps;
const { TextArea } = Input;

class TicketDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signId: this.props.match.params.id,
      selectData: [],
      classifierList: [],
      positionData: [],
      sortersInfo: {},
      communityList: [],
      filterData: {},
      logList: [],
    };
    this.fetchData = debounce(this.fetchData, 800);
  }
  logToTalk(str) {
    console.log(str, "--------str");
    let logList = str.split("#").map((item) => {
      let itemArr = item.split(" ", 4);
      let time = itemArr.slice(0, 3).join(" ");
      let content = itemArr.slice(3).join("");
      return { time, content };
    });
    if (logList.length > 0 && logList[0].time == "" && logList[0].content == "")
      logList.shift();
    return logList;
  }

  // 显示订单详情
  fetchData = () => {
    const { modal, signId } = this.state;
    const self = this;

    Axios(
      {
        url: "/server/admin/ticket/getTicketInfo",
        method: "get",
        data: { id: this.props.match.params.id },
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        const sortersInfo = result.data;
        let logList = this.logToTalk(sortersInfo.log);
        self.setState({ sortersInfo, logList });
      }
    });
  };

  componentDidMount() {
    const self = this;
    this.fetchData();
  }

  render() {
    const self = this;
    const { filterData, sortersInfo, logList } = this.state;
    return (
      <div>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Icon type="home" /> 首页
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/admin/ticket/ticketList">
            工单管理
          </Breadcrumb.Item>
          <Breadcrumb.Item>工单详情</Breadcrumb.Item>
        </Breadcrumb>
        <div className="mt-20">
          <Card title="基本信息">
            <Row>
              {/* <Col span={4} style={{ padding: "20px" }}>
                <div className="avatar-box">
                  <Avatar
                    size={64}
                    icon="user"
                    src={sortersInfo.userHeadImg || ""}
                  />
                </div>
                <div>
                  {(() => {
                    return recyclerServices.map((d) => {
                      if (sortersInfo[d.sqlName] == d.value) {
                        return d.label + " ";
                      } else {
                        return "";
                      }
                    });
                  })()}
                </div>
              </Col> */}
              <Col
                span={24}
                style={{ padding: "20px", borderLeft: "1px dashed #e8e8e8" }}
              >
                <Row>
                  <Col span={8}>发起人：{sortersInfo.sendWorkerName}</Col>
                  <Col span={8}>接收人：{sortersInfo.recvWorkName}</Col>
                  <Col span={8}>
                    工单类型：
                    {sortersInfo.type && sortersInfo.type == "clean"
                      ? "清扫区域"
                      : "安排清运车辆"}
                  </Col>

                  {/* <Col span={8}>
                    状态：
                    {sortersInfo.state == 1 ? (
                      <Tag color="#87d068">正常</Tag>
                    ) : (
                      <Tag color="#f50">禁用</Tag>
                    )}
                  </Col> */}
                </Row>
                <Divider dashed={true} />
                <Row>
                  {/* <Col span={8}>
                    创建时间：
                    {moment(sortersInfo.ctime).format("YYYY-MM-DD HH:mm")}
                  </Col>
                  <Col span={8}>
                    更新时间：
                    {sortersInfo.mtime
                      ? moment(sortersInfo.mtime).format("YYYY-MM-DD HH:mm")
                      : ""}
                  </Col> */}
                  <Col span={8}>任务内容：{sortersInfo.content}</Col>
                  {/* <Col span={8}>工单日志：{sortersInfo.log}</Col> */}
                  <Col span={8}>
                    <div
                      title="添加日志"
                      className={style.talkTitle}
                      onClick={() => {
                        this.AddLog.showDetail();
                      }}
                    >
                      任务日志：
                    </div>
                    <div className={style.cardTalk}>
                      {logList.map((item) => (
                        <div className={style.talkItem} key={item.time}>
                          <div>
                            <Avatar
                              style={{ margin: "12px 10px 0 0" }}
                              size={30}
                              icon="user"
                            />
                          </div>
                          <div>
                            <div className={style.talkItemTime}>
                              {item.time}
                            </div>
                            <div className={style.talkItemContent}>
                              {item.content}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Col>
                  <Col span={8}>
                    工单状态：{sortersInfo.status == "wait" && "待接单"}
                    {sortersInfo.status == "accept" && "已接单"}
                    {sortersInfo.status == "doing" && "开始处理"}
                    {sortersInfo.status == "done" && "完成"}
                    {sortersInfo.status == "error" && "无法完成"}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </div>
        <AddLog
          fetchData={self.fetchData}
          sortersInfo={self.state.sortersInfo}
          onRef={(c) => (this.AddLog = c)}
        ></AddLog>
      </div>
    );
  }
}
export default TicketDetail;
