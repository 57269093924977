import React, { Component } from 'react';
import {Row, Col, Typography, Popover, Select} from 'antd';
import { Drawer, Button, Avatar, Radio, Icon, Dropdown, Badge, message, Tabs, List } from 'antd';
import { withRouter } from 'react-router-dom';
import moment from "moment";
import styles from './style.scss';

const {TabPane} = Tabs;

class NewsView extends Component {
  state = {
    visible: false,
    userInfo: JSON.parse(localStorage.getItem("userInfo")) || {}
  };

  handleNoticeClear = (title: string, key: string) => {
    const { dispatch } = this.props;
    message.success(`${'清空了'}`);
  };

  render() {
    const {userInfo} = this.state;
    const data = [
      {
        title: 'Ant Design Title 1',
      },
      {
        title: 'Ant Design Title 2',
      },
      {
        title: 'Ant Design Title 3',
      },
      {
        title: 'Ant Design Title 4',
      },
    ];
    const content = (<div className="NewsView-content">
      <Tabs defaultActiveKey="1" className="tabs">
        <TabPane tab="消息（99）" key="1">
          <List
            itemLayout="horizontal"
            className="NewsView-list"
            dataSource={data}
            renderItem={item => (
              <List.Item
                className="item"
                onClick={() => {
                console.log(item)
              }}>
                <List.Item.Meta
                  avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                  title={"张三 联系了您"}
                  description="这里是信息"

                />
              </List.Item>
            )}
          />
        </TabPane>
      </Tabs>
      <div className="bottomBar">
        {true ? (
          <div onClick={this.handleNoticeClear}>
            清空 消息
          </div>
        ) : null}
        {true ? (
          <div
            onClick={(e) => {
              console.log(2222222)
            }}
          >
            查看更多
          </div>
        ) : null}
      </div>
    </div>)
    return (
      <span className="NewsView">
        <Dropdown className="NewsView-Dropdown" overlay={content} trigger={['click']}>
          <Badge count={999} overflowCount={99}>
            <a href="#" className="head-example" >
              <Icon type="bell"/>
            </a>
          </Badge>
        </Dropdown>
      </span>
    );
  }
}
export default NewsView;
