import React from "react";

import { Menu, Icon, Tabs, Row, Col, Card, Table, Spin, Button } from "antd";
import { BrowserRouter as Router, Route, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import DataTable from "./components/DataTable";

import moment from "moment";

import style from "./style.module.scss";

import { thousands } from "../../util/calculation";
import Axios from "../../util/request";
import { method } from "lodash";
import { carTypeList } from "../../util/publicData";

const { SubMenu } = Menu;

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // selectedKeys: "", //默认路由
      // spinning: false, //默认路由
      // lastTime: "",
      // signTypeList: [],
      todayActivities: [],
      yesterdayActivities: [],
      lastWeekActivities: [],
      lastMonthActivities: [],
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
    };
    // 1三轮回收物车 2三轮保洁车 3四轮小货车 4四轮其他垃圾清运车 5四轮厨余垃圾清运车 6建筑垃圾清运车辆
    // carTypeList = [
    //   { label: "三轮回收物车", value: "1" },
    //   { label: "三轮保洁车", value: "2" },
    //   { label: "四轮小货车", value: "3" },
    //   { label: "四轮其他垃圾清运车", value: "4" },
    //   { label: "四轮厨余垃圾清运车", value: "5" },
    //   { label: "建筑垃圾清运车辆", value: "6" },
    //   { label: "污水车", value: "7" },
    //   { label: "六轮压缩车", value: "8" },
    // ];
    this.carTypeMap = carTypeList.reduce((totalV, curV) => {
      totalV[curV.value] = curV.label;
      return totalV;
    }, {});
    // this.carTypeMap = {
    //   1: "三轮回收物车",
    //   2: "三轮保洁车",
    //   3: "四轮小货车",
    //   4: "四轮其他垃圾清运车",
    //   5: "四轮厨余垃圾清运车",
    //   6: "建筑垃圾清运车辆",
    //   7: "污水车",
    //   8: "六轮压缩车",
    // };
  }
  // 获取上周的周一和周日
  getLastWeekRange() {
    // 获取当前日期
    const now = new Date();
    const currentDayOfWeek = now.getDay(); // Sunday is 0, Monday is 1, ..., Saturday is 6

    // 调整到上周的周一
    const startOfWeek = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - currentDayOfWeek - 6
    );
    // 调整到上周的周日
    const endOfWeek = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - currentDayOfWeek
    );

    // 设置时间为当天的开始和结束
    startOfWeek.setHours(0, 0, 0, 0);
    endOfWeek.setHours(23, 59, 59, 999);

    return { startOfWeek, endOfWeek };
  }
  // 获取上月的第一天和最后一天的日期
  getLastMonthRange() {
    // 获取当前日期
    const now = new Date();

    // 计算上个月的第一天
    const startOfMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    // 计算上个月的最后一天
    const endOfMonth = new Date(now.getFullYear(), now.getMonth(), 0);

    // 设置时间为上个月第一天的开始和最后一天的结束
    startOfMonth.setHours(0, 0, 0, 0);
    endOfMonth.setHours(23, 59, 59, 999);

    return { startOfMonth, endOfMonth };
  }
  componentWillMount() {
    const { userInfo } = this.state;
    // Axios(
    //   {
    //     url: "/server/admin/car/getGpsLogList",
    //     data: { params, page, rows: 20 },
    //     method: "get",
    //     type: "json",
    //   },
    //   true
    // ).then((result) => {
    //   if (result.success) {
    //     self.setState({ dataSource: result, tableLoading: false });
    //   }
    // });
    const self = this;

    Axios(
      {
        url: "/server/admin/home/getActivities",
        data: {
          day: moment().format("YYYY-MM-DD"),
          projectId: userInfo.projectId,
        },
        method: "get",
        type: "json",
      },
      true
    ).then((res) => {
      if (res.success) {
        self.setState({ todayActivities: res.data });
      }
    });
    Axios(
      {
        url: "/server/admin/home/getActivities",
        data: {
          day: moment().subtract(1, "day").format("YYYY-MM-DD"),
          projectId: userInfo.projectId,
        },
        method: "get",
        type: "json",
      },
      true
    ).then((res) => {
      if (res.success) {
        // console.log(res.data);
        self.setState({ yesterdayActivities: res.data });
      }
    });
    const { startOfWeek, endOfWeek } = this.getLastWeekRange();
    const startTimeOfWeek = moment(startOfWeek).format("YYYY-MM-DD");
    const endTimeOfWeek = moment(endOfWeek).format("YYYY-MM-DD");
    Axios(
      {
        url: "/server/admin/home/getActivities",
        data: {
          startTime: startTimeOfWeek,
          endTime: endTimeOfWeek,
          projectId: userInfo.projectId,
        },
        method: "get",
        type: "json",
      },
      true
    ).then((res) => {
      if (res.success) {
        // console.log(res.data);
        self.setState({ lastWeekActivities: res.data });
      }
    });
    const { startOfMonth, endOfMonth } = this.getLastMonthRange();
    const startTimeOfMonth = moment(startOfMonth).format("YYYY-MM-DD");
    const endTimeOfMonth = moment(endOfMonth).format("YYYY-MM-DD");
    Axios(
      {
        url: "/server/admin/home/getActivities",
        data: {
          startTime: startTimeOfMonth,
          endTime: endTimeOfMonth,
          projectId: userInfo.projectId,
        },
        method: "get",
        type: "json",
      },
      true
    ).then((res) => {
      if (res.success) {
        // console.log(res.data);
        self.setState({ lastMonthActivities: res.data });
      }
    });
  }

  render() {
    // const { spinning, dataSource, lastTime, totalDta, signTypeList } =
    //   this.state;

    // const { match } = this.props;

    return (
      // <div className={style.homeContent}>
      //   环卫系统
      // </div>
      <div className={style.home}>
        <div className={style.homeItem}>
          <div className={style.title}>今日动态</div>
          <div className={style.content}>
            {this.state.todayActivities.map((item, index) => (
              <div key={index} className={style.contentItem}>
                <div>{this.carTypeMap[item.type]}</div>
                <div>
                  <div>运行数量</div>
                  <div>
                    {item.moveCnt ? item.moveCnt : 0}/{item.cnt ? item.cnt : 0}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={style.homeItem}>
          <div className={`${style.title} ${style.dailyTitle}`}>昨日日报</div>
          <div className={style.content}>
            {this.state.yesterdayActivities.map((item, index) => (
              <div
                key={index}
                className={`${style.contentItem} ${style.dailyContentItem}`}
              >
                <div>{this.carTypeMap[item.type]}</div>
                <div>
                  <div>运行数量</div>
                  <div>
                    {item.moveCnt ? item.moveCnt : 0}/{item.cnt ? item.cnt : 0}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={style.homeItem}>
          <div className={`${style.title} ${style.weeklyTitle}`}>上周周报</div>
          <div className={style.content}>
            {this.state.lastWeekActivities.map((item, index) => (
              <div
                key={index}
                className={`${style.contentItem} ${style.weeklyContentItem}`}
              >
                <div>{this.carTypeMap[item.type]}</div>
                <div>
                  <div>运行数量</div>
                  <div>
                    {item.moveCnt ? item.moveCnt : 0}/{item.cnt ? item.cnt : 0}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={style.homeItem}>
          <div className={`${style.title} ${style.monthlyTitle}`}>上月月报</div>
          <div className={style.content}>
            {this.state.lastMonthActivities.map((item, index) => (
              <div
                key={index}
                className={`${style.contentItem} ${style.monthlyContentItem}`}
              >
                <div>{this.carTypeMap[item.type]}</div>
                <div>
                  <div>运行数量</div>
                  <div>
                    {item.moveCnt ? item.moveCnt : 0}/{item.cnt ? item.cnt : 0}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
