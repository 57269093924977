import React from 'react';
import './style.scss';

function Footer(props) {
  return (
    <div className="Copyright">
      @北京爱分类科技有限公司
      <br />
      400-686-5958
    </div>
  );
}

export default Footer;
