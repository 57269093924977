import React, { Component } from 'react';
import {Row, Col, Typography, Popover, Select} from 'antd';
import { Drawer, Button, Avatar, Radio, Icon, message, Tabs, List } from 'antd';
  import { withRouter } from 'react-router-dom';
import moment from "moment";
import styles from './style.scss';
import debounce from "lodash/debounce";

const {TabPane} = Tabs;
const {Option, OptGroup } = Select;

class HeadSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  };


  handleNoticeClear = (title: string, key: string) => {
    const { dispatch } = this.props;
    message.success(`${'清空了'}`);
  };

  showSearch = () =>{
    this.setState({visible:true})
  };
  hideSearch = () =>{
    this.setState({visible:false})
  };

  render() {
    const {visible} = this.state;
    const searchList = [
      {title: '预购订单', url: '/admin/sign/booking',},
      {title: '新增手机用户', url: '/admin/sign/addBooking',},
      {title: '预约记录', url: '/admin/sign/bookingHistory',},
      {title: '订单列表', url: '/admin/shopService/orderList',},
      {title: 'VOP订单列表', url: '/admin/shopService/vOrderList',},
      {title: '待审核VOP订单', url: '/admin/shopService/pendingList',},
      {title: '账户变动明细', url: '/admin/shopService/transList',},
      {title: '商品列表', url: '/admin/shopService/productList',},
      {title: '新增商品', url: '/admin/shopService/addProduct',},
      {title: '用户管理', url: '/admin/user/userManagement',},
      {title: '新增手机用户', url: '/admin/user/addUser',},
      {title: '家庭管理', url: '/admin/user/cardList',},
      {title: '新增家庭地址', url: '/admin/user/addCard',},
      {title: '邀请管理', url: '/admin/user/invitationList',},
      {title: '邀请码管理', url: '/admin/user/invitationCodeList',},
      {title: '新增邀请码', url: '/admin/user/addInvitationCode',},
      {title: '回收员管理', url: '/admin/sorters/sortersList',},
      {title: '服务社区管理', url: '/admin/sorters/classifier',},
      {title: '回收员操作日志', url: '/admin/sorters/setLog',},
      {title: '小组管理', url: '/admin/sorters/teamList',},
      {title: '新增小组', url: '/admin/sorters/addTeam',},
      {title: '代理商管理', url: '/admin/org/orgList',},
      {title: '新增代理商', url: '/admin/org/addOrg',},
      {title: '加盟商管理', url: '/admin/org/franchiseeList',},
      {title: '添加加盟商', url: '/admin/org/addFranchisee',},
      {title: '充值记录', url: '/admin/org/rechargeList',},
      {title: '交易记录', url: '/admin/org/orderList',},
      {title: '省市管理', url: '/admin/area/provinceList',},
      {title: '新增省市', url: '/admin/area/addProvince',},
      {title: '街道管理', url: '/admin/area/streetList',},
      {title: '新增街道', url: '/admin/area/addStreet',},
      {title: '社区管理', url: '/admin/area/communityList',},
      {title: '新增社区', url: '/admin/area/addCommunity',},
      {title: '资源管理', url: '/admin/area/categoryList',},
      {title: '新增资源', url: '/admin/area/addCategory',},
      {title: '规格管理', url: '/admin/area/resourcesList',},
      {title: '新增规格', url: '/admin/area/addResources',},
      {title: '投放点管理', url: '/admin/area/dropList',},
      {title: '新增投放点', url: '/admin/area/addDrop',},
      {title: '签收订单管理', url: '/admin/sign/signMoneyList',},
      {title: '定点订单管理', url: '/admin/sign/signScoreList',},
      {title: '签收处理工单', url: '/admin/sign/errorDeliveryList',},
      {title: '签收评价', url: '/admin/feedback/signCommentList',},
      {title: '签收反馈', url: '/admin/feedback/signFeedbackList',},
      {title: '意见管理', url: '/admin/feedback/suggestList',},
      {title: '交易列表', url: '/admin/transactions/transactionsList',},
      {title: '商家列表', url: '/admin/business/businessList',},
      {title: '提现管理', url: '/admin/business/withdrawList',},
      {title: '工单管理', url: '/admin/workorder/workorderList',},
      {title: '新增工单', url: '/admin/workorder/addWorkorder',},
    ];
    return (
      <div className="HeadSearch">
        <Icon type="search" onClick={() => {
          this.showSearch()
        }}/>
        <div className={visible ? "search show" : "search"}>
          <Select
            showSearch
            style={{ width: 210 }}
            optionFilterProp="children"
            showArrow={false}
            onChange={(val) => {
              this.props.history.push(val);
              this.hideSearch();
            }}
            onBlur={() => {
              this.hideSearch()
            }}
          >
            {searchList.map(d => (
              <Option value={d.url}>{d.title}</Option>
            ))}
          </Select>
        </div>
      </div>
    );
  }
}
export default withRouter(HeadSearch);
