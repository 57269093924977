import React from "react";
import {
  Row,
  Col,
  Breadcrumb,
  message,
  Upload,
  InputNumber,
  Divider,
  Checkbox,
} from "antd";
import moment from "moment";
import { Form, Icon, Input, Button, Select, Modal } from "antd";

import Axios from "../../../util/request";
import debounce from "lodash/debounce";

const { Option } = Select;
const { TextArea } = Input;

class AddLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        title: "添加日志",
        visible: false,
        width: 600,
      },
      logValue: "",
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    if (props.onRef) {
      //如果父组件传来该方法 则调用方法将子组件this指针传过去
      props.onRef(this);
    }
  }

  handleCancel = () => {
    const { modal } = this.state;
    modal.visible = false;
    this.setState({ modal });
  };
  addLog() {
    const { logValue, userInfo } = this.state;
    console.log(userInfo, userInfo.userName, "--------------------userInfo");
    const sortersInfo = this.props.sortersInfo;
    const self = this;
    let log = `#${moment().format("YYYY-MM-DD HH:mm:ss")} ${
      userInfo.userName
    } ${logValue}`;
    Axios(
      {
        url: "/server/admin/ticket/updateTicket",
        method: "post",
        data: {
          id: sortersInfo.id,
          sendWorkerId: sortersInfo.sendWorkerId,
          recvWorkId: sortersInfo.recvWorkId,
          type: sortersInfo.type,
          status: sortersInfo.status,
          content: sortersInfo.content,
          log: sortersInfo.log + log,
        },
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        message.success("添加成功");
        self.props.fetchData();
        const { modal } = this.state;
        modal.visible = false;
        self.setState({ modal });
      } else {
        message.error("添加失败");
      }
    });
  }

  handleOk = () => {
    this.addLog();
  };
  showDetail = (record) => {
    const { modal } = this.state;
    modal.visible = true;
    modal.width = 600;
    this.setState({
      modal,
    });
  };
  handleInputChange(event) {
    this.setState({ logValue: event.target.value });
  }

  componentDidMount() {
    const self = this;
  }

  render() {
    const self = this;
    const { modal, logValue } = this.state;
    return (
      <Modal
        onCancel={this.handleCancel}
        visible={modal.visible}
        width={modal.width}
        title={modal.title}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={this.handleOk}>
            确定
          </Button>,
        ]}
      >
        <div>
          <TextArea
            value={logValue}
            onChange={this.handleInputChange}
          ></TextArea>
        </div>
      </Modal>
    );
  }
}
export default Form.create({ name: "submit" })(AddLog);
