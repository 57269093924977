
import styled, { createGlobalStyle } from "styled-components";
import loginBg from '../../images/login/background.png'

export const Logo = styled.a`
  padding-left: 120px;
  line-height: 88px;
  > img{
    width: 160px;
    height: auto;
  }
`;

export const LoginContainer = styled.div`
  border-radius: 10px;
  overflow: hidden;
  margin: 100px auto 0;
  height: 640px;
  width: 1088px;
  box-shadow: 0 6px 20px rgba(0,25,59,.07);
  background: url(${loginBg}) #fff no-repeat left center;
`;

export const Copyright = styled.div`
  margin: 20px;
`;
