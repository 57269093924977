import React, { Component } from 'react';
import { Row, Col, Typography, Popover } from 'antd';
import { Drawer, Button, Avatar, Radio, Icon, Divider, Badge } from 'antd';
import { withRouter } from 'react-router-dom';
import  NewsView  from './NewsView';
import  HeadSearch  from './HeadSearch';
import logo from '../../images/icon/logo.svg';
import finLogo from '../../images/icon/finLogo.png';
import finNav01 from '../../images/icon/fin-nav01.png';
import finNav02 from '../../images/icon/fin-nav02.png';
import finNav03 from '../../images/icon/fin-nav03.png';
import finNav04 from '../../images/icon/fin-nav04.png';
import finNav05 from '../../images/icon/fin-nav05.png';
import finNav06 from '../../images/icon/fin-nav06.png';
import './style.scss';
import moment from 'moment';
const { Text } = Typography;
class Head extends Component {
  state = {
    visible: false,
    url: "",
    userInfo: JSON.parse(localStorage.getItem("userInfo")) || {}
  };

  showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };

  onToHref = href => {
    this.props.history.push(href);
    this.onClose();
  };

  getUrl = () => {
    this.setState({url:this.props.history.location.pathname})
  };

  componentWillMount() {
    this.getUrl()
  }

  render() {
    const {userInfo,url} = this.state;
    return (
      <div className={"header-component"}>
        <Row>

          <Col span={24}>
            <Row>
              <Col span={24} style={{textAlign:'right'}}>
                {/*{userInfo.role == 1 ? <HeadSearch></HeadSearch> : ""}*/}

                <Popover placement="bottom" content={<span>😁 {moment(userInfo.createTime).format("YYYY-MM-DD HH:mm")} 加入</span>} >
                  {userInfo.userHeadImg && <Avatar size={36} src={userInfo.userHeadImg } />}
                  {!userInfo.userHeadImg && <Avatar size={36} icon="user" />}
                  <Text level={4} style={{marginLeft: 12, color: 'white'}}>{userInfo.userName}</Text>
                </Popover>
                <a className={'login'} href="/login"><Icon style={{verticalAlign: 'middle', marginLeft: 32, fontSize: 24, color: 'white'}} type="poweroff" /><span className={'loginText'}>退出</span></a>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
export default withRouter(Head);
