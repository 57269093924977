import React from "react";
import {
  List,
  Checkbox,
  Radio,
  Pagination,
  Tabs,
  Alert,
  Row,
  Col,
  Message,
  TreeSelect,
  Avatar,
  Breadcrumb,
} from "antd";
import {
  Form,
  Icon,
  Input,
  Button,
  Table,
  Divider,
  Tag,
  Select,
  Modal,
  Card,
  Steps,
  Popover,
} from "antd";

import moment from "moment";
import debounce from "lodash/debounce";
import Axios from "../../util/request";
import QRCode from "qrcode.react";
import { recyclerServices } from "../../util/publicData";

const { TabPane } = Tabs;
const { Option } = Select;
const { Step } = Steps;
const { TextArea } = Input;

class UserDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signId: this.props.match.params.id,
      selectData: [],
      classifierList: [],
      positionData: [],
      sortersInfo: {},
      communityList: [],
      filterData: {},
    };
    this.fetchData = debounce(this.fetchData, 800);
  }

  // 显示订单详情
  fetchData = () => {
    const { modal, signId } = this.state;
    const self = this;

    Axios(
      {
        url: "/server/admin/worker/getUserInfo",
        method: "get",
        data: { id: this.props.match.params.id },
        type: "json",
      },
      true
    ).then((result) => {
      if (result.success) {
        const sortersInfo = result.data;

        self.setState({ sortersInfo });
      }
    });
  };

  componentDidMount() {
    const self = this;
    this.fetchData();
  }

  render() {
    const { filterData, sortersInfo } = this.state;
    return (
      <div>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Icon type="home" /> 首页
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/admin/worker/userList">
            用户管理
          </Breadcrumb.Item>
          <Breadcrumb.Item>用户详情</Breadcrumb.Item>
        </Breadcrumb>
        <div className="mt-20">
          <Card title="基本信息">
            <Row>
              <Col span={4} style={{ padding: "20px" }}>
                <div className="avatar-box">
                  <Avatar
                    size={64}
                    icon="user"
                    src={sortersInfo.userHeadImg || ""}
                  />
                </div>
                <div>
                  {(() => {
                    return recyclerServices.map((d) => {
                      if (sortersInfo[d.sqlName] == d.value) {
                        return d.label + " ";
                      } else {
                        return "";
                      }
                    });
                  })()}
                </div>
              </Col>
              <Col
                span={20}
                style={{ padding: "20px", borderLeft: "1px dashed #e8e8e8" }}
              >
                <Row>
                  <Col span={8}>姓名：{sortersInfo.userName}</Col>
                  <Col span={8}>手机号：{sortersInfo.mobile}</Col>
                  <Col span={8}>
                    状态：
                    {sortersInfo.state == 1 ? (
                      <Tag color="#87d068">正常</Tag>
                    ) : (
                      <Tag color="#f50">禁用</Tag>
                    )}
                  </Col>
                </Row>
                <Divider dashed={true} />
                <Row>
                  <Col span={8}>
                    创建时间：
                    {moment(sortersInfo.ctime).format("YYYY-MM-DD HH:mm")}
                  </Col>
                  <Col span={8}>
                    更新时间：
                    {sortersInfo.mtime
                      ? moment(sortersInfo.mtime).format("YYYY-MM-DD HH:mm")
                      : ""}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    );
  }
}
export default UserDetail;
