import React from 'react';
import {Form, Select} from 'antd';

import debounce from 'lodash/debounce';
import Axios from "../../util/request";

const {Option} = Select;

class UserSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inviteList: [],
    };
    this.fetchData = debounce(this.fetchData, 800);
  };

  fetchData = (url, val) => {  //筛选下拉查询
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    const userInfo = JSON.parse(localStorage.getItem("userInfo")) || {};

    const data = {
      pageSize: 20,
      pageNum:1
    }
    if(userInfo.franchiseeId){
      data.franchiseeId = userInfo.franchiseeId
    }

    Axios({url, method: 'get', data }, true).then(result => {

      if(result.success){
        let inviteList = [];

        inviteList = result.data.map(d => ({
          text: <div>{d.userName} {d.communityName + " " + d.userAddress}</div>,
          value: d.id,
          title: d.userName + " " + d.communityName + " " + d.userAddress
        }));


        this.setState({inviteList});

        // callBack(result.data);
      }
    });

  };

  render() {
    const { inviteList} = this.state;
    return (
      <Select
        onFocus={()=>{
          this.setState({inviteList: []});
        }}
        showSearch
        allowClear
        onSearch={(value)=>{
          this.fetchData("/server/admin/userNew/getUserList?userName=" + value);
        }}
        onChange={val => {
          this.props.onChange(val)
        }}
        style={{width: 200}}
        filterOption={false}
        value={this.props.value}
      >
        {inviteList.map(d => (
          <Option key={d.value} value={d.value} title={d.title}>{d.text}</Option>
        ))}
      </Select>
    )
  }



}

export default UserSelect;
