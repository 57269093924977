import React from "react";
import { ConfigProvider } from "antd";
import zhCN from "antd/es/locale/zh_CN";

import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./pages/home/Index";
import Worker from "./pages/worker/Index";
import Car from "./pages/car/Index";
import Drop from "./pages/drop/Index";
import Work from "./pages/work/Index";
import Ticket from "./pages/ticket/Index";

import { Head, Footer, Menu } from "./layouts";
import "./App.scss";

const userInfo = JSON.parse(localStorage.getItem("userInfo")) || {};

export default class App extends React.Component {
  state = {
    selectedKeys: "",
    userInfo: JSON.parse(localStorage.getItem("userInfo")),
  };

  //获取路由最后一个地址名称
  handleSetSelectedKeys(pathname) {
    // /admin = ["/","admin"]
    // 根据'/'把路由地址分割成一个数组
    const temp = pathname.split("/");
    // 如果数组的长度小于2,表示的是只有根路径/,设置为Home. 否则取数组中第二个值
    const key = temp[temp.length - 1];
    this.setState({
      selectedKeys: key,
    });
  }

  componentDidMount() {
    this.handleSetSelectedKeys(this.props.history.location.pathname);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo) {
      sessionStorage.setItem(
        "loginFrom",
        this.props.location.pathname + this.props.location.search
      );
      window.location.href = "/login";
    }
  }

  render() {
    const { match } = this.props;
    const { selectedKeys } = this.state;
    return (
      <div className="APP">
        {userInfo.role == 1 ? <Menu></Menu> : ""}
        <div
          className={userInfo.role == 1 ? "AppContainer rolo1" : "AppContainer"}
        >
          <ConfigProvider locale={zhCN}>
            {selectedKeys == "print" ? "" : <Head />}
            <div className="ContentContainer">
              {userInfo.role == 1 ? (
                <div>
                  <Route path={`${match.path}/home`} component={Home} />
                  <Route path={`${match.path}/worker`} component={Worker} />
                  <Route path={`${match.path}/car`} component={Car} />
                  <Route path={`${match.path}/drop`} component={Drop} />
                  <Route path={`${match.path}/work`} component={Work} />
                  <Route path={`${match.path}/ticket`} component={Ticket} />
                </div>
              ) : (
                ""
              )}
            </div>
            {selectedKeys == "print" ? "" : <Footer />}
          </ConfigProvider>
        </div>
      </div>
    );
  }
}
