import React from 'react';

import { Row, Col } from 'antd';
import { Card, Icon, Avatar, Form, Input, Button, Checkbox  } from 'antd';

import {Logo, LoginContainer, Copyright} from "./style.js";

import logo from '../../images/icon/logo-black.png';
import loginLogo from '../../images/icon/loginLogo.png';

import Axios from '../../util/request';


class LoginPage extends React.Component {
  state = {
    loading: true
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        Axios({
          url: "/server/admin/auth/login",
          data: values
        }).then(result => {
          console.log(111, result)
          if(result.success){
            console.log(222)
            localStorage.setItem("token", result.data.token);
            localStorage.setItem("userInfo", JSON.stringify(result.data.userInfo));
            const loginFrom = sessionStorage.getItem("loginFrom");
            if(loginFrom){
              return window.location.href = loginFrom;
            }


            if(result.data.userInfo.role == 2){
              window.location.href = "/admin/financeHome";
            }else if(result.data.userInfo.role == 3){
              window.location.href = "/admin/personnel/recruitmentList";
            }else if(result.data.userInfo.role == 4){
              window.location.href = "/admin/officialWeb/newsList";
            }else if(result.data.userInfo.role == 5){
              window.location.href = "/admin/data/signData";
            }else{
              window.location.href = "/admin/home";
            }
          }
          console.log(333)
        });
      }
    });
  };

  componentDidMount(){
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    const self = this;
    setTimeout(function(){
      self.setState({loading: false});
    }, 600)
  }

  render() {

    const { getFieldDecorator } = this.props.form;
    const { loading } = this.state;

    return (
      <div style={{minHeight:"100%",position:'relative'}}>
       <Row className="login-head">
          <Col span={6}>
            <Logo>
              <img src={logo} />
            </Logo>
          </Col>
        </Row>
        <LoginContainer>
          <Row>
         <Col span={8}>
          <Card bordered={false} style={{ width: 482, marginTop: 100,marginLeft: 606,textAlign:"center",background:"transparent" }} loading={loading}>
            <div className="login-form">
              {/*<div className="login-logo"><img src={loginLogo} /></div>*/}
              <div className="login-title">欢迎使用环卫后台管理系统</div>
              <Form onSubmit={this.handleSubmit} className="login-form">
                <Form.Item>
                  {getFieldDecorator('userName', {
                    rules: [{ required: true, message: '请输入账号' }],
                  })(
                    <Input
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="账号"
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: '请输入密码' }],
                  })(
                    <Input
                      prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="password"
                      placeholder="密码"
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    登 录
                  </Button>
                </Form.Item>
              </Form>
              <div className="login-form-msg"><span>让环境更美好  让城市更宜居</span></div>
            </div>
          </Card>
          </Col>
        </Row>
        </LoginContainer>

        <Row className="login-footer">
          <Col xs={2} sm={4} md={6} lg={8} xl={10}>
          </Col>
          <Col xs={20} sm={16} md={12} lg={8} xl={4}>
            <Copyright>@北京爱分类科技有限公司</Copyright>
          </Col>
          <Col xs={2} sm={4} md={6} lg={8} xl={10}>
          </Col>
        </Row>
      </div>
    );
  }
}
export default Form.create({ name: 'normal_login' })(LoginPage);
